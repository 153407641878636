import { DateTime } from 'luxon'
import { DialogContent, PopupMenu, MenuItem, ConfirmDialog, Heading } from 'xuick'
import { ActionsDialog } from './ActionsDialog.js'
import { SlidePopup } from './SlidePopup.js'
import { TripRouteCommentDialog } from './TripRouteCommentDialog.js'
import api from './api.js'

export class TripRouteActionsDialog extends ActionsDialog
{
  static class = 'TripRouteActionsDialog'

  state = {
    ...this.state,
    busy : false,
  }

  render() {
    const { props, state } = this
    const { trip, route } = props
    const dt = DateTime.fromISO(route.date)
    const date = dt.toLocaleString({
      month : 'numeric',
      day : 'numeric',
    })
    const disabled = trip.routes.length < 2 || state.busy
    const number = trip.routes.indexOf(route) + 1
    return new DialogContent([
      new Heading(`${ date } День ${ number }`),
      new PopupMenu({
        orientation : 'vertical',
        children : [
          new MenuItem({
            label : route.comment ?
              'Изменить комментарий к этому дню' :
              'Добавить комментарий к этому дню',
            icon : 'message_outline',
            disabled : state.busy,
            dialog : dialogProps => new TripRouteCommentDialog({
              ...dialogProps,
              route,
              number,
              onload : this.#onDialogLoad,
            }),
          }),
          new MenuItem({
            label : 'Удалить этот день из поездки',
            icon : disabled ?
              'bin_outline_dim' :
              'bin_outline',
            disabled,
            dialog : dialogProps => new ConfirmDialog({
              ...dialogProps,
              classList : 'SlidePopup',
              title : `Удаление дня №${ number } из маршрута`,
              message : 'Карточки мест в удаляемом дне могут содержать примечания, которые будут удалены вместе с днем. Восстановить примечания не получится.\n\nВы точно хотите удалить этот день из маршрута?',
              submitLabel : 'Удалить',
              cancelLabel : 'Отмена',
              onsubmit : this.#onDialogSubmit,
            }),
          }),
        ],
      }),
    ])
  }

  #onDialogLoad = e => {
    e.target.close()
    this.emit('load')
  }

  #onDialogSubmit = async e => {
    this.setState({ busy : true })
    try {
      await api.deleteRoute(this.props.route.id)
      e.target.close()
      this.emit('load')
    }
    finally {
      this.setState({ busy : false })
    }
  }
}
