import { Button, ButtonControl } from 'xuick'
import { Icon } from './Icon'
import './PlaceActionButton.css'

export class PlaceActionButton extends Button
{
  static class = 'PlaceActionButton'

  static role = null

  static tagName = 'a'

  static defaultProps = {
    classList : 'accent',
    tabIndex : null,
  }

  render() {
    return new ButtonControl(
      new Icon(this.props.icon),
    )
  }
}
