import lodash from 'lodash'
import { Menu } from 'xuick'
import { MapCategoryItem } from './MapCategoryItem'
// import MapPlaceCategories from '../common/MapPlaceCategories.js'
import './MapCategoryMenu.css'

const MapPlaceCategories = [
  {
    id : 16032, // Landmarks and Outdoors > Park
    keyword : 'park',
    title : 'Парки',
  },
  {
    id : 10027, // Arts and Entertainment > Museum
    keyword : 'museum',
    title : 'Музеи',
  },
  {
    id : 10043, // Arts and Entertainment > Performing Arts Venue > Theater
    keyword : 'theatre',
    title : 'Театры',
  },
  {
    id : 10037, // Arts and Entertainment > Performing Arts Venue > Concert Hall
    keyword : 'concert hall',
    title : 'Концерты',
  },
  {
    id : 10009, // Arts and Entertainment > Circus
    keyword : 'circus',
    title : 'Цирки',
  },
  {
    id : 10024, // Arts and Entertainment > Movie Theater
    keyword : 'movie theater',
    title : 'Кинотеатры',
  },
]

export class MapCategoryMenu extends Menu
{
  static class = 'MapCategoryMenu'

  #ids

  category = null

  init() {
    super.init()
    this.on('click', this.#onClick)
  }

  render() {
    const category = this.props.category
    const items = category?.items || category?.parent?.items || MapPlaceCategories
    return items.map(item => {
      return new MapCategoryItem({
        key : item.id,
        value : item,
        checked : item.id === category?.id,
      })
    })
  }

  update(prevProps, prevState) {
    super.update(prevProps, prevState)
    const items = this.findAll(MapCategoryItem)
    const ids = String(lodash.map(items, 'value'))
    if(ids === this.#ids) {
      return
    }
    this.node.scrollLeft = 0
    this.#ids = ids
  }

  #onClick(e) {
    const item = e.target.closest(MapCategoryItem)
    if(!item) {
      return
    }
    const category = item.value
    this.category = category === this.category ?
      category.parent || null :
      category
    this.emit('change')
  }
}
