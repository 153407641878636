import { HtmlDiv, Link, FootBlock, LeftBlock, RightBlock, Img, CountryFlag } from 'xuick'
import { Card } from './Card.js'
import { Photo } from './Photo.js'
import wpc from '../common/wpc.js'
import './LocalityCard.css'

function decimalToDMS(decimalValue) {
  const degrees = Math.floor(decimalValue)
  const minutesDecimal = Math.abs(decimalValue - degrees) * 60
  const minutes = Math.floor(minutesDecimal)
  const seconds = Math.round((minutesDecimal - minutes) * 60)
  return `${ degrees }°${ minutes }′${ seconds }″`
}

export class LocalityCard extends Card
{
  static class = 'LocalityCard'

  state = {
    place : undefined,
    locality : undefined,
  }

  init() {
    super.init()
    void this.#load()
  }

  async #load() {
    const locality = this.props.locality
    if(locality.photos?.length) {
      return
    }
    const summary = await wpc.getLocalitySummary(locality, 'ru')
    if(!summary?.thumbnail || !summary?.originalimage) {
      return
    }
    this.setState({
      locality : {
        ...locality,
        photos : [{ image : summary.originalimage.source }],
      },
    })
  }

  render() {
    const props = this.props
    const locality = this.state.locality ?? props.locality
    const country = locality.country
    const [lng, lat] = locality.geometry.coordinates
    const latDMS = decimalToDMS(lat)
    const lngDMS = decimalToDMS(lng)
    return new Link({
      to : props.to,
      children : [
        new Photo({
          photo : locality.photos?.[0],
        }),
        new FootBlock([
          new LeftBlock([
            new LocalityName(locality.name_ru || locality.name_en),
            new LocalityCountryName(country.name_ru || country.name_en),
          ]),
          new RightBlock([
            new LocalityCoords([
              new HtmlDiv(`${ latDMS } с.ш.`),
              new HtmlDiv(`${ lngDMS } з.д.`),
            ]),
            new CountryFlag({
              code : country.code,
              src : country.flag,
            }),
            new LocalityCountryEmblem({
              src : country.emblem,
            }),
          ]),
        ]),
      ],
    })
  }
}

export class LocalityName extends HtmlDiv
{
  static class = 'LocalityName'
}

export class LocalityCountryName extends HtmlDiv
{
  static class = 'LocalityCountryName'
}

export class LocalityCoords extends HtmlDiv
{
  static class = 'LocalityCoords'
}

export class LocalityCountryEmblem extends Img
{
  static class = 'LocalityCountryEmblem'
}
