import { Main, Content } from 'xuick'
import { Screen } from './Screen'
import { MobileMenu } from './MobileMenu'
import { ProfileHeader } from './ProfileHeader'
import { Err404Main } from './Err404Main'
import { LoadingIndicator } from './LoadingIndicator'
import { ProfilePhotoBlock } from './ProfilePhotoBlock'
import { ProfileName } from './ProfileName'
import { UserStatus } from './UserStatus.js'
import { ProfileBlock } from './ProfileBlock'
import api from './api.js'
import './ProfileScreen.css'

export class ProfileScreen extends Screen
{
  static class = 'ProfileScreen'

  static defaultProps = {
    userId : api.user.id,
  }

  state = {
    user : undefined,
    editMode : false,
  }

  init() {
    super.init()
    this.on('edit-mode', this.#onEditMode)
    api.on('User', this.#onUser)
  }

  render() {
    const { user, editMode } = this.state
    if(user) {
      document.title = user.name
    }
    return [
      new MobileMenu,
      new ProfileHeader({ user, editMode }),
      user === undefined && new LoadingIndicator,
      user === null && new Err404Main,
      user && new Main({
        key : 'profile',
        children : [
          new ProfilePhotoBlock({ user, editMode }),
          new Content([
            new ProfileName({ user, editMode }),
            user.id === api.user.id || user.statusText ?
              new UserStatus({ user }) :
              null,
            new ProfileBlock({ user, editMode }),
          ]),
        ],
      }),
    ]
  }

  mount() {
    super.mount()
    void this.#load()
  }

  update(prevProps) {
    super.update(...arguments)
    if(prevProps.userId !== this.props.userId) {
      void this.#load()
    }
    if(this.state.editMode) {
      document.addEventListener('keydown', this.#onKeyDown)
    }
    else document.removeEventListener('keydown', this.#onKeyDown)
  }

  destroy() {
    super.destroy()
    api.off('User', this.#onUser)
    document.removeEventListener('keydown', this.#onKeyDown)
  }

  #onEditMode() {
    this.setState(state => ({
      editMode : !state.editMode,
    }))
  }

  #onUser = user => {
    if(user.id !== this.props.userId) {
      return
    }
    this.setState(state => ({
      user : { ...state.user, ...user },
    }))
  }

  #onKeyDown = e => {
    if(e.code === 'Escape' && this.state.editMode) {
      this.setState({ editMode : false })
    }
  }

  async #load() {
    this.setState({
      user : await api.getUser(this.props.userId, {
        props : [
          'id',
          'name',
          'details',
          'email',
          'online',
          'phoneNumber',
          'photos',
          'showGeolocation',
          'site',
          'statusText',
          'suspended',
          'countries',
          'languages',
          'placeOfStay',
          'tags',
          'followers',
        ],
      }),
    })
  }
}
