import { TabList, Tab, Control, DialogHead, Heading, DialogBody, DialogContent } from 'xuick'
import { ModalDialog } from './ModalDialog.js'
import { HeadBackButton } from './HeadBackButton.js'
import { LoadingIndicator } from './LoadingIndicator.js'
import './InfoObjectDialog.css'

export class InfoObjectDialog extends ModalDialog
{
  static class = 'InfoObjectDialog'

  static defaultProps = {
    direction : 'right',
    objectPropName : 'object',
  }

  static sections = []

  state = {
    ...this.state,
    [this.props.objectPropName] : undefined,
    section : location.hash.slice(1) || this.constructor.sections[0].value,
  }

  init() {
    super.init()
    this.on('click', this.#onClick)
    this.on('cancel', this.#onCancel)
    window.addEventListener('hashchange', this.#onHashChange)
  }

  destroy() {
    super.destroy()
    window.removeEventListener('hashchange', this.#onHashChange)
  }

  render() {
    if(!this.props.objectId) {
      return
    }
    const state = this.state
    const object = state[this.props.objectPropName]
    return new DialogContent([
      new DialogHead([
        new HeadBackButton,
        new Heading(
          object?.name_ru || object?.name_en || 'Загрузка...',
        ),
      ]),
      new DialogBody([
        new TabList(
          this.constructor.sections.map(
            item => new Tab({
              value : item.value,
              selected : item.value === state.section,
              children : new Control(item.title),
            }),
          ),
        ),
        object ?
          this.constructor.sections.map(item => {
            const objectPropName = item.objectPropName || this.props.objectPropName
            return new item.panel({
              [objectPropName] : object,
              hidden : item.value !== state.section,
            })
          }) :
          new LoadingIndicator,
      ]),
    ])
  }

  mount() {
    super.mount()
    if(this.props.objectId) {
      void this._load()
    }
  }

  update(prevProps, prevState) {
    super.update(prevProps, prevState)
    const objectId = this.props.objectId
    if(objectId && objectId !== prevProps.objectId) {
      void this._load()
    }
  }

  async _load() {
    void null
  }

  #onClick(e) {
    const tab = e.target.closest(Tab)
    if(!tab || tab.value === this.state.section) {
      return
    }
    const url = new URL(location)
    url.hash = tab.value
    history.replaceState(null, '', url)
    this.setState({ section : tab.value })
  }

  async #onCancel() {
    const url = new URL(location)
    url.hash = ''
    history.replaceState(null, '', url)
    this.state.section = this.constructor.sections[0].value
  }

  #onHashChange = () => {
    this.setState({
      section : location.hash.slice(1) || this.constructor.sections[0].value,
    })
  }
}
