import { Link, CountryFlag, Span } from 'xuick'
import './InfoCountryLink.css'

export class InfoCountryLink extends Link
{
  static class = 'InfoCountryLink'

  render() {
    const country = this.props.country
    return [
      new CountryFlag({
        code : country.code,
        src : country.flag,
      }),
      new Span(country.name_ru || country.name_en),
    ]
  }
}
