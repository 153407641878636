import { MenuItem, ConfirmDialog, PopupMenu, Popup } from 'xuick'
import { SlidePopup } from './SlidePopup.js'
import api from './api.js'

export class TripMenu extends PopupMenu
{
  static class = 'TripMenu'

  static defaultProps = {
    orientation : 'vertical',
  }

  state = {
    ...this.state,
    busy : false,
  }

  render() {
    return [
      new MenuItem({
        label : 'Удалить поездку',
        icon : 'bin_outline',
        disabled : this.state.busy,
        dialog : dialogProps => new ConfirmDialog({
          ...dialogProps,
          classList : SlidePopup.class,
          title : `Удаление поездки`,
          message : 'Это действие невозможно отменить, поездка удалится навсегда!',
          submitLabel : 'Удалить',
          cancelLabel : 'Отмена',
          onsubmit : this.#onDialogSubmit,
        }),
      }),
    ]
  }

  #onDialogSubmit = async e => {
    const trip = this.props.trip
    e.target.close()
    this.setState({ busy : true })
    try {
      await api.deleteTrip(trip.id)
      this.emit('trip-delete', {
        bubbles : true,
        detail : {
          tripId : trip.id,
        },
      })
      this.closest(Popup)?.emit('close')
    }
    catch(err) {
      this.setState({ busy : false })
      throw err
    }
  }
}
