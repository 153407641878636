import { ListBox } from 'xuick'
import { TravelModeOption } from './TravelModeOption'
import './TravelModeListBox.css'

export class TravelModeListBox extends ListBox
{
  static class = 'TravelModeListBox'

  static defaultProps = {
    orientation : 'horizontal',
  }

  init() {
    super.init()
    this.on('focusin', e => e.stopPropagation())
  }

  renderOptions() {
    return this.props.options || [
      new TravelModeOption({
        value : 'driving',
      }),
      // todo
      /*new TravelModeOption({
        value : 'transit',
      }),*/
      new TravelModeOption({
        value : 'two_wheeler',
      }),
      new TravelModeOption({
        value : 'bicycling',
      }),
      new TravelModeOption({
        value : 'walking',
      }),
    ]
  }
}
