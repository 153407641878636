import lodash from 'lodash'
import {
  DialogContent,
  DialogHead,
  DialogBody,
  Heading,
  Button,
  ListItem,
  List,
  CountryFlag,
} from 'xuick'
import { Icon } from './Icon.js'
import { CountryBox } from './adaptive/CountryBox.js'
import { ProfileItemDialog } from './ProfileItemDialog.js'
import { HeadBackButton } from './HeadBackButton.js'
import api from './api.js'
import './ProfileCountryDialog.css'

export class ProfileCountryDialog extends ProfileItemDialog
{
  static class = 'ProfileCountryDialog'

  init() {
    super.init()
    this.on('change', this.#onChange)
  }

  render() {
    const user = this.props.user
    const state = this.state
    return new DialogContent([
      new DialogHead([
        new HeadBackButton({
          disabled : state.busy,
        }),
        new Heading('Страны, которые вы посетили'),
      ]),
      new DialogBody([
        this._countryBox = new CountryBox({
          label : 'Выберите страну',
          disabled : state.busy,
          excludeIds : lodash.map(user.countries, 'id'),
        }),
        new List(user.countries.map(item => {
          return new ListItem({
            key : item.id,
            children : [
              new CountryFlag({
                code : item.code,
                src : item.flag,
              }),
              item.name,
              new Button({
                value : item.id,
                disabled : state.busy,
                onclick : this.#onButtonClick,
                children : state.busy ?
                  new Icon('bin_outline_dim') :
                  new Icon('bin_outline'),
              }),
            ],
          })
        })),
      ]),
    ])
  }

  async #updateUserCountries(countries) {
    this.setState({ busy : true })
    try {
      await api.updateUser({
        countries : lodash.map(countries, 'id'),
      })
      this._countryBox.reset()
    }
    finally {
      this.setState({ busy : false })
    }
  }

  #onChange(e) {
    const item = e.target.item
    if(!item) {
      return
    }
    void this.#updateUserCountries([
      ...this.props.user.countries,
      item,
    ])
  }

  #onButtonClick = e => {
    const button = e.target.closest(Button)
    const countries = this.props.user.countries.filter(
      item => item.id !== button.value,
    )
    void this.#updateUserCountries(countries)
  }
}
