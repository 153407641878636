import { HtmlSmall } from 'xuick'
import { Photo } from './Photo.js'
import './PlacePhoto.css'

export class PlacePhoto extends Photo
{
  static class = 'PlacePhoto'

  static defaultProps = {
    style : {
      minWidth : '100%',
    },
  }

  constructor(props) {
    if(!props.place) {
      super(props)
      return
    }
    super({
      photo : props.place.photos?.[0],
      ...props,
    })
  }

  render() {
    const photo = this.props.photo
    if(!photo?.attributions) {
      return super.render()
    }
    const links = photo.attributions.map(item => {
      return item.replace('<a href="', '<a target="_blank" href="')
    })
    return new HtmlSmall({
      innerHTML : links.join(' / '),
    })
  }
}
