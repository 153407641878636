import parsePhoneNumber from 'libphonenumber-js'
import { PlaceActionButton } from './PlaceActionButton'

export class PlaceCallButton extends PlaceActionButton
{
  static defaultProps = {
    icon : 'phone_outline_white',
  }

  assign() {
    super.assign()
    const phoneNumber = this.props.place.phoneNumber
    const pn = phoneNumber && parsePhoneNumber(phoneNumber)
    this.disabled = !pn
    this.href = pn ? `tel:${ pn.number }` : null
  }
}
