import { RoleImg, Icon } from 'xuick'
import './UserPhoto.css'

export class UserPhoto extends RoleImg
{
  static class = 'UserPhoto'

  render() {
    const photo = this.props.user.photos[0]
    if(!photo) {
      return new Icon('account_filled_dim')
    }
    const url = Object.assign(new URL('/photo', location.origin), {
      search : new URLSearchParams({
        image : photo.image,
        width : 80,
        height : 80,
      }),
    })
    this.style = {
      backgroundImage : `url(${ url })`,
    }
  }
}
