import { HtmlDiv, DialogContent, DialogHead, Heading, DialogBody } from 'xuick'
import { Icon } from './Icon.js'
import { ModalDialog } from './ModalDialog'
import { HeadBackButton } from './HeadBackButton'
import { BubbleBlock } from './BubbleBlock'
import params from './params.js'
import './AboutInfoDialog.css'

export class AboutInfoDialog extends ModalDialog
{
  static class = 'AboutInfoDialog'

  static defaultProps = {
    direction : 'right',
  }

  render() {
    return new DialogContent([
      new DialogHead([
        new HeadBackButton,
        new Heading('О приложении'),
      ]),
      new DialogBody([
        new BubbleBlock([
          new Icon('planirus_custom'),
          new HtmlDiv(`Версия: ${ params.version }`),
        ]),
      ]),
    ])
  }
}
