import { Group } from 'xuick'
import { InfoBlock } from './InfoBlock'
import { InfoObjectLink } from './InfoObjectLink.js'
import './InfoSectionsBlock.css'

export class InfoSectionsBlock extends InfoBlock
{
  static class = 'InfoSectionsBlock'

  render() {
    return new Group([
      new InfoObjectLink({
        label : 'География',
        photo : 'images/geography.webp',
        to : '/info/geography',
      }),
    ])
  }
}
