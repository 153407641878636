import { HtmlDiv, RoleNone } from 'xuick'
import PriceLevels from '../common/PriceLevels'
import './PlacePriceBlock.css'

export class PlacePriceBlock extends HtmlDiv
{
  static class = 'PlacePriceBlock'

  constructor(props) {
    if(!props.place.priceLevel) {
      return []
    }
    super(props)
  }

  render() {
    const price = this.props.place.priceLevel
    this.title = PriceLevels[price]
    return [
      new None('₽'.repeat(price)),
      new None('₽'.repeat(4 - price)),
    ]
  }
}

class None extends RoleNone
{
  static tagName = 'span'
}
