import { Group } from 'xuick'
import { InfoCountryLink } from './InfoCountryLink.js'
import { InfoObjectNav } from './InfoObjectNav.js'

export class InfoCountriesNav extends InfoObjectNav
{
  static class = 'InfoCountriesNav'

  render() {
    return new Group(
      this.props.countries.map(
        country => new InfoCountryLink({
          to : '/info/countries/' + country.id,
          country,
        }),
      ),
    )
  }
}
