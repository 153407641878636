const turf = require('@turf/turf')
const DISTANCE_MAX = 3

class WikipediaClient
{
  async getPageSummary(title, lang = 'en') {
    const origin = `https://${ lang }.wikipedia.org`
    const url = Object.assign(new URL(origin), {
      pathname : '/api/rest_v1/page/summary/' + title,
    })
    const res = await fetch(url)
    return res.ok ? res.json() : null
  }

  async getLocalitySummary(locality, lang = 'en') {
    const title = locality['name_' + lang]
    const summary = await this.getPageSummary(title, lang)
    if(!summary?.coordinates) {
      return null
    }
    const pointA = [summary.coordinates.lon, summary.coordinates.lat]
    const pointB = locality.geometry.coordinates
    const distance = turf.distance(pointA, pointB)
    return distance < DISTANCE_MAX ?
      summary :
      null
  }
}

module.exports = WikipediaClient
