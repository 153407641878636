import { Menu, MenuItem } from 'xuick'
import api from './api.js'
import './TripActionsMenu.css'

export class TripActionsMenu extends Menu
{
  static class = 'TripActionsMenu'

  static defaultProps = {
    orientation : 'horizontal',
  }

  render() {
    const trip = this.props.trip
    const params = new URLSearchParams({
      tripId : trip.id,
    })
    return [
      /*new MenuItem({
        icon : 'two-tickets_outline_white',
        label : 'Купить билеты',
      }),*/
      /*new MenuItem({
        icon : 'taxi_outline_white',
        label : 'Заказать такси',
      }),*/
      /*new MenuItem({
        children : [
          new Icon('occupied-bed_outline_white'),
          new Label({
            innerText : 'Найти\nотель',
          }),
        ],
      }),*/
      api.user.role && new MenuItem({
        label : 'Добавить друга',
        icon : 'plus_outline_white',
      }),
      new MenuItem({
        label : 'Посмотреть  на карте',
        icon : 'map-marker_outline_white',
        to : '/map?' + params,
      }),
    ]
  }
}
