import lodash from 'lodash'
import { Main } from 'xuick'
import { AppError } from './adaptive/AppError.js'
import { Screen } from './Screen.js'
import { MobileMenu } from './MobileMenu.js'
import { MapHeader } from './MapHeader.js'
import { MapCategoryMenu } from './MapCategoryMenu.js'
import { MapBackButton } from './MapBackButton.js'
import { MapBoundsButton } from './MapBoundsButton.js'
import { LoadingIndicator } from './LoadingIndicator.js'
import { MapApplication } from './MapApplication.js'
import { MapCardBlock } from './MapCardBlock.js'
import googlemaps from './googlemaps.js'
import api from './api.js'
import './MapScreen.css'

const SEARCH_RADIUS = 10_000
const MOVE_TO_PLACE_ZOOM = 14
const FIT_BOUNDS_MAX_ZOOM = 18
const FIT_BOUNDS_PADDING = 20

export class MapScreen extends Screen
{
  static class = 'MapScreen'

  state = {
    ready : false,
    busy : true,
    query : '',
    center : undefined,
    position : undefined,
    bounds : undefined,
    zoom : MOVE_TO_PLACE_ZOOM,
    places : [],
    category : null,
    itemId : undefined,
    route : null,
    trip : null,
  }

  #transition = true

  async init() {
    super.init()
    this.on('map-moveend', this.#onMapMoveEnd)
    this.on('map-dragend', this.#onMapDragEnd)
    this.on('map-zoomend', this.#onMapZoomEnd)
    this.on('map-geolocate', this.#onMapGeolocate)
    this.on('submit', this.#onSubmit)
    this.on('reset', this.#onReset)
    api.on('Trip', this.#onTrip)
    document.addEventListener('trip-delete', this.#onTripDelete)
    await googlemaps.load()
    this.#start()
  }

  destroy() {
    super.destroy()
    api.off('Trip', this.#onTrip)
    document.removeEventListener('trip-delete', this.#onTripDelete)
  }

  render() {
    const { props, state } = this
    if(!state.ready) {
      return [
        new MobileMenu,
        new LoadingIndicator,
      ]
    }
    const trip = state.trip
    const place = this.#place
    const route = props.routeId && lodash.find(trip?.routes, ['id', props.routeId])
    if(place) {
      document.title = `${ place.name } - Карта`
    }
    else document.title = 'Карта'
    return [
      new MobileMenu,
      this._header = new MapHeader({
        location : state.center,
        radius : SEARCH_RADIUS,
        itemId : state.itemId,
        category : state.category,
        query : state.query,
        oninput : this.#onHeaderInput,
      }),
      new Main({
        key : '/map',
        children : [
          this._menu = new MapCategoryMenu({
            key : 'menu',
            category : state.category,
            onchange : this.#onMenuChange,
          }),
          new MapBoundsButton({
            key : 'bounds',
            hidden : !state.bounds,
            onclick : this.#onBoundsButtonClick,
          }),
          trip && new MapBackButton({
            key : 'back',
            place,
            route,
            trip,
          }),
          this._map = new MapApplication({
            key : 'map',
            place,
            route,
            trip,
            places : state.places,
            center : state.center,
            zoom : state.zoom,
            onchange : this.#onMapChange,
          }),
        ],
      }),
      this._card = new MapCardBlock({
        place,
        route,
        trip,
        places : state.places,
        position : state.position,
      }),
    ]
  }

  update(prevProps, prevState) {
    super.update(prevProps, prevState)
    const props = this.props
    if(props.placeId && props.placeId !== prevProps.placeId) {
      void this.#moveToPlace()
      return
    }
    const trip = this.state.trip
    if(props.routeId && props.routeId !== prevProps.routeId) {
      const route = lodash.find(trip.routes, ['id', props.routeId])
      if(route) {
        const places = route.places.length ?
          route.places :
          lodash.flatMap(trip.routes, 'places')
        this._map.fitBoundsToMarkers(places, this.#fitBoundsOptions)
      }
    }
    if(!props.routeId && prevProps.routeId) {
      const places = lodash.flatMap(trip.routes, 'places')
      this._map.fitBoundsToMarkers(places, this.#fitBoundsOptions)
    }
    if(prevProps.tripId && !props.tripId) {
      this.setState({
        trip : null,
        route : null,
      })
    }
  }

  #start() {
    const props = this.props
    if(props.tripId) {
      void this.#loadTrip(props.tripId)
      return
    }
    if(props.routeId) {
      void this.#loadRoute(props.routeId)
      return
    }
    if(props.placeId) {
      this.#transition = false
      void this.#loadPlace(props.placeId)
      return
    }
    this.setState({
      ready : true,
      busy : false,
    })
    if(!location.hash) {
      void this._map.geolocate()
    }
  }

  async #loadPlace(placeId) {
    try {
      const place = await api.getPlace(placeId)
      const [lng, lat] = place.geometry.coordinates
      this.setState({
        places : [place],
        center : { lng, lat },
        ready : true,
        busy : false,
      })
    }
    catch(error) {
      this.setState({ busy : false })
      throw error
    }
  }

  async #loadPlaceFromSearch() {
    const header = this._header
    const place = header.item
    this.setState({
      trip : null,
      ready : true,
      query : header.query || '',
      category : null,
      itemId : header.itemId,
      bounds : undefined,
      places : [place],
    })
    await this.app.navigate('/map/place/' + place.id, {
      data : { place },
    })
  }

  async #loadPlaces() {
    const state = this.state
    const category = this._menu.category
    const header = this._header
    const bounds = state.bounds
    this.setState({
      busy : true,
      query : category?.title || header.query,
      places : [],
      category,
      itemId : header.itemId ?? undefined,
      bounds : undefined,
    })
    try {
      const places = await api.findPlaces({
        query : category ? undefined : header.query,
        keywords : category ?
          lodash.compact([
            category.keyword,
            ...lodash.map(category.items, 'keyword'),
          ]) :
          undefined,
        location : this._map.getCenter(),
        radius : bounds ?
          this._map.getRadius() :
          SEARCH_RADIUS,
      })
      this.setState({
        busy : false,
        places,
      })
      if(!bounds) {
        this._map.fitBoundsToMarkers(places, this.#fitBoundsOptions)
      }
    }
    catch(error) {
      this.setState({ busy : false })
      throw error
    }
  }

  async #loadRoute(routeId) {
    const route = await api.getRoute(routeId)
    if(!route) {
      throw new AppError({
        name : '404',
        message : 'Маршрут не найден',
      })
    }
    if(route.places.length) {
      this.setState({
        route,
        busy : false,
        ready : true,
        places : route.places,
      })
      this._map.fitBoundsToMarkers(route.places, this.#fitBoundsOptions)
      return
    }
    const [lng, lat] = route.trip.destination.geometry.coordinates
    const center = { lng, lat }
    this.setState({
      route,
      busy : false,
      ready : true,
      places : route.places,
      center,
    })
    this._map.setCenter(center)
  }

  async #loadTrip(tripId) {
    const props = this.props
    const trip = await api.getTrip(tripId)
    if(!trip) {
      throw new AppError({
        name : '404',
        message : 'Поездка не найдена',
      })
    }
    const routeId = props.routeId
    const route = routeId && lodash.find(trip.routes, ['id', routeId])
    if(routeId && !route) {
      throw new AppError({
        name : '404',
        message : 'Маршрут не найден',
      })
    }
    const places = lodash.flatMap(trip.routes, 'places')
    const placeId = props.placeId
    let place = this.#place
    if(placeId && !place) {
      place = await api.getPlace(placeId)
      places.push(place)
    }
    this.setState({
      trip,
      place,
      places,
      center : place && {
        lng : place.geometry.coordinates[0],
        lat : place.geometry.coordinates[1],
      },
      busy : false,
      ready : true,
    })
    if(place) {
      await this.#moveToPlace()
    }
    else this._map.fitBoundsToMarkers(places, this.#fitBoundsOptions)
  }

  async #moveToPlace() {
    const place = this.#place
    if(!place) {
      return
    }
    await this._map.ready()
    const [lng, lat] = place.geometry.coordinates
    this._map.moveTo({ lng, lat }, this.#mapPadding, this.#transition)
    this.#transition = true
  }

  #setBounds() {
    const state = this.state
    if(this.props.placeId) {
      return
    }
    const item = this._header.item
    const isSearch = !item || !item.id
    const bounds = isSearch && state.query ?
      this._map.getBounds() :
      undefined
    if(JSON.stringify(bounds) !== JSON.stringify(state.bounds)) {
      this.setState({ bounds })
    }
  }

  #onBoundsButtonClick = () => {
    void this.#loadPlaces()
  }

  #onHeaderInput = e => {
    this.setState({ query : e.target.query })
  }

  #onMapChange = () => {
    this.setState({ bounds : undefined })
  }

  #onMapDragEnd() {
    this.#setBounds()
  }

  #onMapGeolocate(e) {
    const position = e.detail.position
    const coords = this.state.position?.coords
    if(coords) {
      if(position.coords.latitude === coords.latitude) {
        if(position.coords.longitude === coords.longitude) {
          return
        }
      }
    }
    this.setState({ position })
  }

  #onMapMoveEnd = () => {
    const center = this._map.getCenter()
    if(JSON.stringify(center) !== JSON.stringify(this.state.center)) {
      this.setState({ center })
    }
  }

  #onMapZoomEnd() {
    this.#setBounds()
  }

  #onMenuChange = e => {
    const category = e.target.category
    if(category) {
      void this.#loadPlaces()
      return
    }
    this.setState({
      query : '',
      places : [],
      category,
      itemId : undefined,
    })
  }

  #onReset() {
    this.setState({
      query : '',
      places : [],
      bounds : undefined,
      itemId : undefined,
      category : null,
    })
  }

  #onSubmit(e) {
    const item = e.target.item
    if(!item) {
      this.setState({
        query : '',
        category : null,
        places : [],
        itemId : undefined,
        bounds : undefined,
        trip : null,
      })
      return
    }
    if(this.state.busy) {
      return
    }
    if(item.id) {
      void this.#loadPlaceFromSearch()
    }
    else void this.#loadPlaces()
  }

  #onTrip = trip => {
    const route = lodash.find(trip.routes, ['id', this.props.routeId])
    if(route) {
      this.setState({ trip })
      return
    }
    this.state.trip = trip
    void this.app.navigate('/map?tripId' + trip.id)
  }

  #onTripDelete = () => {
    this.app.navigate('/map')
  }

  get #place() {
    const id = this.props.placeId
    if(!id) {
      return undefined
    }
    const places = this.#places
    return lodash.find(places, ['id', id])
  }

  get #places() {
    const places = [
      ...lodash.flatMap(this.state.trip?.routes, 'places'),
      ...this.state.places,
    ]
    return lodash.uniqBy(places, 'id')
  }

  get #fitBoundsOptions() {
    return {
      padding : this.#mapPadding,
      maxZoom : FIT_BOUNDS_MAX_ZOOM,
    }
  }

  get #mapPadding() {
    const bottom = this._card.hidden ?
      FIT_BOUNDS_PADDING :
      this._card.cardHeight
    return {
      left : FIT_BOUNDS_PADDING,
      right : FIT_BOUNDS_PADDING,
      top : this._menu.node.offsetHeight + 50,
      bottom : bottom + 50,
    }
  }
}
