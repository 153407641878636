import { HtmlDt, HtmlDd } from 'xuick'
import { BubbleBlock } from './BubbleBlock'
import './PersonalInfoBlock.css'

export class PersonalInfoBlock extends BubbleBlock
{
  static class = 'PersonalInfoBlock'

  static tagName = 'dl'

  render() {
    const user = this.props.user
    return [
      new HtmlDt('Аккаунт ID'),
      new HtmlDd(user.id),
      new HtmlDt('Имя и фамилия'),
      new HtmlDd(user.name),
      user.email && [
        new HtmlDt('Электронная почта'),
        new HtmlDd(user.email),
      ],
      user.phoneNumber && [
        new HtmlDt('Телефон'),
        new HtmlDd(user.phoneNumber),
      ],
    ]
  }
}
