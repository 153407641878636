import { Class, PopupContent, TextBox, Button, Form, Heading } from 'xuick'
import { CommentDialog } from './CommentDialog.js'
import api from './api'

export class TripPlaceCommentDialog extends CommentDialog
{
  static class = 'TripPlaceCommentDialog'

  state = {
    ...this.state,
    comment : undefined,
  }

  init() {
    super.init()
    this.on('input', this.#onInput)
    this.on('submit', this.#onSubmit)
  }

  render() {
    const { props, state } = this
    return new Form({
      classList : Class.generate(PopupContent),
      children : [
        new Heading('Комментарий к точке:'),
        new TextBox({
          placeholder : 'Введите текст',
          multiLine : true,
          value : state.comment ?? props.place.RoutePlace.comment,
        }),
        new Button({
          label : 'Сохранить',
          type : 'submit',
          classList : 'accent',
        }),
      ],
    })
  }

  async #updateComment() {
    const { props, state } = this
    const route = props.route
    const comment = state.comment?.trim() || null
    if(comment === props.place.RoutePlace.comment) {
      return
    }
    await api.updateRoutePlaceComment(route.id, props.place.id, comment)
    this.setState({ comment : undefined })
    this.emit('load')
  }

  #onInput(e) {
    this.setState({
      comment : e.target.value,
    })
  }

  #onSubmit() {
    if(this.state.comment !== undefined) {
      void this.#updateComment()
    }
  }
}
