import wait from 'wait'
import lodash from 'lodash'
import { DialogContent, DialogBody, Block } from 'xuick'
import { DateInterval } from '../common/DateInterval.js'
import { Trip } from '../common/Trip.js'
import { AppError } from './adaptive/AppError.js'
import { ModalDialog } from './ModalDialog.js'
import { TripRoutesDialog } from './TripRoutesDialog.js'
import { TripDialogHead } from './TripDialogHead.js'
import { TripMainCard } from './TripMainCard.js'
import { TripRoutesList } from './TripRoutesList.js'
import { TripRouteButton } from './TripRouteButton.js'
import { TripActionsMenu } from './TripActionsMenu.js'
import { LoadingIndicator } from './LoadingIndicator.js'
import { TripMenuButton } from './TripMenuButton.js'
import { TripMenu } from './TripMenu.js'
import api from './api.js'
import './TripDialog.css'

const HASH_DATE_RE = /^#(\d\d\d\d-\d\d-\d\d)$/

export class TripDialog extends ModalDialog
{
  static class = 'TripDialog'

  static parseLocationHash() {
    const match = location.hash.match(HASH_DATE_RE)
    return match?.[1] || null
  }

  state = {
    ...this.state,
    trip : undefined,
    busy : false,
  }

  init() {
    super.init()
    this.on('drag-start', this.#onDragStart)
    this.on('drag-end', this.#onDragEnd)
    document.addEventListener('trip-delete', this.#onTripDelete)
    api.on('Trip', this.#onTrip)
    if(this.props.open) {
      void this.#load()
    }
  }

  assign() {
    super.assign()
    const trip = this.state.trip
    if(this.props.open && trip) {
      document.title = Trip.generateTitle(trip)
    }
  }

  destroy() {
    super.destroy()
    document.removeEventListener('trip-delete', this.#onTripDelete)
    api.off('Trip', this.#onTrip)
  }

  render() {
    const state = this.state
    const trip = state.trip
    if(!trip) {
      return new DialogContent(new LoadingIndicator)
    }
    const date = TripDialog.parseLocationHash()
    const route = date && lodash.find(trip.routes, ['date', date])
    return new DialogContent([
      new TripDialogHead({ trip }),
      this._body = new DialogBody([
        new TripMainCard({ trip }),
        new Block([
          new TripActionsMenu({ trip }),
          new TripMenuButton({
            menu : menuProps => new TripMenu({
              ...menuProps,
              trip,
            }),
          }),
        ]),
        new TripRoutesList({ trip }),
        new TripRouteButton({
          onclick : this.#onRouteButtonClick,
          disabled : state.busy,
        }),
      ]),
      new TripRoutesDialog({
        trip,
        route,
        open : !!route,
        oncancel : () => location.hash = '',
      }),
    ])
  }

  update(prevProps, prevState) {
    super.update(prevProps, prevState)
    if(this.props.open && !prevProps.open) {
      if(this.props.tripId !== this.state.trip?.id) {
        void this.#load()
      }
    }
  }

  async #load() {
    if(this.state.trip) {
      this.setState({ trip : undefined })
    }
    const trip = await api.getTrip(this.props.tripId)
    if(!trip) {
      throw new AppError({
        name : '404',
        message : 'Поездка не найдена',
      })
    }
    this.setState({ trip })
  }

  #onDragStart() {
    document.documentElement.style.overflow = 'hidden'
    this._body.style.overflow = 'hidden'
  }

  #onDragEnd() {
    document.documentElement.style.overflow = null
    this._body.style.overflow = null
  }

  #onTrip = async tripB => {
    const tripA = this.state.trip
    const placesA = tripA.routes.flatMap(route => route.places)
    const placesB = tripB.routes.flatMap(route => route.places)
    if(placesB.length < placesA.length) {
      await wait(500)
    }
    this.setState({
      trip : { ...tripA, ...tripB },
    })
  }

  #onRouteButtonClick = async () => {
    const trip = this.state.trip
    const interval = DateInterval.fromDateRange(trip.interval)
    const dt = interval.end.plus({ days : 1 })
    const date = dt.toISODate()
    this.setState({ busy : true })
    try {
      await api.createRoute({
        tripId : trip.id,
        date,
      })
    }
    finally {
      this.setState({ busy : false })
    }
  }

  #onTripDelete = e => {
    if(e.detail.tripId === this.props.tripId) {
      this.close()
    }
  }
}
