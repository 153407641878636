import { TabList, Tab } from 'xuick'
import './TripDotList.css'

export class TripDotList extends TabList
{
  static class = 'TripDotList'

  render() {
    const props = this.props
    return props.trip.routes.map(
      route => new Tab({
        selected : route.date === props.date,
      }),
    )
  }
}
