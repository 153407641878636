import { DialogContent, DialogHead, DialogBody, Heading, MenuItem } from 'xuick'
import { ModalDialog } from './ModalDialog'
import { HeadCancelButton } from './HeadCancelButton'
import { DialogMenu } from './DialogMenu.js'
import { SettingsAccountDialog } from './SettingsAccountDialog'
import { SettingsAccessDialog } from './SettingsAccessDialog'
import { AboutInfoDialog } from './AboutInfoDialog'
import params from './params.js'

export class SettingsDialog extends ModalDialog
{
  static class = 'SettingsDialog'

  static defaultProps = {
    direction : 'bottom',
  }

  render() {
    return new DialogContent([
      new DialogHead([
        new HeadCancelButton,
        new Heading('Настройки'),
      ]),
      new DialogBody([
        new DialogMenu([
          new MenuItem({
            children : 'Настройки аккаунта',
            dialog : SettingsAccountDialog,
          }),
          /*new MenuItem({
            children : 'Конфиденциальность',
            dialog : PrivacySettingsDialog,
          }),*/
          new MenuItem({
            children : 'Доступ к данным',
            dialog : SettingsAccessDialog,
          }),
          /*new MenuItem({
            children : 'Настройки приложения',
            dialog : AppSettingsDialog,
          }),*/
          /*new MenuItem({
            children : 'Управление платежами',
            dialog : PaymentsControlDialog,
          }),*/
          /*new MenuItem({
            children : 'Поддержка',
            dialog : SupportMenuDialog,
          }),*/
          /*new MenuItem({
            children : 'Справка',
            dialog : HelpMenuDialog,
          }),*/
          /*new MenuItem({
            children : 'Часто задаваемые вопросы',
            dialog : FaqDialog,
          }),*/
          new MenuItem({
            children : 'О проекте',
            to : params.env.LANDING_ORIGIN + '/about',
            target : '_blank',
          }),
          /*new MenuItem({
            children : 'Юридическая информация',
            dialog : LegalInfoDialog,
          }),*/
          new MenuItem({
            children : 'Политика конфиденциальности',
            to : params.env.LANDING_ORIGIN + '/legal/privacy',
            target : '_blank',
            /*dialog : dialogProps => new DocDialog({
              ...dialogProps,
              name : 'privacy',
            }),*/
          }),
          new MenuItem({
            children : 'Пользовательское соглашение',
            to : params.env.LANDING_ORIGIN + '/legal/terms',
            target : '_blank',
            /*dialog : dialogProps => new DocDialog({
              ...dialogProps,
              name : 'agreement',
            }),*/
          }),
          new MenuItem({
            children : 'Файлы cookie',
            to : params.env.LANDING_ORIGIN + '/legal/cookie',
            target : '_blank',
            /*dialog : dialogProps => new DocDialog({
              ...dialogProps,
              name : 'cookie',
            }),*/
          }),
          new MenuItem({
            children : 'О приложении',
            dialog : AboutInfoDialog,
          }),
          /*params.user.role && [
            new Separator,
            new MenuItem({
              children : 'Загрузка мест',
              dialog : PlacesLoadDialog,
            }),
          ],*/
        ]),
      ]),
    ])
  }

  update(prevProps, prevState) {
    super.update(prevProps, prevState)
    if(this.props.open && !prevProps.open) {
      document.addEventListener('app-navigate', this.onAppNavigate)
      return
    }
    if(!this.props.open && prevProps.open) {
      document.removeEventListener('app-navigate', this.onAppNavigate)
    }
  }

  destroy() {
    super.destroy()
    document.removeEventListener('app-navigate', this.onAppNavigate)
  }

  onAppNavigate = () => {
    if(this.props.open) {
      this.close()
    }
  }
}
