import { HtmlDiv } from 'xuick'
import { ProfileLocationItem } from './ProfileLocationItem.js'
import { ProfileLangItem } from './ProfileLangItem.js'
import { ProfileCountryItem } from './ProfileCountryItem.js'
import { ProfileSiteItem } from './ProfileSiteItem.js'
import { ProfileEmailItem } from './ProfileEmailItem.js'
import { ProfileTagItem } from './ProfileTagItem.js'
import { ProfileDetailsItem } from './ProfileDetailsItem.js'
import './ProfileBlock.css'

export class ProfileBlock extends HtmlDiv
{
  static class = 'ProfileBlock'

  render() {
    const { user, editMode } = this.props
    return [
      new ProfileLocationItem({
        user,
        editMode,
        key : 'showGeolocation',
      }),
      new ProfileLangItem({
        user,
        editMode,
        key : 'languages',
      }),
      new ProfileCountryItem({
        user,
        editMode,
        key : 'countries',
      }),
      new ProfileSiteItem({
        user,
        editMode,
        key : 'site',
      }),
      new ProfileEmailItem({
        user,
        editMode,
        key : 'email',
      }),
      new ProfileDetailsItem({
        user,
        editMode,
        key : 'details',
      }),
      new ProfileTagItem({
        user,
        editMode,
        key : 'tags',
      }),
      // todo Ищу попутчиков
    ]
  }
}
