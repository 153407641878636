import wait from 'wait'
import lodash from 'lodash'
import { RoleApplication, HtmlDiv, App } from 'xuick'
import { MapLocationButton } from './MapLocationButton.js'
import { MapPlaceMarker } from './MapPlaceMarker.js'
import params from './params.js'
import api from './api.js'
import './MapApplication.css'

let map, overlay, onAdd

const LOCATION_HASH_RE = /(\d{1,2}\.\d\d)\/(-?\d{1,3}\.\d{5})\/(-?\d{1,3}\.\d{5})/
const DEFAULT_COLOR = '#BABABA'
const STROKE_WIDTH = 3
const RADIUS_DIVIDER = 2.2
const MAP_IDLE_DELAY = 500
const ready = new Promise(resolve => onAdd = resolve)
const routeKey = Symbol()

export class MapApplication extends RoleApplication
{
  static class = 'MapApplication'

  static colors = [
    '#F80000',
    '#FF8328',
    // '#FFCA00',
    '#00B000',
    '#000AFF',
    '#3E754A',
    '#FEB0B0',
  ]

  static getRouteColorByIndex(index) {
    const colorIndex = index % MapApplication.colors.length
    return MapApplication.colors[colorIndex]
  }

  static #parseLocationHash() {
    const match = location.hash.match(LOCATION_HASH_RE)
    if(!match) {
      return null
    }
    const [, zoom, lat, lng] = match
    return {
      center : { lat : +lat, lng : +lng },
      zoom : +zoom,
    }
  }

  #map

  #listeners

  #locationButton

  #places

  #markers = []

  #lines = []

  #moving = false

  #click = false

  init() {
    window.addEventListener('hashchange', this.#onHashChange)
  }

  render() {
    if(this._div) {
      return
    }
    return this._div = new HtmlDiv({
      node : map?.getDiv(),
    })
  }

  mount() {
    const props = this.props
    this.#renderMap()
    if(props.trip) {
      this.#renderTrip(props.trip)
      return
    }
    if(props.route) {
      this.#renderRoute(props.route)
      return
    }
    this.#renderPlaces()
  }

  update(prevProps, prevState) {
    const props = this.props
    this.#renderPlaces()
    if(props.trip) {
      if(props.trip !== prevProps.trip) {
        const places = lodash.flatMap(prevProps.trip.routes, 'places')
        const ids = lodash.keyBy(places, 'id')
        this.#markers.forEach(marker => {
          if(ids[marker.place.id]) {
            MapPlaceMarker.destroy(marker, true)
          }
        })
        this.#removeAllLines()
        this.#renderTrip(props.trip)
      }
      else if(props.route !== prevProps.route) {
        this.#updateRoute()
      }
    }
    else if(prevProps.trip) {
      this.#removeAllLines()
      this.#removeAllMarkers()
    }
    if(props.place !== prevProps.place) {
      this.#markers.forEach(marker => {
        marker.focus = marker.place.id === props.place?.id
      })
      void this.#setLocationHash()
    }
  }

  #updateRoute() {
    const props = this.props
    if(props.route) {
      const index = props.trip.routes.indexOf(props.route)
      const color = MapApplication.getRouteColorByIndex(index)
      this.#lines.forEach(line => {
        line.setOptions({
          strokeColor : line[routeKey] === props.route ?
            color :
            DEFAULT_COLOR,
        })
      })
      this.#markers.forEach(marker => {
        marker.color = lodash.find(props.route.places, ['id', marker.place.id]) && color
      })
      return
    }
    this.#lines.forEach(line => {
      const route = line[routeKey]
      const index = props.trip.routes.indexOf(route)
      line.setOptions({
        strokeColor : MapApplication.getRouteColorByIndex(index),
      })
    })
    this.#markers.forEach(marker => {
      const index = props.trip.routes.findIndex(route => {
        return route.places.find(place => place === marker.place)
      })
      marker.color = MapApplication.getRouteColorByIndex(index)
    })
  }

  destroy() {
    window.removeEventListener('hashchange', this.#onHashChange)
    this.#listeners.forEach(listener => {
      listener.remove()
    })
    MapLocationButton.destroy(this.#locationButton, true)
    this.#removeAllMarkers()
    this.#removeAllLines()
    this._div.node.remove()
    if(!LOCATION_HASH_RE.test(location.hash)) {
      return
    }
    const url = new URL(location)
    url.hash = ''
    history.replaceState(null, '', url)
  }

  #renderMap() {
    const props = this.props
    const { Map, OverlayView } = google.maps
    const options = MapApplication.#parseLocationHash()
    if(!map) {
      map = new Map(this._div.node, {
        mapId : params.env.GOOGLE_MAPS_MAP_ID,
        center : options?.center ?? props.center,
        zoom : options?.zoom ?? props.zoom,
        disableDefaultUI : true,
        keyboardShortcuts : false,
        clickableIcons : false,
      })
      overlay = new OverlayView()
      overlay.setMap(map)
      overlay.onAdd = onAdd
    }
    this.#listeners = [
      map.addListener('idle', this.#onMapIdle),
      map.addListener('click', this.#onMapClick),
      map.addListener('dragend', this.#onMapDragEnd),
      map.addListener('zoom_changed', lodash.debounce(this.#onMapZoomEnd, 500)),
      map.addListener('center_changed', lodash.debounce(this.#onMapMoveEnd, 500)),
    ]
    this.#locationButton = MapLocationButton.render({
      map,
      onerror : this.#onGelocateError,
    })
    this.#map = map
    void api.createGmsRequest({ method : 'maps' })
  }

  #renderPlaces() {
    const props = this.props
    const idsA = lodash.map(this.#places, 'id')
    const idsB = lodash.map(props.places, 'id')
    if(idsA.join() === idsB.join()) {
      return
    }
    const places = lodash.flatMap(props.trip?.routes, 'places')
    const ids = lodash.keyBy(places, 'id')
    const markers = []
    this.#markers.forEach(marker => {
      if(ids[marker.place.id]) {
        markers.push(marker)
      }
      else MapPlaceMarker.destroy(marker, true)
    })
    props.places.forEach(place => {
      if(!ids[place.id]) {
        markers.push(this.#renderMarker(place))
      }
    })
    this.#places = props.places
    this.#markers = markers
  }

  #renderRoute(route, color) {
    const { Polyline, event } = google.maps
    const path = []
    route.places.forEach(place => {
      const [lng, lat] = place.geometry.coordinates
      path.push({ lng, lat })
      this.#markers.push(
        this.#renderMarker(place, color),
      )
    })
    const polyline = new Polyline({
      map : this.#map,
      strokeWidth : STROKE_WIDTH,
      strokeColor : color || DEFAULT_COLOR,
      path,
    })
    polyline[routeKey] = route
    event.addListener(polyline, 'click', this.#onMapClick)
    this.#lines.push(polyline)
  }

  #renderTrip(trip) {
    const props = this.props
    trip.routes.forEach((route, i) => {
      const color = props.route && route.id !== props.route.id ?
        undefined :
        MapApplication.getRouteColorByIndex(i)
      this.#renderRoute(route, color)
    })
  }

  #renderMarker(place, color) {
    const props = this.props
    const marker = MapPlaceMarker.render({
      map : this.#map,
      place,
    })
    marker.on('marker-click', this.#onMarkerClick)
    marker.color = color
    if(place.id === props.place?.id) {
      marker.focus = true
    }
    return marker
  }

  #removeAllMarkers() {
    this.#markers.forEach(marker => {
      MapPlaceMarker.destroy(marker, true)
    })
    this.#markers.length = 0
  }

  #removeAllLines() {
    this.#lines.forEach(line => line.setMap(null))
    this.#lines.length = 0
  }

  async #setLocationHash() {
    const props = this.props
    const url = new URL(location)
    if(props.place || props.route || props.trip) {
      if(url.hash) {
        url.hash = ''
        history.replaceState(null, '', url)
      }
      return
    }
    const zoom = this.#map.getZoom()
    const center = this.getCenter()
    if(!center) {
      return
    }
    const arr = [
      zoom.toFixed(2),
      center.lat.toFixed(5),
      center.lng.toFixed(5),
    ]
    const hash = arr.join('/')
    if(hash !== url.hash) {
      url.hash = hash
      history.replaceState(null, '', url)
    }
  }

  /**
   * @return {Promise<void>}
   */
  ready() {
    return ready
  }

  geolocate() {
    this.#locationButton.click()
  }

  getCenter() {
    const { LatLng } = google.maps
    const center = this.#map.getCenter()
    if(!center) {
      return
    }
    const latLng = new LatLng(center)
    return latLng.toJSON()
  }

  getBounds() {
    return this.#map.getBounds()?.toJSON()
  }

  getRadius() {
    const center = this.getCenter()
    const bounds = this.getBounds()
    const a = {
      lat : center.lat,
      lng : bounds.east,
    }
    const b = {
      lat : center.lat,
      lng : bounds.west,
    }
    const distance = google.maps.geometry.spherical.computeDistanceBetween(a, b)
    return Math.round(distance / RADIUS_DIVIDER)
  }

  moveTo(center, padding, transition = false) {
    const projection = overlay.getProjection()
    const pointA = projection.fromLatLngToDivPixel(center)
    const pointB = {
      x : pointA.x - (padding.right - padding.left) / 2,
      y : pointA.y - (padding.top - padding.bottom) / 2,
    }
    const latLng = projection.fromDivPixelToLatLng(pointB)
    this.#moving = true
    if(transition) {
      this.#map.panTo(latLng)
    }
    else this.#map.setCenter(latLng)
  }

  setCenter(center) {
    this.#map.setCenter(center)
  }

  fitBoundsToMarkers(places, options) {
    if(!places.length) {
      return
    }
    const lngs = []
    const lats = []
    places.forEach(({ geometry }) => {
      lats.push(geometry.coordinates[1])
      lngs.push(geometry.coordinates[0])
    })
    const bounds = {
      south : Math.min(...lats),
      west : Math.min(...lngs),
      north : Math.max(...lats),
      east : Math.max(...lngs),
    }
    this.#moving = true
    this.#map.fitBounds(bounds, options.padding)
  }

  #onGelocateError = () => {
    if(this.#map.getCenter()) {
      return
    }
    this.#map.setOptions({
      center : { lat : 0, lng : 0 },
      zoom : 2,
    })
  }

  #onHashChange = () => {
    const options = MapApplication.#parseLocationHash()
    if(options) {
      this.#map.setOptions(options)
    }
  }

  #onMapClick = () => {
    if(this.#click) {
      this.#click = false
      return
    }
    const props = this.props
    if(props.place) {
      this.app.navigate('/map' + location.search)
      return
    }
    if(props.trip && props.route) {
      const params = new URLSearchParams({
        tripId : props.trip.id,
      })
      this.app.navigate('/map?' + params)
    }
  }

  #onMapDragEnd = () => {
    this.emit('map-dragend', { bubbles : true })
  }

  #onMapIdle = async () => {
    await wait(MAP_IDLE_DELAY)
    this.#moving = false
  }

  #onMapMoveEnd = () => {
    void this.#setLocationHash()
    this.emit('map-moveend', { bubbles : true })
  }

  #onMapZoomEnd = () => {
    void this.#setLocationHash()
    if(!this.#moving) {
      this.emit('map-zoomend', { bubbles : true })
    }
  }

  #onMarkerClick = e => {
    const place = e.target.place
    const props = this.props
    this.#click = true
    if(place === props.place) {
      return
    }
    const params = new URLSearchParams(location.search)
    if(props.trip) {
      const route = props.trip.routes.find(route => {
        return lodash.find(route.places, ['id', place.id])
      })
      if(route) {
        params.set('routeId', route.id)
      }
    }
    this.app.navigate(`/map/place/${ place.id }?${ params }`)
  }

  get app() {
    return this.closest(App)
  }
}
