import { RoleMenu } from 'xuick'
import { NavMenuItem } from './adaptive/NavMenuItem'
import api from './api.js'
import './MobileMenu.css'

export class MobileMenu extends RoleMenu
{
  static class = 'MobileMenu'

  constructor(props) {
    if(!api.user.id) {
      return []
    }
    super(props)
  }

  render() {
    return [
      new NavMenuItem({
        label : 'База знаний',
        to : '/info',
        nav : true,
        icons : [
          'user-manual_outline',
          'user-manual_outline_dim',
        ],
      }),
      new NavMenuItem({
        label : 'Карта',
        to : '/map',
        nav : true,
        icons : [
          'address_outline',
          'address_outline_dim',
        ],
      }),
      new NavMenuItem({
        label : 'Мои поездки',
        to : '/trips',
        nav : true,
        icons : [
          'suitcase_outline',
          'suitcase_outline_dim',
        ],
      }),
      /*new NavMenuItem({
        label : 'Сервисы',
        to : '/services',
        nav : true,
        icons : [
          'tools_outline',
          'tools_outline_dim',
        ],
      }),*/
      new NavMenuItem({
        label : 'Профиль',
        to : `/users/${ api.user.id }`,
        icons : [
          'account_outline',
          'account_outline_dim',
        ],
      }),
    ]
  }
}
