import lodash from 'lodash'
import { App } from 'xuick'
import { Trip } from '../common/Trip.js'
import { Screen } from './Screen.js'
import { MobileMenu } from './MobileMenu.js'
import { MobileHeader } from './MobileHeader.js'
import { InfoPlacesMain } from './InfoPlacesMain.js'
import { InfoPlaceGroupsMain } from './InfoPlaceGroupsMain.js'
import { InfoLocalitiesMain } from './InfoLocalitiesMain.js'
import { InfoCountriesMain } from './InfoCountriesMain.js'
import { InfoRegionsMain } from './InfoRegionsMain.js'
import { InfoGeographyMain } from './InfoGeographyMain.js'
import { InfoMain } from './InfoMain.js'
import { Err404Main } from './Err404Main.js'
import './InfoScreen.css'

const index = lodash.keyBy(Trip.keywords, 'keyword')

export class InfoScreen extends Screen
{
  static class = 'InfoScreen'

  render() {
    const titleText = document.title = 'База знаний'
    return [
      new MobileMenu,
      App.router([
        {
          path : /^\/info\/places(?:\/(\d+))?$/,
          render : placeId => {
            const url = new URL(location)
            const keyword = url.searchParams.get('keyword')
            const localityId = url.searchParams.get('localityId')
            const item = index[keyword]
            return item ?
              new InfoPlacesMain({
                item,
                localityId : +localityId || null,
              }) :
              new InfoPlaceGroupsMain({
                placeId : +placeId || null,
              })
          },
        },
        {
          path : /^\/info\/localities(?:\/(\d+))?$/,
          render : id => new InfoLocalitiesMain({
            localityId : +id || undefined,
          }),
        },
        {
          path : '/info/localities',
          render : InfoLocalitiesMain,
        },
        {
          path : /^\/info\/countries(?:\/(\d+))?$/,
          render : id => new InfoCountriesMain({
            countryId : +id || undefined,
          }),
        },
        {
          path : /^\/info\/regions(?:\/(\d+))?$/,
          render : id => new InfoRegionsMain({
            regionId : +id || undefined,
          }),
        },
        {
          path : '/info/geography',
          render : InfoGeographyMain,
        },
        {
          path : '/info',
          render : () => [
            new MobileHeader({ titleText }),
            new InfoMain,
          ],
        },
        {
          path : /.*/,
          render : () => new Err404Main,
        },
      ]),
    ]
  }
}
