import { Button } from 'xuick'
import './TripFormDialogButton.css'

export class TripFormDialogButton extends Button
{
  static class = 'TripFormDialogButton'

  static defaultProps = {
    icon : 'plus_outline_white',
    hasPopup : 'dialog',
    classList : 'accent round',
  }
}
