import { Button } from 'xuick'
import { SlidePopup } from './SlidePopup.js'
import './TripMenuButton.css'

export class TripMenuButton extends Button
{
  static class = 'TripMenuButton'

  static defaultProps = {
    type : 'menu',
    icon : 'menu-vertical_outline_black',
    popup : {
      modal : true,
      classList : SlidePopup.class,
      direction : 'bottom',
    },
  }
}
