import { HtmlDiv } from 'xuick'
import './PlaceInfoBlock.css'

export class PlaceInfoBlock extends HtmlDiv
{
  static class = 'PlaceInfoBlock'

  state = {
    open : false,
  }

  init() {
    super.init()
    this.on('click', this.#onClick)
  }

  assign() {
    super.assign()
    this.classList = this.state.open && 'open'
  }

  #onClick(e) {
    if(e.target.node.closest('a[href],.Button')) {
      return
    }
    this.setState(state => ({
      open : !state.open,
    }))
  }
}
