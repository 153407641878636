import { SuggestBox } from 'xuick'
import { PlaceOption } from './PlaceOption.js'
import geolocation from './geolocation.js'
import api from './api'

export class PlaceBox extends SuggestBox
{
  static class = 'PlaceBox'

  static defaultProps = {
    Option : PlaceOption,
  }

  location

  async loadItems(query, limit, offset) {
    query = query.trim()
    let location = this.location
    if(!location) {
      try {
        const { coords } = await geolocation.getCurrentPosition()
        this.location = location = {
          lat : coords.latitude,
          lng : coords.longitude,
        }
      }
      catch(err) {
        console.error(err)
      }
    }
    const places = await api.findPlaces({
      query,
      location,
      limit,
      offset,
    })
    return query ?
      [{ id : 0, name : query }, ...places] :
      places
  }

  async loadItem(id) {
    return api.getPlace(id)
  }
}
