import { SuggestBox } from 'xuick'
import api from './api.js'

export class TagBox extends SuggestBox
{
  static class = 'TagBox'

  async loadItems(query, limit, offset) {
    return api.findTags({
      query,
      scope : ['name_en', 'name_ru'],
      order : [['name_ru', 'ASC']],
      limit,
      offset,
    })
  }

  async loadItem(id) {
    const [result] = await api.findTags({
      filters : { id },
      limit : 1,
    })
    return result
  }
}
