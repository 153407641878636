/**
 * @abstract
 */
class Item
{
  /**
   * @param {{}} [item]
   */
  constructor(item = {}) {
    this.__item = item
    this.constructor.__init()
  }

  static __inited = false

  static props = {}

  /**
   * @private
   */
  static __init() {
    if(this.__inited) {
      return
    }
    for(const name in this.props) {
      this.__defineProp(name)
    }
    this.__inited = true
  }

  /**
   * @param {string} name
   * @private
   */
  static __defineProp(name) {
    Object.defineProperty(this.prototype, name, {
      configurable : true,
      enumerable : true,
      get() {
        return this.__item[name]
      },
      set(value) {
        this.__item[name] = value
      },
    })
  }
}

module.exports = { Item }
