import lodash from 'lodash'
import { DialogContent } from 'xuick'
import { ModalDialog } from './ModalDialog.js'
import { HeadBackButton } from './HeadBackButton.js'
import { PlaceDetailsCard } from './PlaceDetailsCard.js'
import './MapPlaceDialog.css'

export class MapPlaceDialog extends ModalDialog
{
  static class = 'MapPlaceDialog'

  static defaultProps = {
    direction : 'bottom',
  }

  render() {
    return new DialogContent([
      new HeadBackButton({
        color : 'white',
      }),
      new PlaceDetailsCard({
        ...lodash.pick(this.props, ['place', 'position', 'route', 'places', 'placeId']),
      }),
    ])
  }
}
