import lodash from 'lodash'
import { Main, Search, SearchBox } from 'xuick'
import { InfoMainHead } from './InfoMainHead.js'
import { InfoPlacesFeed } from './InfoPlacesFeed.js'
import api from './api.js'
import './InfoPlacesMain.css'

export class InfoPlacesMain extends Main
{
  static class = 'InfoPlacesMain'

  state = {
    locality : undefined,
    query : '',
  }

  init() {
    super.init()
    this.on('input', lodash.debounce(this.#onInput, 500))
    if(this.props.localityId) {
      void this.#load()
    }
  }

  async #load() {
    this.setState({
      locality : await api.getLocality(this.props.localityId),
    })
  }

  render() {
    const { item, localityId } = this.props
    let title = item.label
    if(localityId) {
      const locality = this.state.locality
      if(locality) {
        const name = locality.name_ru || locality.name_en
        const label = item.label.toLowerCase()
        title = `${ name }: ${ label }`
      }
      else title = 'Загрузка...'
    }
    return [
      new InfoMainHead(title),
      new Search([
        !localityId && new SearchBox({
          label : 'Введите название',
          placeholder : 'Например, Кремль',
          value : this.state.query,
          oninput : e => {
            this.setState({ query : e.target.value })
          },
        }),
        this._feed = new InfoPlacesFeed({
          key : 'feed',
          keywords : [item.keyword],
          query : this.state.query,
          localityId,
        }),
      ]),
    ]
  }

  #onInput = () => {
    void this._feed.load()
  }
}
