import wait from 'wait'
import { Id, HeadBlock, Main } from 'xuick'
import { Screen } from './Screen.js'
import { MobileMenu } from './MobileMenu.js'
import { MobileHeader } from './MobileHeader.js'
import { LoadingIndicator } from './LoadingIndicator.js'
import { TripFormDialogButton } from './TripFormDialogButton.js'
import { TripFormDialog } from './TripFormDialog.js'
import { TripsFilterGroup } from './TripsFilterGroup.js'
import { TripsFeed } from './TripsFeed.js'
import { TripDialog } from './TripDialog.js'
import api from './api.js'
import './TripsScreen.css'

export class TripsScreen extends Screen
{
  static class = 'TripsScreen'

  static defaultProps = {
    limit : 5,
  }

  state = {
    ...this.state,
    initial : true,
    busy : false,
    stop : false,
    form : false,
    trip : null,
    trips : undefined,
  }

  #dialogId

  init() {
    super.init()
    document.addEventListener('scroll', this.#onDocScroll)
    document.addEventListener('trip-delete', this.#onTripDelete)
    api.on('Trip', this.#onTrip)
  }

  destroy() {
    super.destroy()
    document.removeEventListener('scroll', this.#onDocScroll)
    document.removeEventListener('trip-delete', this.#onTripDelete)
    api.off('Trip', this.#onTrip)
  }

  assign() {
    super.assign()
    document.title = 'Мои поездки'
  }

  render() {
    const { props, state } = this
    const trips = state.trips
    const filter = props.url.searchParams.get('filter') || 'actual'
    return [
      new MobileMenu({
        key : 'menu',
      }),
      new MobileHeader({
        key : 'header',
        titleText : 'Мои поездки',
      }),
      trips ?
        new Main({
          key : 'trips',
          children : [
            new HeadBlock([
              new TripsFilterGroup({
                value : filter,
                onchange : this.#onFilterChange,
              }),
              this._button = new TripFormDialogButton({
                controls : this.#dialogId ??= Id.generate(),
                expanded : state.form,
                onclick : this.#onTripFormDialogButtonClick,
              }),
            ]),
            new TripsFeed({
              trips,
              filter,
              busy : state.busy,
            }),
          ],
        }) :
        new LoadingIndicator({
          key : 'loading',
        }),
      this._tripFormDialog = new TripFormDialog({
        key : 'form',
        anchor : this._button,
        id : this.#dialogId,
        open : state.form,
        classList : state.initial && 'initial',
        oncancel : this.#onFormDialogCancel,
        onload : this.#onFormDialogLoad,
      }),
      new TripDialog({
        key : 'trip',
        tripId : props.tripId,
        open : !!props.tripId,
        oncancel : () => this.app.navigate('/trips'),
        onclose : () => this.app.navigate('/trips'),
      }),
    ]
  }

  mount() {
    super.mount()
    void this.#loadTrips()
  }

  async update(prevProps, prevState) {
    super.update(prevProps, prevState)
    const filterA = prevProps.url.searchParams.get('filter')
    const filterB = this.props.url.searchParams.get('filter')
    if(filterB !== filterA) {
      return this.#loadTrips()
    }
  }

  async #loadTrips(items = []) {
    const state = this.state
    if(state.busy) {
      return
    }
    this.setState({ busy : true })
    try {
      const limit = this.props.limit
      const params = new URLSearchParams(location.search)
      const { trips, total } = await api.getUserTrips({
        filter : params.get('filter') || 'actual',
        limit : this.props.limit,
        offset : items.length,
      })
      this.setState({
        busy : false,
        trips : [...items, ...trips],
        stop : trips.length < limit,
        form : state.initial && !total,
        initial : state.initial && !total,
      })
    }
    catch(error) {
      this.setState({ busy : false })
      throw error
    }
  }

  #onDocScroll = () => {
    const state = this.state
    if(state.stop) {
      return
    }
    const rect = this.node.getBoundingClientRect()
    if(rect.bottom < window.innerHeight * 2) {
      void this.#loadTrips(state.trips)
    }
  }

  #onTripFormDialogButtonClick = () => {
    this.setState({
      form : true,
      initial : false,
    })
  }

  #onFilterChange = e => {
    const url = new URL(location)
    const value = e.target.value || 'actual'
    url.searchParams.set('filter', value)
    this.app.navigate(url)
  }

  #onFormDialogCancel = async () => {
    this.setState({ form : false })
    if(!this.state.initial) {
      return
    }
    const duration = this._tripFormDialog.getMaxTransitionDuration()
    await wait(duration)
    this.setState({ initial : false })
  }

  #onFormDialogLoad = async e => {
    this.state.form = false
    await this.app.navigate('/trips/' + e.target.trip.id)
    await this.#loadTrips()
  }

  #onTrip = trip => {
    const state = this.state
    if(!state.trips) {
      return
    }
    const trips = []
    let item
    for(item of state.trips) {
      if(item.id === trip.id) {
        item = trip
      }
      trips.push(item)
    }
    this.setState({ trips })
  }

  #onTripDelete = async () => {
    await wait(500)
    await this.#loadTrips()
  }
}
