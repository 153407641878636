import { Class, Form, DialogContent, DialogHead, DialogBody, Heading, TextBox, Button } from 'xuick'
import { ProfileItemDialog } from './ProfileItemDialog.js'
import { HeadBackButton } from './HeadBackButton.js'
import api from './api.js'

export class ProfileSiteDialog extends ProfileItemDialog
{
  static class = 'ProfileSiteDialog'

  state = {
    ...this.state,
    busy : false,
    site : null,
  }

  init() {
    super.init()
    this.on('cancel', this.onCancel)
    this.on('submit', this.onSubmit)
  }

  render() {
    const { props, state } = this
    const user = props.user
    const site = state.site || null
    return new Form({
      classList : Class.generate(DialogContent),
      children : [
        new DialogHead([
          new HeadBackButton({
            disabled : state.busy,
          }),
          new Heading('Cайт или профиль в соцсетях'),
        ]),
        new DialogBody([
          new TextBox({
            label : 'Адрес сайта',
            value : state.site ?? user.site ?? 'https://',
            inputMode : 'url',
            enterKeyHint : 'done',
            disabled : state.busy,
            oninput : e => {
              this.setState({ site : e.target.value })
            },
          }),
          new Button({
            label : 'Сохранить',
            type : 'submit',
            classList : 'accent',
            disabled : state.busy || site === user.site,
          }),
        ]),
      ],
    })
  }

  onCancel() {
    this.setState({ site : null })
  }

  async onSubmit() {
    this.setState({ busy : true })
    try {
      await api.updateUser({
        site : this.state.site.trim() || null,
      })
      this.setState({
        busy : false,
        site : null,
      })
      this.emit('load')
    }
    catch(err) {
      this.setState({ busy : false })
    }
  }
}
