import { DateTime } from 'luxon'
import { HtmlDiv, Heading, Time, Button } from 'xuick'
import { DateInterval } from '../common/DateInterval.js'
import { Trip } from '../common/Trip.js'
import { MapObjectCard } from './MapObjectCard.js'
import { TripMenuButton } from './TripMenuButton.js'
import { TripMenu } from './TripMenu.js'
import { UserPhoto } from './UserPhoto.js'

export class MapTripCard extends MapObjectCard
{
  static class = 'MapTripCard'

  render() {
    const { trip } = this.props
    const interval = DateInterval.fromDateRange(trip.interval)
    return [
      new HtmlDiv([
        new Heading(Trip.generateTitle(trip)),
        new Time(interval.toLocaleString(DateTime.DATE_SHORT)),
      ]),
      new HtmlDiv([
        new TripMenuButton({
          key : trip.id,
          menu : menuProps => new TripMenu({
            ...menuProps,
            trip,
          }),
        }),
      ]),
      new HtmlDiv([
        new Button({
          classList : 'accent round',
          icon : 'plus_outline_white',
        }),
        new Button({
          classList : 'accent round',
          icon : 'notepad_outline_white',
          to : `/trips/${ trip.id }`,
        }),
      ]),
      new HtmlDiv([
        new UserPhoto({
          user : trip.creator,
        }),
      ]),
    ]
  }
}
