import { Interval } from 'luxon'
import {
  Class,
  Form,
  DialogContent,
  DialogHead,
  DialogBody,
  DialogFoot,
  Heading,
  Slider,
  Button,
} from 'xuick'
import { FilterDialog } from './FilterDialog'
import { TripDateBox } from './TripDateBox.js'
import { HeadCancelButton } from './HeadCancelButton'
import { TravelModeListBox } from './TravelModeListBox'

export class TripSettingsDialog extends FilterDialog
{
  static class = 'TripSettingsDialog'

  init() {
    super.init()
    this.on('submit', this.#onSubmit)
  }

  render() {
    const props = this.props
    const interval = Interval.fromISO(props.interval)
    return new Form({
      classList : Class.generate(DialogContent),
      children : [
        new DialogHead([
          new HeadCancelButton,
          new Heading('Настройки'),
        ]),
        new DialogBody([
          props.interval && new TripDateBox({
            label : 'Даты поездки',
            text : 'Выберите даты',
            name : 'interval',
            value : props.interval,
            duration : interval.toDuration('days').plus({ days : 1 }),
          }),
          props.maxPlaces && new Slider({
            label : 'Достопримечательностей в день, шт',
            valueMin : 1,
            valueMax : 10,
            disabled : props.busy,
            name : 'maxPlaces',
            value : props.maxPlaces,
          }),
          props.maxDistance && new Slider({
            label : 'Максимальное расстояние между точками, км',
            valueMin : 1,
            valueMax : 15,
            disabled : props.busy,
            name : 'maxDistance',
            value : props.maxDistance,
          }),
          new TravelModeListBox({
            label : 'Транспорт по умолчанию (между точками)',
            disabled : props.busy,
            name : 'travelMode',
            value : props.travelMode,
          }),
        ]),
        new DialogFoot([
          new Button({
            label : 'Сбросить все',
            classList : 'dim',
            disabled : props.busy,
            onclick : () => this.emit('reset'),
          }),
          new Button({
            label : 'Применить',
            type : 'submit',
            classList : 'accent',
            disabled : props.busy,
          }),
        ]),
      ],
    })
  }

  #onSubmit(e) {
    if(e.target === this) {
      return
    }
    e.stopImmediatePropagation()
    this.emit('submit')
  }
}
