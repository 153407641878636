import {
  DialogContent,
  DialogHead,
  Heading,
  DialogBody,
  FootBlock,
  Button,
  ConfirmDialog,
} from 'xuick'
import { ModalDialog } from './ModalDialog'
import { HeadBackButton } from './HeadBackButton'
import { PersonalInfoBlock } from './PersonalInfoBlock'
import { SlidePopup } from './SlidePopup.js'
import api from './api.js'
import './SettingsAccountDialog.css'

export class SettingsAccountDialog extends ModalDialog
{
  static class = 'SettingsAccountDialog'

  static defaultProps = {
    direction : 'right',
  }

  state = {
    ...this.state,
    busy : false,
    confirm : false,
    alert : null,
  }

  render() {
    const state = this.state
    return new DialogContent([
      new DialogHead([
        new HeadBackButton({
          disabled : state.busy,
        }),
        new Heading('Настройки аккаунта'),
      ]),
      new DialogBody([
        /*new DialogMenu({
          disabled : state.busy,
          children : [
            new MenuItemDialog({
              children : 'Личная информация',
              dialog : PersonalInfoDialog,
            }),
            new MenuItem({
              children : 'Верификация',
            }),
          ],
        }),*/
        new PersonalInfoBlock({
          user : api.user,
        }),
        new FootBlock([
          new Button({
            label : 'Выйти из аккаунта',
            classList : 'dim',
            disabled : state.busy,
            onclick : () => location = '/signout',
          }),
          this._button = new Button({
            label : 'Удалить аккаунт',
            classList : 'dim',
            disabled : state.busy,
            onclick : () => {
              this.setState({ confirm : true })
            },
          }),
        ]),
      ]),
      new ConfirmDialog({
        title : 'Вы хотите удалить аккаунт?',
        message : 'Восстановить удаленный аккаунт можно в течение 180 дней после авторизации в приложении. Через 180 дней после заявки на удаление при отсутствии активности аккаунт будет полностью удален.',
        submitLabel : 'Удалить',
        cancelLabel : 'Не удалять',
        open : state.confirm,
        anchor : this._button,
        classList : SlidePopup.class,
        oncancel : () => {
          this.setState({ confirm : false })
        },
        onsubmit : this.onConfirmSubmit,
      }),
    ])
  }

  onConfirmSubmit = async () => {
    this.setState({ busy : true })
    try {
      await api.deleteUser()
      location = '/signout'
    }
    catch(error) {
      this.setState({
        busy : false,
        confirm : false,
      })
      throw error
    }
  }
}
