import { MenuItem, Label } from 'xuick'
import { Icon } from './Icon'
import PlaceCategoryIcons from './PlaceCategoryIcons.js'
import './MapCategoryItem.css'

export class MapCategoryItem extends MenuItem
{
  static class = 'MapCategoryItem'

  static role = 'MenuItemRadio'

  render() {
    const category = this.props.value
    let icon = PlaceCategoryIcons[category.keyword]
    if(!this.props.checked) {
      icon += '_dim'
    }
    return [
      new Icon(icon),
      new Label(category.title),
    ]
  }
}
