import { Loader } from '@googlemaps/js-api-loader'
import params from './params.js'

const options = {
  apiKey : params.env.GOOGLE_MAPS_API_KEY,
  language : params.lang,
  version : 'beta',
  libraries : [
    'maps',
    'marker',
    'geometry',
  ],
}
const loader = new Loader(options)

let promise

function load() {
  return promise ??= new Promise(async (resolve, reject) => {
    try {
      await loader.load()
      await Promise.all(options.libraries.map(
        library => google.maps.importLibrary(library),
      ))
      resolve(google.maps)
    }
    catch(err) {
      reject(err)
    }
  })
}

export default { load }
