import { CheckBox, Control, Label } from 'xuick'
import { Icon } from './Icon'
import PlaceCategoryIcons from './PlaceCategoryIcons.js'
import './PlaceCategoryBox.css'

export class PlaceCategoryBox extends CheckBox
{
  static class = 'PlaceCategoryBox'

  render() {
    let icon = PlaceCategoryIcons[this.name] || 'location_filled'
    if(this.props.disabled) {
      icon += '_dim'
    }
    return [
      new Control([
        new Icon(icon),
        new Label(this.props.label),
      ]),
    ]
  }
}
