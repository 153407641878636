import { Table, Row, Cell, Icon } from 'xuick'
import { InfoObjectStatsPanel } from './InfoObjectStatsPanel.js'

const formatter = Intl.NumberFormat('ru')

export class InfoCountryStatsPanel extends InfoObjectStatsPanel
{
  static class = 'InfoCountryStatsPanel'

  render() {
    if(this.props.hidden) {
      return
    }
    const country = this.props.object
    const languages = country.languages.map(item => {
      const name = item.name_ru || item.name_en || item.code
      return item.CountryLanguage.official ?
        `${ name } (оф.)` :
        name
    })
    const currency = country.currency
    return new Table([
      new Row([
        new Cell(new Icon('language_outline')),
        new Cell('Языки общения'),
        new Cell(
          languages.length ? languages.join(', ') : '—',
        ),
      ]),
      new Row([
        new Cell(new Icon('community_outline')),
        new Cell('Население'),
        new Cell(
          `${ formatter.format(country.population) } чел.`,
        ),
      ]),
      new Row([
        new Cell(new Icon('cash_outline')),
        new Cell('Валюта'),
        new Cell(
          currency ?
            `${ currency.name_ru || currency.name_en }, ${ currency.code }` :
            '—',
        ),
      ]),
      new Row([
        new Cell(new Icon('www_outline')),
        new Cell('Доменное имя'),
        new Cell(country.tld || '—'),
      ]),
    ])
  }
}
