import { Dialog, TextBox, PopupContent, Button, RoleForm, Heading } from 'xuick'
import { SlidePopup } from './SlidePopup.js'
import api from './api.js'
import './UserStatusDialog.css'

export class UserStatusDialog extends Dialog
{
  static class = 'UserStatusDialog'

  static defaultProps = {
    modal : true,
    classList : SlidePopup.class,
    direction : 'bottom',
  }

  state = {
    ...this.state,
    busy : false,
    value : null,
  }

  init() {
    super.init()
    this.on('submit', this.#onSubmit)
  }

  render() {
    const state = this.state
    return new RoleForm({
      classList : PopupContent.class,
      children : [
        new Heading('Изменить статус'),
        new TextBox({
          value : state.value ?? this.props.user.statusText,
          enterKeyHint : 'done',
          spellcheck : 'false',
          disabled : state.busy,
          oninput : e => {
            this.setState({ value : e.target.value })
          },
        }),
        new Button({
          label : 'Сохранить',
          type : 'submit',
          classList : 'accent',
          disabled : state.busy,
        }),
      ],
    })
  }

  async #onSubmit() {
    const value = this.state.value
    this.setState({ busy : true })
    try {
      await api.updateUser({
        statusText : value?.trim() || null,
      })
      this.state.busy = false
      this.state.value = null
      this.emit('load')
    }
    catch(err) {
      this.setState({ busy : false })
      throw err
    }
  }
}
