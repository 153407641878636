import { TabPanel } from 'xuick'
import { PlaceDetailsCard } from './PlaceDetailsCard.js'
import './InfoPlaceDetailsPanel.css'

export class InfoPlaceDetailsPanel extends TabPanel
{
  static class = 'InfoPlaceDetailsPanel'

  render() {
    if(this.props.hidden) {
      return
    }
    return new PlaceDetailsCard({
      place : this.props.place,
    })
  }
}
