import { HtmlArticle, MainBlock, Heading, LeftBlock, RightBlock } from 'xuick'
import { PlaceCategoryBlock } from './PlaceCategoryBlock.js'
import { PlacePriceBlock } from './PlacePriceBlock.js'
import { PlaceHoursBlock } from './PlaceHoursBlock.js'
import { PlaceFeaturesBlock } from './PlaceFeaturesBlock.js'
import { PlaceRating } from './PlaceRating.js'
import { PlaceActionsBlock } from './PlaceActionsBlock.js'
import { MapPlaceDialog } from './MapPlaceDialog.js'
import api from './api.js'
import './MapPlaceCard.css'

export class MapPlaceCard extends HtmlArticle
{
  static class = 'MapPlaceCard'

  state = {
    expanded : false,
    place : undefined,
  }

  async init() {
    this.on('click', this.#onClick)
    if(this.props.place) {
      return
    }
    this.setState({
      place : await api.getPlace(this.props.placeId),
    })
  }

  render() {
    const { props, state } = this
    const position = props.position
    const place = state.place || props.place
    if(!place) {
      return
    }
    return [
      new MainBlock([
        new LeftBlock([
          new Heading(place.name),
          new PlaceCategoryBlock({
            place,
            maxNames : 1,
          }),
          new PlaceHoursBlock({ place }),
          new PlaceFeaturesBlock({
            place,
            maxItems : 4,
          }),
        ]),
        new RightBlock([
          new PlaceRating({ place }),
          new PlacePriceBlock({ place }),
        ]),
      ]),
      new PlaceActionsBlock({
        place,
        position,
        route : props.route,
        places : props.places,
        placeId : props.placeId,
        onclick : e => e.stopPropagation(),
      }),
      new MapPlaceDialog({
        place,
        position,
        route : props.route,
        places : props.places,
        placeId : props.placeId,
        open : state.expanded,
        oncancel : () => {
          this.setState({ expanded : false })
        },
      }),
    ]
  }

  #onClick() {
    this.setState({ expanded : true })
  }
}
