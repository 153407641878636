import { Main, Heading, MainBlock, Group } from 'xuick'
import { InfoMainHead } from './InfoMainHead'
import { InfoObjectNav } from './InfoObjectNav.js'
import { InfoObjectLink } from './InfoObjectLink.js'

export class InfoGeographyMain extends Main
{
  static class = 'InfoGeographyMain'

  render() {
    return [
      new InfoMainHead('География'),
      new MainBlock(
        new InfoObjectNav([
          new Heading('Социально-экономическая'),
          new Group([
            new InfoObjectLink({
              label : 'Регионы',
              photo : 'images/regions.webp',
              to : '/info/regions',
            }),
            new InfoObjectLink({
              label : 'Страны и территории',
              photo : 'images/countries.webp',
              to : '/info/countries',
            }),
            new InfoObjectLink({
              label : 'Населенные пункты',
              photo : 'images/localities.webp',
              to : '/info/localities',
            }),
          ]),
        ]),
      ),
    ]
  }
}
