import { DateTime, Interval } from 'luxon'
import { Class, Dialog, DatePicker, DialogContent, Button, Form, Time } from 'xuick'
import './TripDateDialog.css'

export class TripDateDialog extends Dialog
{
  static class = 'TripDateDialog'

  static props = {
    required : undefined,
  }

  static defaultProps = {
    modal : true,
    submitLabel : 'Выбрать даты',
  }

  state = {
    ...this.state,
    value : undefined,
  }

  value

  init() {
    super.init()
    this.on('cancel', this.#onCancel)
  }

  #onCancel() {
    if(this.state.value) {
      this.setState({ value : undefined })
    }
  }

  render() {
    const props = this.props
    const value = this.state.value ?? props.interval
    const interval = value && Interval.fromISO(value)
    return new Form({
      classList : Class.generate(DialogContent),
      onsubmit : e => {
        e.stopPropagation()
        this.emit('submit')
      },
      children : [
        this._datePicker = new DatePicker({
          key : 'widget',
          type : 'range',
          duration : props.duration,
          required : props.required,
          disabled : props.busy,
          value,
          onchange : e => {
            this.value = e.target.value
            this.setState({ value : this.value })
          },
        }),
        new Time({
          key : 'time',
          dateTime : interval?.toISODate(),
          children : interval?.toLocaleString(DateTime.DATE_FULL) || ' ',
        }),
        new Button({
          key : 'button',
          label : props.submitLabel,
          classList : 'accent',
          type : 'submit',
          disabled : props.busy || (props.interval ?
            this.value === props.interval :
            !this.value),
        }),
      ],
    })
  }

  reset() {
    this._datePicker.reset()
    this.setState({ value : undefined })
  }
}
