const { DateInterval } = require('./DateInterval.js')

class Trip
{
  static keywords = [
    {
      label : 'Интересные места',
      keyword : 'places to visit',
      limit : 60,
    },
    {
      label : 'Парки',
      keyword : 'park',
      filter : true,
    },
    {
      label : 'Музеи',
      keyword : 'museum',
      filter : true,
    },
    {
      label : 'Театры',
      keyword : 'theatre',
      type : 'tourist_attraction',
      filter : true,
    },
    {
      label : 'Концерты',
      keyword : 'concert hall',
      filter : true,
    },
    {
      label : 'Цирки',
      keyword : 'circus',
      filter : true,
    },
    {
      label : 'Кинотеатры',
      keyword : 'movie theater',
      filter : true,
    },
    {
      label : 'Архитектурные объекты',
      keyword : 'building',
      type : 'tourist_attraction',
      filter : true,
    },
    {
      label : 'Памятники',
      keyword : 'monument',
      filter : true,
    },
    {
      label : 'Исторические места',
      keyword : 'historical place',
      filter : true,
    },
    {
      label : 'Религиозные места',
      keyword : 'religious place',
      filter : true,
    },
    {
      label : 'Зоопарки',
      keyword : 'zoo',
      type : 'tourist_attraction',
      filter : true,
    },
    {
      label : 'Места для фотографий',
      keyword : 'observation deck',
      filter : true,
    },
    {
      label : 'Пляжи и берега',
      keyword : 'beach',
      type : 'natural_feature',
      filter : true,
    },
    /*{
      label : 'Горы и вершины',
      keyword : 'mountain',
      type : 'natural_feature',
      filter : true,
    },*/
    /*{
      label : 'Вулканы и кратеры',
      keyword : 'volcano',
      type : 'natural_feature',
      filter : true,
    },*/
    /*{
      label : 'Пещеры',
      keyword : 'cave',
      type : 'natural_feature',
      filter : true,
    },*/
    {
      label : 'Сады',
      keyword : 'garden',
      type : 'tourist_attraction',
      filter : true,
    },
    {
      label : 'Озера',
      keyword : 'lake',
      filter : true,
    },
    /*{
      label : 'Водопады',
      keyword : 'waterfall',
      type : 'tourist_attraction',
      filter : true,
    },*/
  ]

  static generateTitle(trip) {
    if(trip.title) {
      return trip.title
    }
    const destination = trip.destination
    const name = destination.name_ru || destination.name_en
    const interval = DateInterval.fromDateRange(trip.interval)
    const duration = interval.toDuration('days', { locale : 'ru' })
    const days = duration.plus({ days : 1 })
    return `${ name } на ${ days.toHuman() }`
  }
}

module.exports = { Trip }
