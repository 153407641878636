import { Heading, Inner } from 'xuick'
import { TripObjectCard } from './TripObjectCard.js'
import { TripPhoto } from './TripPhoto'
import { TripDaySpan } from './TripDaySpan.js'
import wpc from '../common/wpc.js'
import './TripRouteCard.css'

const div = document.createElement('div')

export class TripRouteCard extends TripObjectCard
{
  static class = 'TripRouteCard'

  state = {
    locality : undefined,
  }

  init() {
    void this.#load()
  }

  render() {
    const props = this.props
    const trip = props.trip
    const route = props.route
    const photos = route.places[0]?.photos
    const locality = this.state.locality || route.places[0]?.locality || trip.destination
    const day = trip.routes.indexOf(route) + 1
    if(locality.info) {
      div.innerHTML = locality.info
      locality.info = div.textContent
    }
    return [
      new TripPhoto({
        photo : photos?.[0] || locality.photos?.[0],
      }),
      new Inner([
        new TripDaySpan(`День ${ day }`),
        new Heading(locality.name_ru || locality.name_en),
      ]),
    ]
  }

  update(prevProps, prevState) {
    super.update(prevProps, prevState)
    const localityA = prevProps.route.places[0]?.locality
    const localityB = this.props.route.places[0]?.locality
    if(localityA?.id !== localityB?.id) {
      void this.#load()
    }
  }

  async #load() {
    const props = this.props
    const locality = props.route.places[0]?.locality || props.trip.destination
    if(locality.photos?.length) {
      return
    }
    const summary = await wpc.getLocalitySummary(locality, 'ru')
    if(!summary?.originalimage) {
      return
    }
    this.setState({
      locality : {
        ...locality,
        photos : [
          { image : summary.originalimage.source },
        ],
      },
    })
  }
}
