import io from 'socket.io-client'
import { AppError } from './AppError'

// todo ApiClient
export class AppInterface
{
  /**
   * @param {{}} options
   */
  constructor(options) {
    const {
      url = '/',
      methods = [],
      logging = false,
      ...socketOptions
    } = options
    this.options = options
    this.socket = io(url, {
      autoConnect : false,
      ...socketOptions,
    })
    for(const methodName of methods) {
      this[methodName] ??= (...args) => {
        return this.call(methodName, ...args)
      }
    }
    if(!logging) {
      return
    }
    this.socket.onAny((eventName, ...args) => {
      console.log(`** ${ eventName }:`, ...args)
    })
  }

  #connected = false

  connect() {
    this.socket.connect()
    this.#connected = true
  }

  /**
   * @param {string} eventName
   * @param {function} callback
   */
  on(eventName, callback) {
    this.socket.on(eventName, callback)
  }

  /**
   * @param {string} [eventName]
   * @param {function} [callback]
   */
  off(eventName, callback) {
    this.socket.off(eventName, callback)
  }

  /**
   * @param {string} methodName
   * @param {any[]} args
   * @returns {Promise<any>}
   */
  async call(methodName, ...args) {
    if(this.options.logging) {
      console.log(`-> ${ methodName }:`, ...args)
    }
    if(!this.#connected) {
      return this.get(methodName, ...args)
    }
    return new Promise((resolve, reject) => {
      this.socket.emit(methodName, ...args, (error, result) => {
        if(this.options.logging) {
          console.log(`<- ${ methodName }:`, error || result)
        }
        if(error) {
          reject(new AppError(error))
        }
        else resolve(result)
      })
    })
  }

  async get(methodName, ...args) {
    const input = `api/${ methodName }`
    const url = new URL(input, location.origin)
    const res = await fetch(url, {
      method : 'POST',
      headers : {
        'Content-Type' : 'application/json',
        'Accept' : 'application/json',
      },
      body : JSON.stringify(args),
    })
    if(!res.ok) {
      const error = Error(decodeURIComponent(res.statusText))
      if(this.options.logging) {
        console.log(`<- ${ methodName }:`, error)
      }
      throw error
    }
    const result = await res.json()
    if(this.options.logging) {
      console.log(`<- ${ methodName }:`, result)
    }
    return result
  }
}

export default AppInterface
