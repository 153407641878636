import { HtmlDiv, Inner, Button } from 'xuick'
import { Icon } from './Icon'
import { User } from './adaptive/User.js'
import { OnlineIndicator } from './adaptive/OnlineIndicator'
import api from './api.js'
import './ProfileName.css'

const $user = new User(api.user)

export class ProfileName extends HtmlDiv
{
  static class = 'ProfileName'

  render() {
    const user = this.props.user
    return [
      new Inner([
        user.name,
        user.online && new OnlineIndicator,
      ]),
      user.id === api.user.id ?
        new Button({
          children : this.props.editMode ?
            new Icon('edit_filled') :
            new Icon('pencil_outline'),
          onclick : () => {
            this.emit('edit-mode', { bubbles : true })
          },
        }) :
        !!api.user.role && new Button({
          type : 'favorite',
          onclick : this.#onButtonClick,
          children : $user.isFollowerOf(user) ?
            new Icon('star_filled') :
            new Icon('star_outline'),
        }),
    ]
  }

  #onButtonClick = async () => {
    const user = this.props.user
    if($user.isFollowerOf(user)) {
      await api.unfollowUser(user.id)
    }
    else await api.followUser(user.id)
  }
}
