import lodash from 'lodash'
import { DateTime } from 'luxon'
import { Heading, Inner, Time } from 'xuick'
import { TripObjectCard } from './TripObjectCard.js'
import { PlacePhoto } from './PlacePhoto.js'
import { TripDaySpan } from './TripDaySpan.js'
import './TripPlaceInfoCard.css'

export class TripPlaceInfoCard extends TripObjectCard
{
  static class = 'TripPlaceInfoCard'

  render() {
    const { trip, routeId, placeId } = this.props
    const route = lodash.find(trip.routes, ['id', routeId])
    const place = lodash.find(route.places, ['id', placeId])
    const number = trip.routes.indexOf(route) + 1
    const dt = DateTime.fromISO(route.date)
    const dateString = dt.toLocaleString({
      day : 'numeric',
      month : 'long',
    })
    return [
      new PlacePhoto({ place }),
      new Inner([
        new TripDaySpan(`День ${ number }`),
        new Time(dateString),
        new Heading(place.name),
      ]),
    ]
  }
}
