import { Form, Class, PopupContent, TextBox, Button, Heading } from 'xuick'
import { CommentDialog } from './CommentDialog.js'
import { DateTime } from 'luxon'
import api from './api.js'

export class TripRouteCommentDialog extends CommentDialog
{
  static class = 'TripRouteCommentDialog'

  state = {
    ...this.state,
    busy : false,
    comment : undefined,
  }

  init() {
    super.init()
    this.on('input', this.#onInput)
    this.on('submit', this.#onSubmit)
  }

  render() {
    const state = this.state
    const route = this.props.route
    const dt = DateTime.fromISO(route.date)
    const date = dt.toLocaleString({
      month : 'numeric',
      day : 'numeric',
    })
    return new Form({
      classList : Class.generate(PopupContent),
      children : [
        new Heading(`Комментарий к ${ date } День ${ this.props.number }`),
        new TextBox({
          placeholder : 'Введите текст',
          multiLine : true,
          value : this.state.comment ?? route.comment,
          disabled : state.busy,
        }),
        new Button({
          type : 'submit',
          classList : 'accent',
          label : 'Сохранить',
          disabled : state.busy,
        }),
      ],
    })
  }

  #onInput(e) {
    this.setState({
      comment : e.target.value,
    })
  }

  async #onSubmit() {
    this.setState({ busy : true })
    try {
      await api.updateRoute(this.props.route.id, {
        comment : this.state.comment || null,
      })
      this.setState({ busy : false })
      this.emit('load')
    }
    catch(err) {
      this.setState({ busy : false })
      throw err
    }
  }
}
