import { Main } from 'xuick'
import { InfoSectionsBlock } from './InfoSectionsBlock.js'
import { InfoLocalitiesBlock } from './InfoLocalitiesBlock.js'
import { InfoPlacesBlock } from './InfoPlacesBlock.js'
import './InfoMain.css'

export class InfoMain extends Main
{
  static class = 'InfoMain'

  render() {
    return [
      new InfoSectionsBlock,
      new InfoLocalitiesBlock,
      new InfoPlacesBlock({
        classList : 'compact',
        href : '/info/places',
      }),
    ]
  }
}
