import lodash from 'lodash'
import { Main, SearchBox, Search } from 'xuick'
import { InfoMainHead } from './InfoMainHead.js'
import { InfoLocalitiesFeed } from './InfoLocalitiesFeed.js'
import { InfoLocalityDialog } from './InfoLocalityDialog.js'
import './InfoLocalitiesMain.css'

export class InfoLocalitiesMain extends Main
{
  static class = 'InfoLocalitiesMain'

  state = {
    query : '',
  }

  init() {
    super.init()
    this.on('input', lodash.debounce(this.#onInput, 500))
  }

  render() {
    const objectId = this.props.localityId
    return [
      new InfoMainHead('Города'),
      new Search([
        new SearchBox({
          label : 'Введите название города',
          placeholder : 'Например, Казань',
          value : this.state.query,
          oninput : e => {
            this.setState({ query : e.target.value })
          },
        }),
        this._feed = new InfoLocalitiesFeed({
          query : this.state.query,
        }),
        new InfoLocalityDialog({
          objectId,
          open : !!objectId,
          oncancel : () => history.back(),
        }),
      ]),
    ]
  }

  #onInput = () => {
    void this._feed.load()
  }
}
