import wait from 'wait'
import lodash from 'lodash'
import { List } from 'xuick'
import { TripRouteItem } from './TripRouteItem.js'
import api from './api.js'
import './TripRoutesList.css'

export class TripRoutesList extends List
{
  static class = 'TripRoutesList'

  state = {
    grabbedItem : null,
    busy : false,
  }

  trip

  #timerId = null
  #routes = null
  #items = null
  #rects = null
  #rect = null

  init() {
    super.init()
    this.on('contextmenu', this.#onContextMenu)
    this.on('touchstart', this.#onTouchStart)
  }

  render() {
    const state = this.state
    this.busy = state.busy
    return this.trip.routes.map(
      (route, i) => new TripRouteItem({
        route,
        trip : this.trip,
        key : route.id,
        number : i + 1,
        grabbed : route.date === state.grabbedItem?.route.date,
      }),
    )
  }

  update(prevProps, prevState) {
    super.update(prevProps, prevState)
    if(!this.#items) {
      return
    }
    const idsA = lodash.map(this.trip.routes, 'id')
    const idsB = lodash.map(prevProps.trip.routes, 'id')
    if(idsA.join() === idsB.join()) {
      return
    }
    this.#items.forEach(item => {
      item.node.style.transform = null
    })
  }

  #onContextMenu(e) {
    e.preventDefault()
  }

  #onTouchStart(e) {
    if(e.touches.length > 1) {
      return
    }
    const grabbedItem = e.target.closest(TripRouteItem)
    if(!grabbedItem?.route) {
      return
    }
    const handler = () => {
      this.setState({ grabbedItem })
      this.#onTouchStartTimeout(e)
    }
    this.on('touchmove', this.#onTouchMove)
    this.on('touchend', this.#onTouchEnd, { once : true })
    this.#timerId = setTimeout(handler, 500)
  }

  #onTouchStartTimeout(e) {
    const [touch] = e.touches
    const grabbedItem = this.state.grabbedItem
    const rect = grabbedItem.node.getBoundingClientRect()
    const y = touch.clientY - rect.top - rect.height / 2
    grabbedItem.node.style.transform = `translateY(${ y }px) rotate(2deg)`
    this.#timerId = null
    this.#items = this.findAll(
      TripRouteItem,
      item => item.route,
    )
    this.#rects = this.#items.map(
      item => item === grabbedItem ?
        rect :
        item.node.getBoundingClientRect(),
    )
    this.#rect = rect
    this.emit('drag-start', { bubbles : true })
  }

  #onTouchMove(e) {
    const grabbedItem = this.state.grabbedItem
    if(!grabbedItem) {
      if(this.#timerId) {
        clearTimeout(this.#timerId)
        this.#timerId = null
        this.off('touchmove', this.#onTouchMove)
      }
      return
    }
    const routes = this.#items.map(item => ({
      id : grabbedItem.route.id,
      date : item.route.date,
    }))
    const top = this.#rect.top
    const offset = this.#rect.height / 2
    const min = this.#rects[0].top + offset
    const max = this.#rects.at(-1).bottom - offset
    const [touch] = e.touches
    let rect, clientY, y, j
    this.#items.forEach((item, i) => {
      if(item === grabbedItem) {
        clientY = touch.clientY
        clientY = Math.min(clientY, max)
        clientY = Math.max(clientY, min)
        y = clientY - top - this.#rect.height / 2
        item.node.style.transform = `translateY(${ y }px) rotate(2deg)`
        return
      }
      rect = this.#rects[i]
      if(rect.top < top && rect.bottom > touch.clientY) {
        j = i + 1
      }
      else if(rect.top > top && rect.top < touch.clientY) {
        j = i - 1
      }
      else {
        routes[i].id = item.route.id
        item.node.style.transform = null
        return
      }
      routes[j].id = item.route.id
      y = this.#rects[j].top - rect.top
      item.node.style.transform = `translateY(${ y }px)`
    })
    this.#routes = routes
  }

  #onTouchEnd() {
    clearTimeout(this.#timerId)
    this.#timerId = null
    this.off('touchmove', this.#onTouchMove)
    this.emit('drag-end', { bubbles : true })
    if(!this.state.grabbedItem) {
      return
    }
    const idsA = lodash.map(this.trip.routes, 'id')
    const idsB = lodash.map(this.#routes, 'id')
    if(this.#routes && idsA.join() !== idsB.join()) {
      void this.#updateRouteOrder()
      return
    }
    this.#items.forEach(item => {
      item.node.style.transform = null
    })
    this.setState({ grabbedItem : null })
    this.#routes = null
    this.#items = null
  }

  async #updateRouteOrder() {
    this.setState({ busy : true })
    try {
      await wait(50)
      await api.updateTripRoutes(this.trip.id, this.#routes)
    }
    catch(err) {
      this.#items.forEach(item => {
        item.node.style.transform = null
      })
      throw err
    }
    finally {
      this.setState({
        grabbedItem : null,
        busy : false,
      })
      this.#routes = null
      this.#items = null
    }
  }
}
