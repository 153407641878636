const { Interval, DateTime } = require('luxon')

Interval.fromDateRange = function(range) {
  const [start, end] = range
  const dtEnd = DateTime.fromISO(end.value)
  return Interval.fromDateTimes(
    DateTime.fromISO(start.value),
    dtEnd.minus({ days : 1 }),
  )
}

Interval.prototype.toDateRange = function() {
  const dtEnd = this.end.plus({ days : 1 })
  return [
    {
      value : this.start.toISODate(),
      inclusive : true,
    },
    {
      value : dtEnd.toISODate(),
      inclusive : false,
    },
  ]
}

module.exports = {
  DateInterval : Interval,
}
