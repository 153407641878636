import { HtmlDiv, Button, App } from 'xuick'
import { DateInterval } from '../common/DateInterval.js'
import { MapApplication } from './MapApplication.js'
import api from './api.js'
import './MapTripBlock.css'

export class MapTripBlock extends HtmlDiv
{
  static class = 'MapTripBlock'

  render() {
    const { trip, route } = this.props
    return [
      ...trip.routes.map(
        (item, i) => new Button({
          label : `День ${ i + 1 }`,
          pressed : item.id === route?.id,
          classList : 'flying',
          to : '/map?' + new URLSearchParams({
            tripId : trip.id,
            routeId : item.id,
          }),
          style : `--button-border-color : ${ MapApplication.getRouteColorByIndex(i) }`,
        }),
      ),
      new Button({
        classList : 'flying round',
        icon : 'plus_outline_dim',
        onclick : this.#onRouteButtonClick,
      }),
    ]
  }

  #onRouteButtonClick = async () => {
    const trip = this.props.trip
    const interval = DateInterval.fromDateRange(trip.interval)
    const dt = interval.end.plus({ days : 1 })
    const date = dt.toISODate()
    this.setState({ busy : true })
    try {
      const route = await api.createRoute({
        tripId : trip.id,
        date,
      })
      const params = new URLSearchParams({
        tripId : trip.id,
        routeId : route.id,
      })
      const app = this.closest(App)
      await app.navigate('/map?' + params)
    }
    finally {
      this.setState({ busy : false })
    }
  }
}
