import { HtmlArticle, Link, Button } from 'xuick'
import { Icon } from './Icon.js'
import { User } from './adaptive/User.js'
import { UserCardPhoto } from './adaptive/UserCardPhoto'
import { UserName } from './adaptive/UserName'
import api from './api.js'
import './MobileUserCard.css'

const $user = new User(api.user)

export class MobileUserCard extends HtmlArticle
{
  static class = 'MobileUserCard'

  state = {
    user : undefined,
  }

  init() {
    api.on('User', this.#onUser)
  }

  destroy() {
    api.off('User', this.#onUser)
  }

  render() {
    const user = this.state.user || this.props.user
    return new Link({
      to : '/users/' + user.id,
      children : [
        new UserCardPhoto({ user }),
        new UserName(user.name),
        !!api.user.role && new Button({
          onclick : this.#onButtonClick,
          children : $user.isFollowerOf(user) ?
            new Icon('star_filled') :
            new Icon('star_outline'),
        }),
      ],
    })
  }

  #onButtonClick = async e => {
    const user = this.state.user || this.props.user
    e.preventDefault()
    e.stopPropagation()
    if($user.isFollowerOf(user)) {
      await api.unfollowUser(user.id)
    }
    else await api.followUser(user.id)
  }

  #onUser = user => {
    if(user.id !== this.props.user.id) {
      return
    }
    this.setState({
      user : { ...this.props.user, ...user },
    })
  }
}
