import { Button } from 'xuick'
import './MapBackButton.css'

export class MapBackButton extends Button
{
  static class = 'MapBackButton'

  static defaultProps = {
    classList : 'flying',
    icon : 'left_outline_black',
  }

  constructor(props) {
    const { place, route, trip, ...rest } = props
    if(route) {
      const params = new URLSearchParams
      params.set('tripId', trip.id)
      if(place) {
        params.set('routeId', route.id)
      }
      rest.to = '/map?' + params
    }
    else {
      rest.to = route ?
        `/trips/${ trip.id }#${ route.date }` :
        `/trips/${ trip.id }`
    }
    super({ ...rest })
  }
}
