import { Button, Icon, Search, NativeInputBox, App } from 'xuick'
import { MobileHeader } from './MobileHeader'
import './SearchHeader.css'

export class SearchHeader extends MobileHeader
{
  static class = 'SearchHeader'

  state = {
    query : null,
  }

  init() {
    this.on('submit', this.#onSubmit)
    document.addEventListener('app-navigate', this.#onAppNavigate)
  }

  render() {
    const params = new URLSearchParams(location.search)
    return [
      new Button({
        type : 'back',
        children : new Icon('left_outline'),
        onclick : () => history.back(),
      }),
      new Search({
        tagName : 'form',
        children : [
          this._searchBox = new NativeInputBox({
            type : 'search',
            placeholder : 'Поиск пользователей',
            value : this.state.query ?? params.get('query'),
            spellcheck : 'false',
            oninput : e => {
              this.setState({ query : e.target.value })
            },
          }),
          new Button({
            type : 'submit',
            children : new Icon('search_outline'),
          }),
        ],
      }),
    ]
  }

  mount() {
    super.mount()
    this._searchBox.focus()
  }

  #onAppNavigate = () => {
    this.setState({ query : null })
  }

  #onSubmit(e) {
    e.preventDefault()
    const params = new URLSearchParams(location.search)
    const query = this.state.query ?? params.get('query')
    const app = this.closest(App)
    const url = new URL('/search', location.origin)
    url.searchParams.set('query', query || '')
    app.navigate(url.href, {
      replace : true,
    })
  }
}
