import { Dialog, PopupContent, FileBox, CameraBox } from 'xuick'
import { AppError } from './adaptive/AppError.js'
import { SlidePopup } from './SlidePopup.js'
import params from './params.js'
import './ProfilePhotoSelectDialog.css'

const fileSizeMax = params.env.FILE_SIZE_MAX / 1e6

export class ProfilePhotoSelectDialog extends Dialog
{
  static class = ['ProfilePhotoSelectDialog', SlidePopup.class].join(' ')

  static defaultProps = {
    modal : true,
    direction : 'bottom',
  }

  file

  render() {
    return new PopupContent([
      new FileBox({
        label : 'Выбрать из галереи',
        classList : 'accent',
        accept : 'image/*',
        onchange : this.#onFileBoxChange,
        oncancel : e => e.stopPropagation(),
      }),
      new CameraBox({
        label : 'Сделать снимок',
        classList : 'accent',
        onchange : this.#onCameraBoxChange,
      }),
    ])
  }

  #onFileBoxChange = async e => {
    e.stopPropagation()
    const file = e.target.file
    if(file.size > params.env.FILE_SIZE_MAX) {
      e.target.reset()
      throw new AppError(`Размер файла превышает допустимый лимит ${ fileSizeMax }MB`)
    }
    this.file = e.target.file
    this.emit('change')
  }

  #onCameraBoxChange = e => {
    e.stopPropagation()
    this.file = e.target.file
    this.emit('change')
  }
}
