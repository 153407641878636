import { HtmlDiv, MainBlock, Heading, Group } from 'xuick'
import { PlacePhotoGroup } from './PlacePhotoGroup.js'
import { PlaceCategoryBlock } from './PlaceCategoryBlock.js'
import { PlaceRating } from './PlaceRating.js'
import { PlaceHoursBlock } from './PlaceHoursBlock.js'
import { PlacePriceBlock } from './PlacePriceBlock.js'
import { PlaceLocationBlock } from './PlaceLocationBlock.js'
import { PlaceLinksBlock } from './PlaceLinksBlock.js'
import { PlaceDurationBlock } from './PlaceDurationBlock.js'
import { PlaceFeaturesBlock } from './PlaceFeaturesBlock.js'
import { PlaceActionsBlock } from './PlaceActionsBlock.js'
import './PlaceDetailsCard.css'

export class PlaceDetailsCard extends HtmlDiv
{
  static class = 'PlaceDetailsCard'

  render() {
    const props = this.props
    const { place, position } = props
    return [
      new PlacePhotoGroup({ place }),
      new MainBlock([
        new Group([
          new HtmlDiv([
            new Heading(place.name),
            new PlaceCategoryBlock({ place }),
          ]),
          new PlaceRating({ place }),
        ]),
        new Group([
          new PlaceHoursBlock({ place }),
          new PlacePriceBlock({ place }),
        ]),
        new PlaceLocationBlock({ place }),
        new PlaceLinksBlock({ place }),
        new PlaceDurationBlock({ place, position }),
        new PlaceFeaturesBlock({ place }),
      ]),
      new PlaceActionsBlock({
        place,
        position,
        route : props.route,
        places : props.places,
        placeId : props.placeId,
      }),
    ]
  }
}
