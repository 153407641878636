import { Status, Article } from 'xuick'
import { InfoObjectAboutPanel } from './InfoObjectAboutPanel.js'
import { LocalityCard } from './LocalityCard'
import './InfoLocalityAboutPanel.css'

export class InfoLocalityAboutPanel extends InfoObjectAboutPanel
{
  static class = 'InfoLocalityAboutPanel'

  render() {
    const props = this.props
    if(props.hidden) {
      return
    }
    const locality = props.locality
    return [
      new LocalityCard({ locality }),
      locality.info ?
        new Article({
          innerHTML : locality.info,
        }) :
        new Status('Нет данных'),
    ]
  }
}
