import { Block } from 'xuick'
import { TripPlaceItem } from './TripPlaceItem'
import { TripPlaceButton } from './TripPlaceButton.js'
import './TripLocationItem.css'

export class TripLocationItem extends TripPlaceItem
{
  static class = 'TripLocationItem'

  render() {
    return [
      new Block(new TripPlaceButton({
        label : 'Ваше местоположение',
      })),
    ]
  }
}
