import { RoleListItem, Time, Button } from 'xuick'
import { Icon } from './adaptive/Icon'
import { TravelModeDialog } from './TravelModeDialog.js'
import TravelMode from '../common/TravelMode'
import './TripDurationItem.css'

export class TripDurationItem extends RoleListItem
{
  static class = 'TripDurationItem'

  render() {
    const props = this.props
    const travelMode = props.trip.travelMode || 'driving'
    const mode = TravelMode[travelMode]
    const duration = props.duration
    const icon = duration ?
      mode.icon :
      mode.icon + '_dim'
    if(duration === null) {
      this.classList = 'dim'
    }
    return new Button({
      disabled : duration === undefined,
      dialog : dialogProps => new TravelModeDialog({
        ...dialogProps,
        trip : props.trip,
      }),
      children : [
        new Icon(icon),
        duration === undefined || new Time({
          dateTime : duration && (duration.value + 's'),
          children : duration === null ?
            'Время в пути неизвестно' :
            duration && `~ ${ duration.text }`,
        }),
      ],
    })
  }
}
