import { Button, Icon, Heading, DialogHead } from 'xuick'
import { DateInterval } from '../common/DateInterval.js'
import { HeadCancelButton } from './HeadCancelButton.js'
import { TripSettingsDialog } from './TripSettingsDialog.js'
import api from './api.js'

export class TripDialogHead extends DialogHead
{
  static class = 'TripDialogHead'

  #settings = {
    travelMode : undefined,
    interval : undefined,
  }

  state = {
    ...this.#settings,
  }

  render() {
    const state = this.state
    const trip = this.props.trip
    const interval = state.interval ?
      DateInterval.fromISO(state.interval) :
      DateInterval.fromDateRange(trip.interval)
    return [
      new HeadCancelButton,
      new Heading('План поездки'),
      new Button({
        children : new Icon('tune_outline'),
        dialog : dialogProps => new TripSettingsDialog({
          ...dialogProps,
          interval : interval.toISODate(),
          travelMode : state.travelMode || trip.travelMode || 'driving',
          onchange : this.#onFilterChange,
          onreset : () => {
            this.setState(this.#settings)
          },
          onsubmit : this.#onFilterSubmit,
        }),
      }),
    ]
  }

  #onFilterChange = e => {
    const widget = e.target
    if(!widget.name) {
      return
    }
    this.setState({
      [widget.name] : widget.value,
    })
  }

  #onFilterSubmit = async e => {
    const state = this.state
    const trip = this.props.trip
    const interval = state.interval
    const travelMode = state.travelMode
    if(!interval || interval === trip.interval) {
      if(!travelMode || travelMode === trip.travelMode) {
        e.target.close()
        return
      }
    }
    this.setState({ busy : true })
    try {
      await api.updateTrip(trip.id, {
        interval,
        travelMode,
      })
      e.target.close()
    }
    finally {
      this.setState({ busy : false })
    }
  }
}
