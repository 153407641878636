import { HtmlDiv, Button } from 'xuick'
import { ProfileItem } from './ProfileItem'
import { Icon } from './Icon'
import { ProfileLangDialog } from './ProfileLangDialog.js'

export class ProfileLangItem extends ProfileItem
{
  static class = 'ProfileLangItem'

  render() {
    const { user, editMode } = this.props
    const codes = user.languages.map(
      item => item.code_iso3 || item.code,
    )
    let children
    if(codes.length) {
      children = codes.join(', ')
      children = children.toUpperCase()
      children = 'Говорит на ' + children
    }
    return [
      new Icon('language_outline'),
      editMode ?
        new Button(children || 'Языки, которыми вы владеете') :
        new HtmlDiv(children),
      new ProfileLangDialog({
        user,
        modal : true,
        open : this.state.expanded,
        oncancel : () => {
          this.setState({ expanded : false })
        },
      }),
    ]
  }
}
