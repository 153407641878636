import lodash from 'lodash'
import { List, ListItem } from 'xuick'
import './PlaceCategoryBlock.css'

export class PlaceCategoryBlock extends List
{
  static class = 'PlaceCategoryBlock'

  render() {
    const props = this.props
    const categories = props.place.categories
    const length = Math.ceil(categories.length / 2)
    const groups = lodash.chunk(categories, length)
    let names
    return groups.map(group => {
      names = lodash.map(group, 'name')
      if(props.maxNames) {
        names = names.slice(0, props.maxNames)
      }
      return new ListItem(names.join(', '))
    })
  }
}
