import { DateTime } from 'luxon'
import { Table, Row, Cell } from 'xuick'
import { InfoObjectStatsPanel } from './InfoObjectStatsPanel.js'
import { Icon } from './Icon.js'

const formatter = Intl.NumberFormat('ru')

export class InfoLocalityStatsPanel extends InfoObjectStatsPanel
{
  static class = 'InfoLocalityStatsPanel'

  render() {
    if(this.props.hidden) {
      return
    }
    const locality = this.props.locality
    const languages = locality.country.languages.map(item => {
      const name = item.name_ru || item.name_en || item.code
      return item.CountryLanguage.official ?
        `${ name } (оф.)` :
        name
    })
    const currency = locality.country.currency
    const now = DateTime.now().setZone(locality.timezone)
    return new Table([
      new Row([
        new Cell(new Icon('language_outline')),
        new Cell('Языки общения'),
        new Cell(
          languages.length ? languages.join(', ') : '—',
        ),
      ]),
      new Row([
        new Cell(new Icon('community_outline')),
        new Cell('Население'),
        new Cell(
          `${ formatter.format(locality.population) } чел.`,
        ),
      ]),
      new Row([
        new Cell(new Icon('cash_outline')),
        new Cell('Валюта'),
        new Cell(
          currency ?
            `${ currency.name_ru || currency.name_en }, ${ currency.code }` :
            '—',
        ),
      ]),
      new Row([
        new Cell(new Icon('www_outline')),
        new Cell('Доменное имя'),
        new Cell(locality.country.tld || '—'),
      ]),
      new Row([
        new Cell(new Icon('timezone_outline')),
        new Cell('Часовой пояс'),
        new Cell(now.toFormat(`'UTC' ZZ (H:mm)`)),
      ]),
    ])
  }
}
