import { MobileHeader } from './MobileHeader'
import { Button, Heading, Div } from 'xuick'
import { Icon } from './Icon'
import { HeaderLogoLink } from './HeaderLogoLink.js'
import { SettingsDialog } from './SettingsDialog'
import api from './api.js'
import './ProfileHeader.css'

export class ProfileHeader extends MobileHeader
{
  static class = 'ProfileHeader'

  render() {
    const props = this.props
    if(!props.user) {
      return new HeaderLogoLink
    }
    if(props.user.id !== api.user.id) {
      return [
        new HeaderLogoLink,
        new Button({
          type : 'search',
          children : new Icon('search_outline'),
          to : '/search',
        }),
      ]
    }
    if(props.editMode) {
      return [
        new Button({
          type : 'back',
          children : new Icon('left_outline'),
          onclick : () => {
            this.emit('edit-mode', { bubbles : true })
          },
        }),
        new Heading('Редактировать профиль'),
      ]
    }
    return [
      new HeaderLogoLink,
      new Div([
        new Heading('Профиль'),
        new Button({
          type : 'search',
          children : new Icon('search_outline'),
          to : '/search',
        }),
      ]),
      new Button({
        type : 'settings',
        children : new Icon('settings_outline'),
        dialog : SettingsDialog,
      }),
    ]
  }
}
