import { HtmlDiv, Rating } from 'xuick'
import './PlaceRating.css'

export class PlaceRating extends Rating
{
  static class = 'PlaceRating'

  constructor({ place, ...props }) {
    if(!place.rating) {
      return []
    }
    super({
      value : place.rating,
      total : place.totalRatings,
      ...props,
    })
  }

  render() {
    const total = this.props.total
    return [
      super.render(),
      !!total && new RatingTotalCount(total),
    ]
  }
}

class RatingTotalCount extends HtmlDiv
{
  static class = 'RatingTotalCount'
}
