import { Class, Option, Icon, Control, ToolTip } from 'xuick'
import TravelMode from '../common/TravelMode'
import './TravelModeOption.css'

const TOOLTIP_TOUCH_DELAY = 500

export class TravelModeOption extends Option
{
  static class = ['TravelModeOption', Class.generate(Control)].join(' ')

  state = {
    ...this.state,
    expanded : false,
  }

  #timerId = null

  init() {
    super.init()
    this.on('mouseenter', this.#onMouseEnter)
    this.on('touchstart', this.#onTouchStart)
    this.on('contextmenu', e => e.preventDefault())
  }

  destroy() {
    super.destroy()
    document.addEventListener('touchstart', this.#onDocTouchStart)
  }

  render() {
    const mode = TravelMode[this.value]
    this.title = mode.name
    return [
      new Icon(mode.icon),
      new ToolTip({
        anchor : this,
        open : this.state.expanded,
        content : mode.name,
        oncancel : this.#onToolTipCancel,
      }),
    ]
  }

  #onToolTipCancel = () => {
    if(this.state.expanded) {
      this.setState({ expanded : false })
    }
  }

  #onMouseEnter(e) {
    if(e.sourceCapabilities.firesTouchEvents) {
      return
    }
    this.on('mouseleave', this.#onMouseLeave, { once : true })
    this.setState({ expanded : true })
  }

  #onMouseLeave() {
    this.setState({ expanded : false })
  }

  #onTouchStart() {
    this.#timerId = setTimeout(this.#onTimeout, TOOLTIP_TOUCH_DELAY)
    this.on('touchmove', this.#clearTimeout, { once : true })
    this.on('touchend', this.#clearTimeout, { once : true })
    document.addEventListener('touchstart', this.#onDocTouchStart)
  }

  #onDocTouchStart = () => {
    if(this.state.expanded) {
      this.setState({ expanded : false })
    }
  }

  #onTimeout = () => {
    this.setState({ expanded : true })
    this.#timerId = null
    document.addEventListener('touchstart', this.#onDocTouchStart)
  }

  #clearTimeout() {
    if(!this.#timerId) {
      return
    }
    clearTimeout(this.#timerId)
    this.#timerId = null
    document.addEventListener('touchstart', this.#onDocTouchStart)
  }
}
