import { DateTime } from 'luxon'
import { HtmlDiv, Heading, Time, Button, Icon } from 'xuick'
import { UserPhoto } from './UserPhoto.js'
import { MapObjectCard } from './MapObjectCard.js'
import { TripRouteActionsDialog } from './TripRouteActionsDialog.js'

export class MapRouteCard extends MapObjectCard
{
  static class = 'MapRouteCard'

  render() {
    const { trip, route } = this.props
    const name = trip.destination.name_ru || trip.destination.name_en
    const dt = DateTime.fromISO(route.date)
    return [
      new HtmlDiv([
        new Heading(name),
        new Time(dt.toLocaleString(DateTime.DATE_SHORT)),
      ]),
      new HtmlDiv([
        new Button({
          key : route.id,
          children : new Icon('menu-vertical_outline_black'),
          dialog : dialogProps => new TripRouteActionsDialog({
            ...dialogProps,
            route,
            trip,
            onload : e => e.target.close(),
          }),
        }),
      ]),
      new HtmlDiv([
        new Button({
          classList : 'accent round',
          icon : 'plus_outline_white',
        }),
        new Button({
          classList : 'accent round',
          icon : 'notepad_outline_white',
          to : `/trips/${ trip.id }#${ route.date }`,
        }),
      ]),
      new HtmlDiv([
        new UserPhoto({
          user : trip.creator,
        }),
      ]),
    ]
  }
}
