import wait from 'wait'
import { Class, DialogContent, DialogHead, DialogBody, Button, Form } from 'xuick'
import { Icon } from './Icon.js'
import { LoadingIndicator } from './LoadingIndicator.js'
import { ModalDialog } from './ModalDialog.js'
import { HeadBackButton } from './HeadBackButton.js'
import { PlaceCategoryGroup } from './PlaceCategoryGroup.js'
import api from './api.js'
import './PlaceCategoryDialog.css'

export class PlaceCategoryDialog extends ModalDialog
{
  static class = 'PlaceCategoryDialog'

  static keywords = []

  state = {
    ...this.state,
    keywords : PlaceCategoryDialog.keywords,
    busy : false,
  }

  trip

  init() {
    super.init()
    this.on('submit', this.#onSubmit)
  }

  render() {
    const state = this.state
    this.busy = state.busy
    if(state.busy) {
      return new DialogContent(new LoadingIndicator)
    }
    return new Form({
      classList : Class.generate(DialogContent),
      children : [
        new DialogHead([
          new HeadBackButton({
            color : 'white',
            disabled : state.busy,
          }),
          new Button({
            children : new Icon('tune_outline_white'),
            type : 'settings',
          }),
        ]),
        new DialogBody([
          new PlaceCategoryGroup({
            label : 'Какие места в приоритете?',
            disabled : state.busy,
            value : this.#getKeywords(),
            stats : this.props.stats,
            onchange : e => {
              this.setState({ keywords : e.target.value })
            },
          }),
          new Button({
            type : 'submit',
            classList : 'accent',
            label : 'Поехали',
            disabled : state.busy,
          }),
        ]),
      ],
    })
  }

  #getKeywords() {
    const stats = this.props.stats
    return this.state.keywords.filter(
      keyword => stats[keyword] ?? true,
    )
  }

  async #onSubmit() {
    const props = this.props
    this.setState({ busy : true })
    try {
      this.trip = await api.createTrip({
        destinationId : props.destinationId,
        interval : props.interval,
        keywords : this.#getKeywords(),
        maxPlaces : props.maxPlaces,
        maxDistance : props.maxDistance,
        travelMode : props.travelMode,
      })
      this.emit('load')
      await wait(500)
      this.setState({
        busy : false,
        keywords : PlaceCategoryDialog.keywords,
      })
    }
    catch(err) {
      this.state.busy = false
      this.emit('error')
      throw err
    }
  }
}
