import { Dialog } from 'xuick'
import { SlidePopup } from './SlidePopup.js'
import './CommentDialog.css'

export class CommentDialog extends Dialog
{
  static class = ['CommentDialog', SlidePopup.class].join(' ')

  static defaultProps = {
    modal : true,
    direction : 'bottom',
  }
}
