import lodash from 'lodash'
import { PopupContent, MainBlock, Article, FootBlock, Button, Span } from 'xuick'
import { ModalDialog } from './ModalDialog.js'
import { TripPlaceInfoCard } from './TripPlaceInfoCard.js'
import { HeadBackButton } from './HeadBackButton.js'
import './TripPlaceInfoDialog.css'

export class TripPlaceInfoDialog extends ModalDialog
{
  static class = 'TripPlaceInfoDialog'

  static defaultProps = {
    modal : true,
    direction : 'right',
  }

  render() {
    const { trip, routeId, placeId } = this.props
    const route = lodash.find(trip.routes, ['id', routeId])
    const place = lodash.find(route.places, ['id', placeId])
    return new PopupContent([
      new HeadBackButton({
        color : 'white',
        tabIndex : 0,
      }),
      new TripPlaceInfoCard({ trip, routeId, placeId }),
      new Article([
        new MainBlock({
          innerHTML : place.info || 'Нет данных',
        }),
        new FootBlock([
          new Button({
            label : 'Читать далее',
            to : '/info/places/' + place.id,
            classList : 'accent',
          }),
          new Span('в разделе «База знаний»'),
        ]),
      ]),
    ])
  }
}
