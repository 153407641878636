import { Link } from 'xuick'
import { Icon } from './Icon'
import './HeaderLogoLink.css'

export class HeaderLogoLink extends Link
{
  static class = 'HeaderLogoLink'

  static defaultProps = {
    to : '/',
  }

  render() {
    return new Icon('planirus_custom')
  }
}
