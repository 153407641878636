import { Content, Button, Block } from 'xuick'
import { Icon } from './Icon.js'
import { NomadLogo } from './NomadLogo.js'
import { LoadingIndicator } from './LoadingIndicator.js'
import { Screen } from './Screen.js'
import { LegalConfirmationBlock } from './LegalConfirmationBlock.js'
import { EmailAuthForm } from './EmailAuthForm.js'
import { AuthSocialBlock } from './AuthSocialBlock.js'
import params from './params.js'
import './AuthScreen.css'

export class AuthScreen extends Screen
{
  static class = 'AuthScreen'

  state = {
    ...this.state,
    form : false,
    busy : false,
    defferedPrompt : undefined,
  }

  init() {
    super.init()
    window.addEventListener('beforeinstallprompt', this.#onBeforeInstallPrompt)
    window.addEventListener('appinstalled', this.#onAppInstalled)
  }

  destroy() {
    super.destroy()
    window.removeEventListener('beforeinstallprompt', this.#onBeforeInstallPrompt)
    window.removeEventListener('appinstalled', this.#onAppInstalled)
  }

  #onBeforeInstallPrompt = e => {
    e.preventDefault()
    this.setState({
      defferedPrompt : e,
    })
  }

  #onAppInstalled = () => {
    this.setState({
      defferedPrompt : null,
    })
  }

  render() {
    const { busy, form } = this.state
    if(busy) {
      return new LoadingIndicator
    }
    return [
      new Content([
        new Button({
          key : 'back',
          type : 'back',
          hidden : !form,
          children : new Icon('left_outline'),
          onclick : () => {
            this.setState({ form : false })
          },
        }),
        new NomadLogo({
          key : 'logo',
          to : params.env.LANDING_ORIGIN,
        }),
        form ?
          new EmailAuthForm({
            key : 'form',
          }) :
          new Block({
            key : 'block',
            children : [
              new Button({
                label : 'Войти через почту',
                classList : 'flying',
                onclick : () => {
                  this.setState({ form : true })
                },
              }),
              new AuthSocialBlock({
                onclick : this.#onAuthSocialBlockClick,
              }),
            ],
          }),
      ]),
      !form && new LegalConfirmationBlock({
        tagName : 'small',
        target : '_blank',
      }),
    ]
  }

  #onAuthSocialBlockClick = e => {
    if(e.target.closest(Button)) {
      this.setState({ busy : true })
    }
  }
}
