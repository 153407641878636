import { Link, Label } from 'xuick'
import { Photo } from './Photo.js'
import './InfoObjectLink.css'

export class InfoObjectLink extends Link
{
  static class = 'InfoObjectLink'

  render() {
    const props = this.props
    return [
      props.photo === undefined || new Photo({
        photo : props.photo,
      }),
      new Label(this.props.label),
    ]
  }
}
