import { HtmlDiv, HtmlAddress, Inner, Group, Button } from 'xuick'
import { PlaceInfoBlock } from './PlaceInfoBlock'
import { Icon } from './Icon'
import './PlaceLocationBlock.css'

const COPY_DISPLAY_DURATION = 2000
const SHARING_ZOOM = 16

export class PlaceLocationBlock extends PlaceInfoBlock
{
  static class = 'PlaceLocationBlock'

  constructor(props) {
    if(!props.place.address) {
      return []
    }
    super(props)
  }

  state = {
    ...this.state,
    copied : false,
    sharing : false,
  }

  render() {
    const state = this.state
    const address = this.#address
    return [
      new Icon('location_outline'),
      new Inner([
        new HtmlAddress(address || 'Неизвестно'),
        state.open && [
          new HtmlDiv(this.#coords.join(', ')),
          new Group([
            new Button({
              children : new Icon(state.copied ?
                'restore-down_custom' :
                'restore-down_outline_dim'),
              onclick : this.#onCopyClick,
            }),
            navigator.share && new Button({
              children : new Icon(state.sharing ?
                'share_outline' :
                'share_outline_dim'),
              onclick : this.#onShareClick,
            }),
          ]),
        ],
      ]),
      new Icon('expand-arrow_custom_black'),
    ]
  }

  #onCopyClick = async () => {
    const coords = this.#coords.join(', ')
    const blob = new Blob([coords], {
      type : 'text/plain',
    })
    await navigator.clipboard.write([
      new ClipboardItem({ 'text/plain' : blob }),
    ])
    this.setState({ copied : true })
    setTimeout(
      () => this.setState({ copied : false }),
      COPY_DISPLAY_DURATION,
    )
  }

  #onShareClick = async () => {
    const coords = this.#coords
    const url = new URL('/map', location.origin)
    const hash = [SHARING_ZOOM, ...coords].join('/')
    this.setState({ sharing : true })
    try {
      await navigator.share({
        title : this.#address,
        text : coords.join(', '),
        url : Object.assign(url, { hash }),
      })
    }
    finally {
      this.setState({ sharing : false })
    }
  }

  get #coords() {
    const [lng, lat] = this.props.place.geometry.coordinates
    return [
      lat.toFixed(5),
      lng.toFixed(5),
    ]
  }

  get #address() {
    const state = this.state
    const place = this.props.place
    return state.open ?
      place.address :
      place.address.split(', ')[0]
  }
}
