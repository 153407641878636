import { HtmlDiv, Time } from 'xuick'
import { PlaceInfoBlock } from './PlaceInfoBlock'
import { Icon } from './Icon'
import geolocation from './geolocation'
import api from './api'
import './PlaceDurationBlock.css'

export class PlaceDurationBlock extends PlaceInfoBlock
{
  static class = 'PlaceDurationBlock'

  state = {
    duration : undefined,
  }

  async init() {
    super.init()
    try {
      const { coords } = this.props.position || await geolocation.getCurrentPosition()
      const [lng, lat] = this.props.place.geometry.coordinates
      const request = {
        mode : 'driving',
        origin : [coords.latitude, coords.longitude].join(),
        destination : [lat, lng].join(),
      }
      const result = await api.gmsCallMethod('directions', request)
      const [route] = result.routes
      this.setState({
        duration : route?.legs[0].duration ?? null,
      })
    }
    catch(err) {
      this.setState({ duration : null })
      console.error(err)
    }
  }

  render() {
    const duration = this.state.duration
    if(duration === undefined) {
      this.busy = true
      this.classList = 'dim'
      return [
        new Icon('car_outline'),
        new HtmlDiv('Загрузка...'),
      ]
    }
    if(duration === null) {
      this.classList = 'dim'
      return [
        new Icon('car_outline'),
        new HtmlDiv('Неизвестно'),
      ]
    }
    return [
      new Icon('car_outline'),
      new Time({
        dateTime : duration.value + 's',
        children : duration.text,
      }),
    ]
  }
}
