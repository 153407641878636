import { ModalDialog } from './ModalDialog.js'
import './ProfileItemDialog.css'

export class ProfileItemDialog extends ModalDialog
{
  static class = 'ProfileItemDialog'

  static defaultProps = {
    direction : 'right',
  }

  state = {
    ...this.state,
    busy : false,
  }

  assign() {
    super.assign()
    this.busy = this.state.busy
  }
}
