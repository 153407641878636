import { CancelButton } from 'xuick'
import { Icon } from './Icon'

export class HeadBackButton extends CancelButton
{
  static class = 'HeadBackButton'

  static defaultProps = {
    tabIndex : -1,
    color : null,
  }

  render() {
    const color = this.props.color
    const icon = color ?
      'left_outline_' + color :
      'left_outline'
    return new Icon(icon)
  }
}
