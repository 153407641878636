import { HtmlDiv, Button } from 'xuick'
import './AuthSocialBlock.css'

export class AuthSocialBlock extends HtmlDiv
{
  static class = 'AuthSocialBlock'

  render() {
    return [
      new Button({
        label : 'Войти через',
        icon : 'vk-circled_color',
        classList : 'flying',
        onclick : () => location = '/auth/vk',
      }),
      new Button({
        label : 'Войти через',
        icon : 'google_color',
        classList : 'flying',
        onclick : () => location = '/auth/google',
      }),
      /*new Button({
        label : 'Войти через',
        icon : 'facebook-circled_color',
        classList : 'flying',
        onclick : () => location = '/auth/facebook',
      }),*/
    ]
  }
}
