import isEmail from 'validator/lib/isEmail'
import { RoleForm, Id, TextBox, CheckBox, Button, ValidationAlert } from 'xuick'
import { LegalConfirmationBlock } from './LegalConfirmationBlock.js'
import './EmailAuthForm.css'

const USER_NAME_LENGTH_MIN = 2

export class EmailAuthForm extends RoleForm
{
  static class = 'EmailAuthForm'

  state = {
    email : '',
    name : '',
    code : '',
    agree : false,
    busy : false,
    invalid : null,
    message : null,
    token : null,
    user : null,
  }

  #state = { ...this.state }

  #alertId = null

  init() {
    this.on('submit', this.onSubmit)
  }

  render() {
    const { props, state } = this
    this.#alertId ??= Id.generate()
    this.busy = state.busy
    return [
      this._emailBox = new TextBox({
        key : 'email',
        label : 'Электронная почта',
        inputMode : 'email',
        enterKeyHint : 'done',
        autocapitalize : 'none',
        disabled : state.busy,
        readOnly : !!state.token,
        required : true,
        invalid : state.invalid === this._emailBox,
        errorMessage : this.#alertId,
        value : state.email,
        oninput : e => this.setState({
          email : e.target.value,
          invalid : null,
        }),
      }),
      state.token && !state.user && (
        this._nameBox = new TextBox({
          key : 'name',
          label : 'Имя и фамилия',
          enterKeyHint : 'next',
          autocapitalize : 'words',
          disabled : state.busy,
          required : true,
          invalid : state.invalid === this._nameBox,
          errorMessage : this.#alertId,
          value : state.name,
          oninput : e => this.setState({
            name : e.target.value,
            invalid : null,
          }),
        })
      ),
      this._codeBox = new TextBox({
        key : 'code',
        label : 'Код подтверждения',
        inputMode : 'numeric',
        enterKeyHint : 'done',
        hidden : !state.token,
        disabled : state.busy,
        required : true,
        invalid : state.invalid === this._codeBox,
        errorMessage : this.#alertId,
        value : state.code,
        oninput : e => this.setState({
          code : e.target.value,
          invalid : null,
        }),
      }),
      state.token && !state.user && [
        this._agreeBox = new CheckBox({
          key : 'agree',
          label : new LegalConfirmationBlock({
            target : '_blank',
            termsText : 'пользовательским соглашением',
            privacyText : 'политикой конфиденциальности',
            text : ({ terms, privacy }) => {
              return `Я согласен(а) с ${ terms } и ${ privacy }`
            },
            onclick : e => e.stopPropagation(),
          }),
          disabled : state.busy,
          required : true,
          invalid : state.invalid === this._agreeBox,
          errorMessage : this.#alertId,
          checked : state.agree || props.agree,
          hidden : props.agree,
          onchange : e => this.setState({
            agree : e.target.value,
            invalid : null,
          }),
        }),
      ],
      new Button({
        key : 'submit',
        type : 'submit',
        label : state.user ?
          'Войти' :
          state.token ? 'Зарегистрироваться' : 'Отправить код',
        classList : 'accent',
        disabled : state.busy,
      }),
      new ValidationAlert({
        key : 'alert',
        id : this.#alertId,
        open : !!state.invalid,
        anchor : state.invalid,
        text : state.message,
        oncancel : () => {
          this.setState({ invalid : false })
        },
      }),
    ]
  }

  update(prevProps, prevState) {
    const invalid = this.state.invalid
    if(!invalid || invalid === prevState.invalid) {
      return
    }
    invalid.focus()
    getSelection().selectAllChildren(document.activeElement)
  }

  focus() {
    this._emailBox.focus()
  }

  reset() {
    this.setState({
      ...this.#state,
      email : this.state.email,
    })
    this.focus()
    getSelection().selectAllChildren(document.activeElement)
  }

  async onSubmit(e) {
    const state = this.state
    if(!state.token) {
      await this.submitEmail()
      return
    }
    if(state.user) {
      await this.submitCode()
      return
    }
    const name = state.name.trim()
    if(name.length < USER_NAME_LENGTH_MIN) {
      this.setState({
        invalid : this._nameBox,
        message : 'Необходимо указать имя',
      })
      return
    }
    if(e.submitter === this._nameBox) {
      this._codeBox.focus()
      return
    }
    await this.submitCode()
  }

  async submitEmail() {
    const email = this.state.email.trim().toLowerCase()
    if(!email) {
      this.setState({
        invalid : this._emailBox,
        message : 'Необходимо указать адрес электронной почты',
      })
      return
    }
    if(!isEmail(email)) {
      this.setState({
        invalid : this._emailBox,
        message : 'Адрес электронной почты указан некорректно',
      })
      return
    }
    this.setState({
      invalid : null,
      busy : true,
    })
    try {
      const res = await fetch('/auth/email', {
        method : 'POST',
        headers : {
          'Accept' : 'application/json',
          'Content-Type' : 'application/json',
        },
        body : JSON.stringify({ email }),
      })
      if(!res.ok) {
        throw Error(decodeURIComponent(res.statusText))
      }
      const user = await res.json()
      this.setState({
        token : res.headers.get('X-CSRF-Token'),
        busy : false,
        user,
      })
      await new Promise(setTimeout)
      if(user) {
        this._codeBox.focus()
      }
      else this._nameBox.focus()
    }
    catch(err) {
      this.setState({
        busy : false,
        invalid : this._emailBox,
        message : err.message,
      })
    }
  }

  async submitCode() {
    const state = this.state
    const email = state.email.trim().toLowerCase()
    const code = state.code.trim()
    if(!code) {
      this.setState({
        invalid : this._codeBox,
        message : 'Необходимо указать проверочный код',
      })
      return
    }
    if(!state.user && !state.agree && !this.props.agree) {
      this.setState({
        invalid : this._agreeBox,
        message : 'Необходимо принять пользовательское соглашение и политику конфиденциальности',
      })
      return
    }
    this.setState({
      invalid : null,
      busy : true,
    })
    try {
      const res = await fetch('/auth/email/callback', {
        method : 'POST',
        headers : {
          'Accept' : 'application/json',
          'Content-Type' : 'application/json',
          'X-CSRF-Token' : state.token,
        },
        body : JSON.stringify({
          email,
          code,
          name : state.name.trim() || undefined,
        }),
      })
      if(!res.ok) {
        throw Error(decodeURIComponent(res.statusText))
      }
      location = '/'
    }
    catch(err) {
      this.setState({
        busy : false,
        invalid : this._codeBox,
        message : err.message,
      })
    }
  }
}
