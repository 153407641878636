import wait from 'wait'
import lodash from 'lodash'
import { HtmlDiv } from 'xuick'
import { TripDotList } from './TripDotList.js'
import { TripRouteHead } from './TripRouteHead.js'
import { TripDateList } from './TripDateList'
import { TripRoutesGroup } from './TripRoutesGroup.js'
import './TripRoutesBlock.css'

export class TripRoutesBlock extends HtmlDiv
{
  static class = 'TripRoutesBlock'

  state = {
    classList : null,
  }

  routes

  date

  #elem
  #x
  #pointer
  #offset
  #clientX
  #scale
  #move

  init() {
    super.init()
    this.on('change', this.#onChange)
    this.on('touchstart', this.#onTouchStart, { passive : true })
    // this.on('mousedown', this.#onTouchStart, { passive : true })
    this.on('drag-start', this.#onDragStart)
    window.addEventListener('resize', this.#onResize)
  }

  destroy() {
    super.destroy()
    window.removeEventListener('resize', this.#onResize)
  }

  assign() {
    super.assign()
    this.classList = this.state.classList
  }

  render() {
    const props = this.props
    const trip = props.trip
    const date = props.route.date
    return [
      new TripDotList({ trip, date }),
      new TripRouteHead({ trip, date }),
      this._list = new TripDateList({ trip, date }),
      this._group = new TripRoutesGroup({ trip, date }),
    ]
  }

  #onResize = lodash.debounce(() => {
    this._list.updatePosition()
    this._group.updatePosition()
  }, 500)

  async #onChange(e) {
    const target = e.target
    if(target === this || !target.date) {
      return
    }
    this.classList.add('move')
    this.classList.remove('transition')
    this.state.classList = 'move'
    this.date = target.date
    this.emit('change')
    await wait(this.#transitionDuration)
    this.classList.add('transition')
    this.classList.remove('move')
    this.state.classList = 'transition'
  }

  #onTouchStart(e) {
    if(e.type === 'touchstart' && e.touches > 1) {
      return
    }
    if(e.type === 'mousedown' && e.buttons > 1) {
      return
    }
    const list = e.target.closest(TripDateList)
    const group = e.target.closest(TripRoutesGroup)
    const elem = list || group
    if(!elem) {
      return
    }
    this.#elem = elem
    this.#x = elem.x
    this.#offset = 0
    this.#pointer = e.touches?.[0] || e
    this.#clientX = e.touches?.[0].clientX ?? e.clientX
    this.#scale = this._list.step / this._group.step
    this.#move = false
    this._group.style.transition = 'none'
    this._list.style.transition = 'none'
    this.on('touchmove', this.#onTouchMove, { passive : true })
    this.on('touchend', this.#onTouchEnd, { once : true })
    // this.on('mousemove', this.#onTouchMove, { passive : true })
    // this.on('mouseup', this.#onTouchEnd, { once : true })
  }

  async #onTouchMove(e) {
    const clientX = e.touches?.[0].clientX ?? e.clientX
    const clientY = e.touches?.[0].clientY ?? e.clientY
    const dX = clientX - this.#pointer.clientX
    if(!this.#move) {
      const dY = clientY - this.#pointer.clientY
      if(Math.abs(dY) > Math.abs(dX)) {
        this.off('touchmove', this.#onTouchMove)
        this.off('touchend', this.#onTouchEnd)
        // this.off('mousemove', this.#onTouchMove)
        // this.off('mouseup', this.#onTouchEnd)
        return
      }
    }
    const routes = this.props.trip.routes
    const min = this.#elem.step * (1 - routes.length)
    let x = this.#x + dX
    this.#offset = clientX - this.#clientX
    this.#clientX = clientX
    if(!this.#move && x >= min && x <= 0) {
      this.classList.add('move')
      this.classList.remove('transition')
      this.state.classList = 'move'
      this.#move = true
    }
    x = lodash.clamp(x, min, 0)
    await new Promise(requestAnimationFrame)
    if(this.#elem === this._list) {
      this._list.x = x
      this._group.x = x / this.#scale
      return
    }
    this._list.x = x * this.#scale
    this._group.x = x
  }

  async #onTouchEnd() {
    await new Promise(requestAnimationFrame)
    const routes = this.props.trip.routes
    let index = this.#offset < 0 ?
      Math.floor(this.#elem.x / this.#elem.step) :
      Math.ceil(this.#elem.x / this.#elem.step)
    index = lodash.clamp(-index, 0, routes.length - 1)
    const route = routes[index]
    this._group.style.transition = null
    this._list.style.transition = null
    this.off('touchmove', this.#onTouchMove)
    // this.off('mousemove', this.#onTouchMove)
    if(route.date === this.date) {
      this._list.updatePosition()
      this._group.updatePosition()
    }
    else {
      this.date = route.date
      this.emit('change')
    }
    await wait(this.#transitionDuration)
    this.classList.add('transition')
    this.classList.remove('move')
    this.state.classList = 'transition'
  }

  #onDragStart() {
    this.off('touchmove', this.#onTouchMove)
    this.off('touchend', this.#onTouchEnd)
    // this.off('mousemove', this.#onTouchMove)
    // this.off('mouseup', this.#onTouchEnd)
  }

  get #transitionDuration() {
    const style = getComputedStyle(this.node)
    const value = style.getPropertyValue('--transition-duration')
    return parseInt(value)
  }
}
