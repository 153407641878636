import { TabPanel, Heading, App } from 'xuick'
import { TripCard } from './TripCard'
import { LoadingIndicator } from './LoadingIndicator'
import { TripFormDialogButton } from './TripFormDialogButton'
import { TripFormDialog } from './TripFormDialog'
import api from './api'
import './InfoLocalityNomadPanel.css'

export class InfoLocalityNomadPanel extends TabPanel
{
  static class = 'InfoLocalityNomadPanel'

  state = {
    trips : undefined,
  }

  init() {
    super.init()
    if(!this.props.hidden) {
      void this.#load()
    }
  }

  render() {
    if(this.props.hidden) {
      return
    }
    const trips = this.state.trips
    return [
      new Heading('Поездки в этот город'),
      trips ?
        trips.length ?
          trips.map(trip => new TripCard({ trip })) :
          new TripFormDialogButton({
            dialog : props => new TripFormDialog({
              ...props,
              destinationId : this.props.locality.id,
              onload : this.#onFormDialogLoad,
            }),
          }) :
        new LoadingIndicator,
    ]
  }

  update(prevProps, prevState) {
    super.update(prevProps, prevState)
    if(this.props.hidden || !prevProps.hidden) {
      return
    }
    if(!this.state.users) {
      void this.#load()
    }
  }

  async #load() {
    const { trips } = await api.getUserTrips({
      destinationId : this.props.locality.id,
      filter : 'actual',
    })
    this.setState({ trips })
  }

  #onFormDialogLoad(e) {
    const app = this.closest(App)
    const trip = e.target.trip
    app.navigate('/trips/' + trip.id)
  }
}
