import { InfoObjectDialog } from './InfoObjectDialog.js'
import { InfoObjectAboutPanel } from './InfoObjectAboutPanel.js'
import { InfoRegionCountriesPanel } from './InfoRegionCountriesPanel.js'
import api from './api.js'

export class InfoRegionDialog extends InfoObjectDialog
{
  static class = 'InfoRegionDialog'

  static sections = [
    {
      value : 'about',
      title : 'Справка',
      panel : InfoObjectAboutPanel,
    },
    {
      value : 'countries',
      title : 'Страны',
      objectPropName : 'region',
      panel : InfoRegionCountriesPanel,
    },
  ]

  async _load() {
    this.setState({
      object : await api.getRegion(this.props.objectId),
    })
  }
}
