import { RadioGroup } from 'xuick'
import { TripsFilterRadio } from './TripsFilterRadio'
import './TripsFilterGroup.css'

export class TripsFilterGroup extends RadioGroup
{
  static class = 'TripsFilterGroup'

  renderRadios() {
    return [
      new TripsFilterRadio({
        value : 'actual',
        text : 'Актуальные',
        classList : 'flying',
      }),
      new TripsFilterRadio({
        value : 'archive',
        text : 'Архив',
        classList : 'flying',
      }),
    ]
  }
}
