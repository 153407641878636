module.exports = {
  id : {
    name : 'id',
    title : 'ID',
    abbr : 'ID',
    type : 'integer',
    unique : true,
    hidden : true,
    readOnly : true,
    include : true,
    public : true,
  },
  createdAt : {
    name : 'createdAt',
    title : 'Дата создания',
    abbr : 'Создано',
    type : 'dateTime',
    readOnly : true,
    filter : true,
    hidden : true,
  },
  updatedAt : {
    name : 'updatedAt',
    title : 'Дата обновления',
    abbr : 'Обновлено',
    type : 'dateTime',
    readOnly : true,
    filter : true,
    hidden : true,
  },
  deletedAt : {
    name : 'deletedAt',
    title : 'Дата удаления',
    abbr : 'Удалено',
    type : 'dateTime',
    readOnly : true,
    hidden : true,
  },
}
