import { Button } from 'xuick'
import './MapBoundsButton.css'

export class MapBoundsButton extends Button
{
  static class = 'MapBoundsButton'

  static defaultProps = {
    label : 'Искать в этом районе',
    classList : 'action flying',
  }
}
