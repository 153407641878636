import lodash from 'lodash'
import { HtmlA, Inner, Div } from 'xuick'
import { PlaceInfoBlock } from './PlaceInfoBlock'
import { Icon } from './Icon'
import './PlaceLinksBlock.css'

export class PlaceLinksBlock extends PlaceInfoBlock
{
  static class = 'PlaceLinksBlock'

  static items = [
    {
      service : 'instagram.com',
      icon : 'instagram_color',
    },
    {
      service : 'facebook.com',
      icon : 'facebook-circled_color',
    },
    {
      service : 'twitter.com',
      icon : 'twitter-circled_color',
    },
    {
      service : 'vk.com',
      icon : 'vk-circled_color',
    },
    {
      service : 'ok.ru',
      icon : 'odnoklassniki_color',
    },
  ]

  static normalizeLinkItem(item) {
    return typeof item === 'string' ? { url : item } : item
  }

  constructor(props) {
    if(props.place.website) {
      super(props)
      return
    }
    const links = lodash.map(props.place.links, PlaceLinksBlock.normalizeLinkItem)
    const filter = item => {
      return links.some(link => link.url?.includes(item.service))
    }
    if(!links.length || !PlaceLinksBlock.items.some(filter)) {
      return []
    }
    super(props)
  }

  render() {
    const state = this.state
    const place = this.props.place
    const links = lodash.map(place.links, PlaceLinksBlock.normalizeLinkItem)
    const children = []
    let item, link
    for(item of PlaceLinksBlock.items) {
      link = links.find(link => link.url?.includes(item.service))
      link && children.push(new HtmlA({
        href : link.url,
        target : '_blank',
        children : new Icon(item.icon),
      }))
    }
    const href = place.website || place.links[0].url
    return [
      new Icon('internet_outline'),
      new Inner([
        new HtmlA({
          href,
          target : '_blank',
          children : href.replace(/^https?:\/\//, ''),
        }),
        !!children.length && new Div({
          children,
          hidden : !state.open,
        }),
      ]),
      !!children.length && new Icon('expand-arrow_custom_black'),
    ]
  }
}
