import { Feed, Status } from 'xuick'
import { LoadingIndicator } from './LoadingIndicator.js'
import { PlaceCard } from './PlaceCard.js'
import api from './api.js'
import geolocation from './geolocation.js'
import './InfoPlacesFeed.css'

export class InfoPlacesFeed extends Feed
{
  static class = 'InfoPlacesFeed'

  static defaultProps = {
    limit : 10,
  }

  render() {
    const items = this.state.items
    if(!items) {
      return new LoadingIndicator
    }
    if(!items.length) {
      return new Status('Не найдено')
    }
    return items.map(place => new PlaceCard({
      key : place.id,
      place,
    }))
  }

  async loadItems(offset) {
    return api.findPlaces({
      query : this.props.query,
      keywords : this.props.keywords,
      localityId : this.props.localityId || undefined,
      location : await geolocation.getLocation(),
      limit : this.props.limit,
      offset,
    })
  }
}
