import { SuggestBox } from 'xuick'
import { CountryOption } from './CountryOption'
import api from './api'

export class CountryBox extends SuggestBox
{
  static class = 'CountryBox'

  static defaultProps = {
    Option : CountryOption,
  }

  async loadItems(query, limit, offset) {
    return api.findCountries({
      query,
      scope : ['name_en', 'name_ru'],
      order : [['name_ru', 'ASC']],
      limit,
      offset,
    })
  }

  async loadItem(id) {
    const [result] = await api.findCountries({
      filters : { id },
      limit : 1,
    })
    return result
  }
}
