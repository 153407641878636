import { HtmlDiv } from 'xuick'
import './ProfileItem.css'

export class ProfileItem extends HtmlDiv
{
  static class = 'ProfileItem'

  constructor(props) {
    if(!props.editMode) {
      const value = props.user[props.key]
      if(Array.isArray(value) ? !value.length : !value) {
        return []
      }
    }
    super(props)
  }

  state = {
    expanded : false,
  }

  init() {
    this.on('click', this.onClick)
  }

  assign() {
    const props = this.props
    const value = props.user[props.key]
    if(Array.isArray(value) ? !value.length : !value) {
      this.classList = 'blank'
    }
  }

  onClick() {
    if(this.props.editMode) {
      this.setState({ expanded : true })
    }
  }
}
