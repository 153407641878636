import lodash from 'lodash'
import { HtmlSpan, Heading, Group, Link, Status, Span } from 'xuick'
import { Trip } from '../common/Trip.js'
import { InfoBlock } from './InfoBlock.js'
import { PlaceCard } from './PlaceCard.js'
import api from './api.js'
import geolocation from './geolocation.js'
import './InfoPlacesBlock.css'

// const DEFAULT_RADIUS = 15_000

export class InfoPlacesBlock extends InfoBlock
{
  static class = 'InfoPlacesBlock'

  static defaultProps = {
    keywords : lodash.map(Trip.keywords, 'keyword'),
    titleText : 'Места',
  }

  state = {
    places : undefined,
  }

  render() {
    const { props, state } = this
    const places = state.places
    return [
      new Heading(new Link({
        to : props.href,
        children : [
          new HtmlSpan(props.titleText),
          props.href && new Span('Все'),
        ],
      })),
      places ?
        places.length ?
          new Group(places.map(place => {
            return new PlaceCard({
              key : place.id,
              to : props.placeId === undefined ?
                null :
                '/info/places/' + place.id,
              place,
            })
          })) :
          new Status('Не найдено') :
        new Status('Загрузка...'),
    ]
  }

  mount() {
    super.mount()
    void this.#load()
  }

  async #load() {
    const props = this.props
    this.setState({
      places : await api.findPlaces({
        location : props.localityId ?
          undefined :
          props.location || await geolocation.getLocation(),
        localityId : props.localityId,
        keywords : props.keywords,
        // radius : DEFAULT_RADIUS,
        limit : 10,
      }),
    })
  }
}
