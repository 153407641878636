import { Img, Icon } from 'xuick'
import params from './params.js'
import './Photo.css'

const IMAGE_EXT_RE = /\.(?:webp|png|jpg|jpeg)$/i

export class Photo extends Img
{
  static class = 'Photo'

  static defaultProps = {
    icon : 'unsplash_metro_dim',
  }

  static getUrl(image) {
    if(!image) {
      return null
    }
    if(image.startsWith('https://')) {
      return image
    }
    const url = `${ params.env.S3_BUCKET_URL }/${ image }`
    return IMAGE_EXT_RE.test(url) ?
      url :
      url + '.webp'
  }

  constructor(props) {
    const photo = props.photo
    if(!photo) {
      super(props)
      return
    }
    const image = typeof photo === 'string' ?
      photo :
      photo.image
    super({
      src : Photo.getUrl(image),
      ...props,
    })
  }

  render() {
    return !this.props.src && new Icon(this.props.icon)
  }
}
