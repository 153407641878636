import { InfoObjectDialog } from './InfoObjectDialog.js'
import { InfoLocalityAboutPanel } from './InfoLocalityAboutPanel.js'
import { InfoLocalityStatsPanel } from './InfoLocalityStatsPanel.js'
import { InfoLocalityPlacesPanel } from './InfoLocalityPlacesPanel.js'
import { InfoLocalityNomadPanel } from './InfoLocalityNomadPanel.js'
import { InfoLocalityPeoplePanel } from './InfoLocalityPeoplePanel.js'
import wpc from '../common/wpc.js'
import api from './api.js'
import './InfoLocalityDialog.css'

export class InfoLocalityDialog extends InfoObjectDialog
{
  static class = 'InfoLocalityDialog'

  static defaultProps = {
    objectPropName : 'locality',
  }

  static sections = [
    {
      value : 'about',
      title : 'Справка',
      panel : InfoLocalityAboutPanel,
    },
    {
      value : 'stats',
      title : 'Статистика',
      panel : InfoLocalityStatsPanel,
    },
    {
      value : 'places',
      title : 'Места',
      panel : InfoLocalityPlacesPanel,
    },
    {
      value : 'nomad',
      title : 'Стань номадом',
      panel : InfoLocalityNomadPanel,
    },
    {
      value : 'people',
      title : 'Люди',
      panel : InfoLocalityPeoplePanel,
    },
  ]

  async _load() {
    const localityId = this.props.objectId
    if(!localityId) {
      return
    }
    if(this.state.locality) {
      const url = new URL(location)
      const section = this.constructor.sections[0].value
      url.hash = section
      history.replaceState(null, '', url)
      this.setState({
        locality : undefined,
        section,
      })
    }
    const locality = await api.getLocality(localityId)
    const summary = await wpc.getLocalitySummary(locality, 'ru')
    if(!summary) {
      this.setState({ locality })
      return
    }
    if(summary.originalimage) {
      locality.photos ??= [
        { image : summary.originalimage.source },
      ]
    }
    locality.info ??= summary.extract_html
    this.setState({ locality })
  }
}
