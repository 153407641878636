import { DialogContent, DialogHead, DialogBody, Button } from 'xuick'
import { Icon } from './Icon.js'
import { ModalDialog } from './ModalDialog.js'
import { HeadBackButton } from './HeadBackButton.js'
import { TripSettingsDialog } from './TripSettingsDialog.js'
import { TripForm } from './TripForm.js'
import './TripFormDialog.css'

export class TripFormDialog extends ModalDialog
{
  static class = 'TripFormDialog'

  static defaultProps = {
    direction : 'right',
  }

  #settings = {
    maxPlaces : 3,
    maxDistance : 5,
    travelMode : 'driving',
  }

  state = {
    ...this.state,
    ...this.#settings,
  }

  trip

  init() {
    super.init()
    this.on('settings-click', this.#onSettingsClick)
  }

  render() {
    const state = this.state
    return new DialogContent([
      new DialogHead([
        new HeadBackButton({
          color : 'white',
        }),
        this._button = new Button({
          children : new Icon('tune_outline_white'),
          dialog : dialogProps => new TripSettingsDialog({
            ...dialogProps,
            maxPlaces : state.maxPlaces,
            maxDistance : state.maxDistance,
            travelMode : state.travelMode,
            onchange : this.#onFilterChange,
            onreset : () => {
              this.setState(this.#settings)
            },
            onsubmit : e => {
              e.target.close()
            },
          }),
        }),
      ]),
      new DialogBody(
        new TripForm({
          onload : this.#onFormLoad,
          destinationId : this.props.destinationId,
          maxPlaces : state.maxPlaces,
          maxDistance : state.maxDistance,
          travelMode : state.travelMode,
        }),
      ),
    ])
  }

  #onSettingsClick() {
    this._button.click()
  }

  #onFilterChange = e => {
    const widget = e.target
    if(!widget.name) {
      return
    }
    this.setState({
      [widget.name] : widget.value,
    })
  }

  #onFormLoad = e => {
    this.trip = e.target.trip
    this.emit('load')
  }
}
