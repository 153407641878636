import { DialogContent, DialogBody, Button } from 'xuick'
import { Icon } from './Icon.js'
import { ModalDialog } from './ModalDialog.js'
import { LoadingIndicator } from './LoadingIndicator.js'
import { TripRouteCard } from './TripRouteCard.js'
import { TripRoutesBlock } from './TripRoutesBlock.js'
import './TripRoutesDialog.css'

export class TripRoutesDialog extends ModalDialog
{
  static class = 'TripRoutesDialog'

  static defaultProps = {
    direction : 'right',
  }

  init() {
    super.init()
    this.on('change', this.#onChange)
    this.on('drag-start', this.#onDragStart)
    this.on('drag-end', this.#onDragEnd)
  }

  render() {
    const props = this.props
    const trip = props.trip
    if(!trip) {
      return new LoadingIndicator
    }
    const route = props.route
    if(!route) {
      return
    }
    return new DialogContent({
      key : trip.id,
      children : [
        this._body = new DialogBody([
          new Button({
            type : 'cancel',
            children : new Icon('left_outline_white'),
          }),
          new TripRouteCard({ trip, route }),
          new TripRoutesBlock({ trip, route }),
        ]),
      ],
    })
  }

  destroy() {
    super.destroy()
    document.documentElement.style.overflow = null
  }

  #onChange(e) {
    const target = e.target
    if(target.date) {
      location.hash = target.date
    }
  }

  #onDragStart() {
    document.documentElement.style.overflow = 'hidden'
    this._body.node.style.overflow = 'hidden'
  }

  #onDragEnd() {
    document.documentElement.style.overflow = null
    this._body.node.style.overflow = null
  }
}
