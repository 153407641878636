import { Button, ButtonControl, Icon } from 'xuick'
import { ProfilePhoto } from './ProfilePhoto'
import './ProfilePhotoUploadButton.css'

export class ProfilePhotoUploadButton extends Button
{
  static class = 'ProfilePhotoUploadButton'

  render() {
    const props = this.props
    return new ButtonControl(
      props.user.photos.length ?
        new ProfilePhoto({ user : props.user }) :
        new Icon('unsplash_metro_dim'),
    )
  }
}
