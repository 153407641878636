import lodash from 'lodash'
import { List, ListItem, HtmlDiv } from 'xuick'
import { Icon } from './Icon.js'
import './PlaceFeaturesBlock.css'

export class PlaceFeaturesBlock extends HtmlDiv
{
  static class = 'PlaceFeaturesBlock'

  static items = [
    {
      title : 'Доступная среда',
      path : 'wheelchair_accessible_entrance',
      icon : 'wheelchair_outline',
    },
    {
      title : 'Завтрак',
      path : 'serves_breakfast',
      icon : 'breakfast_outline',
    },
    {
      title : 'Поздний завтрак',
      path : 'serves_brunch',
      icon : 'croissant_outline',
    },
    {
      title : 'Обед',
      path : 'serves_lunch',
      icon : 'lunch_outline',
    },
    {
      title : 'Ужин',
      path : 'serves_dinner',
      icon : 'dinner_outline',
    },
    {
      title : 'Вегатарианская еда',
      path : 'serves_vegetarian_food',
      icon : 'natural-food_regular',
    },
    {
      title : 'Пиво',
      path : 'serves_beer',
      icon : 'beer_outline',
    },
    {
      title : 'Вино',
      path : 'serves_wine',
      icon : 'wine-glass_outline',
    },
    {
      title : 'Еда в заведении',
      path : 'dine_in',
      icon : 'restaurant-table_outline',
    },
    {
      title : 'Доступно для бронирования',
      path : 'reservable',
      icon : 'reserve_outline',
    },
    {
      title : 'На вынос',
      path : 'takeout',
      icon : 'take-away-food_outline',
    },
    {
      title : 'Доставка',
      path : 'delivery',
      icon : 'delivery_outline',
    },
    {
      title : 'Самовывоз',
      path : 'curbside_pickup',
      icon : 'hand-box_outline',
    },
    {
      title : 'WI-FI',
      path : 'wifi',
      icon : 'wifi_outline',
    },
    {
      title : 'Парковка',
      path : 'parking',
      icon : 'parking-sign_outline',
    },
    {
      title : 'Оплата картой',
      path : 'card_payment_accepted',
      icon : 'card_outline',
    },
    {
      title : 'Можно с детьми',
      path : 'children_allowed',
      icon : 'baby-face_outline',
    },
    {
      title : 'Можно с животными',
      path : 'pets_allowed',
      icon : 'dog_outline',
    },
  ]

  constructor(props) {
    const features = props.place.features
    if(!features) {
      return []
    }
    const filter = item => {
      return lodash.get(features, item.path)
    }
    if(!PlaceFeaturesBlock.items.some(filter)) {
      return []
    }
    super(props)
  }

  render() {
    const props = this.props
    let items = PlaceFeaturesBlock.items.map(item => {
      if(!lodash.get(props.place.features, item.path)) {
        return
      }
      return new ListItem({
        title : item.title,
        children : new Icon(item.icon),
      })
    })
    if(props.maxItems) {
      items = items.filter(Boolean).slice(0, props.maxItems)
    }
    return new List(items)
  }
}
