import { TabPanel } from 'xuick'
import { InfoCountriesNav } from './InfoCountriesNav.js'
import './InfoRegionCountriesPanel.css'

export class InfoRegionCountriesPanel extends TabPanel
{
  static class = 'InfoRegionCountriesPanel'

  render() {
    return new InfoCountriesNav({
      countries : this.props.region.countries,
    })
  }
}
