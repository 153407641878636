import { HtmlSpan, Group, Heading, Link, Status, Span } from 'xuick'
import { InfoBlock } from './InfoBlock.js'
import { LocalityCard } from './LocalityCard.js'
import { InfoLocalityDialog } from './InfoLocalityDialog.js'
import geolocation from './geolocation.js'
import api from './api.js'
import './InfoLocalitiesBlock.css'

export class InfoLocalitiesBlock extends InfoBlock
{
  static class = 'InfoLocalitiesBlock'

  state = {
    localities : undefined,
    localityId : null,
  }

  init() {
    super.init()
    void this.#load()
  }

  async #load() {
    this.setState({
      localities : await api.findLocalities({
        location : await geolocation.getLocation(),
        publicOnly : true,
        limit : 5,
      }),
    })
  }

  render() {
    const { localities, localityId } = this.state
    return [
      new Heading(new Link({
        to : '/info/localities',
        children : [
          new HtmlSpan('Города'),
          new Span('Все'),
        ],
      })),
      localities ?
        localities.length ?
          new Group(localities.map(
            locality => new LocalityCard({
              locality,
              onclick : () => {
                this.setState({
                  localityId : locality.id,
                })
              },
            }),
          )) :
          new Status('Не найдено') :
        new Status('Загрузка...'),
      new InfoLocalityDialog({
        objectId : localityId,
        open : !!localityId,
        oncancel : () => {
          this.setState({ localityId : null })
        },
      }),
    ]
  }
}
