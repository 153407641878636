import { Button, Control } from 'xuick'
import { Icon } from './Icon'
import { MapLocationMarker } from './MapLocationMarker'
import geolocation from './geolocation'
import './MapLocationButton.css'

const SET_POSITION_ZOOM = 15

export class MapLocationButton extends Button
{
  static class = 'MapLocationButton'

  static defaultProps = {
    label : 'Find my location',
  }

  static icons = {
    true : 'my-location_outline',
    mixed : 'my-location_outline_dim',
    false : 'location-off_outline_dim',
  }

  state = {
    ...this.state,
    pressed : false,
    busy : false,
  }

  map

  #listeners

  #marker = null

  #watchId = null

  #moving = false

  init() {
    super.init()
    this.on('click', this.#onClick)
  }

  assign() {
    super.assign()
    this.pressed = this.state.pressed
    this.busy = this.state.busy
  }

  render() {
    return new Control(
      new Icon(
        MapLocationButton.icons[this.state.pressed],
      ),
    )
  }

  mount() {
    super.mount()
    const { ControlPosition } = google.maps
    const map = this.map
    map.controls[ControlPosition.RIGHT_BOTTOM].push(this.node)
    this.#listeners = [
      map.addListener('idle', this.#onMapIdle),
      map.addListener('dragstart', this.#onMapChanged),
      map.addListener('zoom_changed', this.#onMapChanged),
    ]
  }

  #onMapIdle = () => {
    this.#moving = false
  }

  destroy() {
    super.destroy()
    const { ControlPosition } = google.maps
    this.map.controls[ControlPosition.RIGHT_BOTTOM].clear()
    this.#listeners.forEach(listener => {
      listener.remove()
    })
    this.#clear()
  }

  #clear() {
    geolocation.clearWatch(this.#watchId)
    this.#watchId = null
    if(!this.#marker) {
      return
    }
    this.#marker.remove()
    this.#marker = null
  }

  async #onClick() {
    if(this.state.pressed === true) {
      this.#clear()
      this.setState({
        pressed : false,
        busy : false,
      })
      this.value = false
      this.emit('change')
      return
    }
    this.setState({
      pressed : true,
      busy : true,
    })
    try {
      const position = await geolocation.getCurrentPosition()
      this.#marker ??= MapLocationMarker.render({ map : this.map })
      this.#setPosition(position, true)
      this.setState({ busy : false })
      this.value = true
      this.emit('change')
    }
    catch(err) {
      this.setState({
        pressed : false,
        busy : false,
      })
      if(this.value !== false) {
        this.value = false
        this.emit('change')
      }
      this.emit('error')
      throw err
    }
  }

  #setPosition = (position, force) => {
    if(this.pressed === 'mixed' && !force) {
      return
    }
    const center = {
      lat : position.coords.latitude,
      lng : position.coords.longitude,
    }
    this.#moving = true
    this.map.setZoom(SET_POSITION_ZOOM)
    this.map.panTo(center)
    this.#marker.setPosition(center)
    this.#watchId ??= geolocation.watchPosition(this.#setPosition, console.error)
    this.emit('map-geolocate', {
      bubbles : true,
      detail : { position },
    })
  }

  #onMapChanged = () => {
    if(this.state.pressed !== true || this.#moving) {
      return
    }
    this.setState({ pressed : 'mixed' })
    this.value = 'mixed'
    this.emit('change')
  }
}
