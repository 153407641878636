import { HtmlDiv, Button, List, ListItem, Link } from 'xuick'
import { ProfileItem } from './ProfileItem.js'
import { Icon } from './Icon.js'
import { ProfileTagDialog } from './ProfileTagDialog.js'
import './ProfileTagItem.css'

export class ProfileTagItem extends ProfileItem
{
  static class = 'ProfileTagItem'

  render() {
    const { user, editMode } = this.props
    return [
      new Icon('tags_outline'),
      new HtmlDiv([
        editMode ?
          new Button('Теги') :
          new HtmlDiv('Теги'),
        !!user.tags.length && new List(user.tags.map(tag => {
          return new ListItem(new Link({
            to : editMode ?
              null :
              '/search?query=' + tag.name,
            children : tag.name,
          }))
        })),
      ]),
      new ProfileTagDialog({
        user,
        modal : true,
        open : this.state.expanded,
        oncancel : () => {
          this.setState({ expanded : false })
        },
      }),
    ]
  }
}
