import { HtmlDiv } from 'xuick'
import { PlaceDirectionsButton } from './PlaceDirectionsButton.js'
import { PlaceCallButton } from './PlaceCallButton.js'
import { PlaceRouteButton } from './PlaceRouteButton.js'
import './PlaceActionsBlock.css'

export class PlaceActionsBlock extends HtmlDiv
{
  static class = 'PlaceActionsBlock'

  render() {
    const props = this.props
    const routeButton = props.route && !props.route.places.find(
      place => place.id === props.placeId,
    )
    return [
      new PlaceDirectionsButton({
        place : props.place,
        position : props.position,
      }),
      new PlaceCallButton({
        place : props.place,
      }),
      routeButton && new PlaceRouteButton({
        route : props.route,
        places : props.places,
        placeId : props.placeId,
      }),
    ]
  }
}
