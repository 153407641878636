import { Button } from 'xuick'
import './TripRouteButton.css'

export class TripRouteButton extends Button
{
  static class = 'TripRouteButton'

  static defaultProps = {
    label : 'Добавить день',
    icon : 'plus_outline',
  }
}
