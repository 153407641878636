import lodash from 'lodash'
import { DateTime } from 'luxon'
import { HtmlDiv, HtmlTime, HtmlSpan } from 'xuick'
import { Icon } from './Icon'
import './PlaceHoursBlock.css'

export class PlaceHoursBlock extends HtmlDiv
{
  static class = 'PlaceHoursBlock'

  static defaultProps = {
    title : 'Часы работы',
  }

  constructor(props) {
    if(!props.place.hours?.periods) {
      return []
    }
    super(props)
  }

  render() {
    const hours = this.props.place.hours
    const index = lodash.keyBy(hours.periods, 'open.day')
    const now = DateTime.now()
    const { open, close } = index[now.weekday] || hours.periods[0]
    const closeTime = close && close.time !== '0000' ?
      close.time :
      '2400'
    const dtA = DateTime.fromFormat(open.time, 'HHmm')
    const dtB = DateTime.fromFormat(closeTime, 'HHmm')
    return [
      hours.open_now ?
        new Icon('clock_outline_success') :
        new Icon('clock_outline_attention'),
      new HtmlSpan([
        new HtmlTime({
          dateTime : dtA.toISO(),
          children : dtA.toFormat('H:mm'),
        }),
        '-',
        new HtmlTime({
          dateTime : dtB.toISO(),
          children : closeTime === '2400' ? '24:00' : dtB.toFormat('H:mm'),
        }),
      ]),
    ]
  }
}
