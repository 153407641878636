import { InfoObjectDialog } from './InfoObjectDialog.js'
import { InfoObjectAboutPanel } from './InfoObjectAboutPanel.js'
import { InfoCountryStatsPanel } from './InfoCountryStatsPanel.js'
import wpc from '../common/wpc.js'
import api from './api.js'
import './InfoCountryDialog.css'

export class InfoCountryDialog extends InfoObjectDialog
{
  static class = 'InfoCountryDialog'

  static sections = [
    {
      value : 'about',
      title : 'Справка',
      panel : InfoObjectAboutPanel,
    },
    {
      value : 'stats',
      title : 'Статистика',
      panel : InfoCountryStatsPanel,
    },
  ]

  async _load() {
    const countryId = this.props.objectId
    if(!countryId) {
      return
    }
    if(this.state.object) {
      this.setState({ object : undefined })
    }
    const object = await api.getCountry(countryId)
    if(object.info) {
      this.setState({ object })
      return
    }
    const summary = object.name_ru && await wpc.getPageSummary(object.name_ru, 'ru')
    if(!summary) {
      this.setState({ object })
      return
    }
    if(summary.originalimage) {
      const img = `<img src="${ summary.originalimage.source }" alt="${ summary.title }">`
      object.info = img + summary.extract_html
    }
    else object.info = summary.extract_html
    this.setState({ object })
  }
}
