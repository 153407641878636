import { Dialog, PopupContent } from 'xuick'
import { SlidePopup } from './SlidePopup.js'
import { TravelModeListBox } from './TravelModeListBox.js'
import api from './api.js'
import './TravelModeDialog.css'

export class TravelModeDialog extends Dialog
{
  static class = ['TravelModeDialog', SlidePopup.class].join(' ')

  static defaultProps = {
    modal : true,
  }

  state = {
    ...this.state,
    busy : false,
  }

  init() {
    super.init()
    this.on('change', this.#onChange)
  }

  render() {
    return new PopupContent(
      new TravelModeListBox({
        label : 'Изменить способ передвижения',
        value : this.props.trip.travelMode || 'driving',
        disabled : this.state.busy,
      }),
    )
  }

  async #onChange(e) {
    const travelMode = e.target.value
    this.setState({ busy : true })
    try {
      await api.updateTrip(this.props.trip.id, { travelMode })
      this.close()
    }
    catch(err) {
      this.setState({ busy : false })
      throw err
    }
  }
}
