import {
  Main,
  Button,
  Heading,
  TabList,
  Tab,
  Form,
  TextBox,
  Group,
  Label,
  Widget,
  Span,
  RadioGroup,
} from 'xuick'
import { Icon } from './Icon.js'
import { Screen } from './Screen.js'
import { MainHead } from './MainHead.js'
import { MobileMenu } from './MobileMenu.js'
import { MobileHeader } from './MobileHeader.js'
import './TicketsScreen.css'

export class TicketsScreen extends Screen
{
  static class = 'TicketsScreen'

  render() {
    return [
      new MobileMenu,
      new MobileHeader({
        titleText : 'Билеты',
      }),
      new Main([
        new MainHead([
          new Button({
            children : new Icon('left_outline_black'),
            onclick : () => history.back(),
          }),
          new Heading([
            new Icon('two-tickets_outline'),
            'Билеты',
          ]),
          new Button({
            children : new Icon('menu-vertical_outline_black'),
          }),
        ]),
        new TabList([
          new Tab({
            selected : true,
            children : 'Авиа',
          }),
          new Tab({
            selected : false,
            children : 'ЖД',
          }),
          new Tab({
            selected : false,
            children : 'Автобус',
          }),
        ]),
        new Form([
          new TextBox({
            label : 'Откуда?',
            placeholder : 'Город или аэропорт',
          }),
          new TextBox({
            label : 'Куда?',
            placeholder : 'Страна, город или аэропорт',
          }),
          new TextBox({
            label : 'Когда?',
            placeholder : 'Выберите даты',
          }),
          new Group([
            new Label('Кто летит?'),
            new SpinButton([
              new Label('Взрослые и дети от 12 лет'),
              new Button({ label : '−' }),
              new Span('1'),
              new Button({ label : '+' }),
            ]),
            new SpinButton([
              new Label('Дети от 2 до 12 лет'),
              new Button({ label : '−' }),
              new Span('0'),
              new Button({ label : '+' }),
            ]),
            new SpinButton([
              new Label('Дети без места до 2 лет'),
              new Button({ label : '−' }),
              new Span('0'),
              new Button({ label : '+' }),
            ]),
          ]),
          new RadioGroup({
            label : 'Класс перелета',
            children : [
              new Button({
                classList : 'action',
                label : 'Эконом',
              }),
              new Button({
                classList : 'action',
                label : 'Бизнес',
              }),
              new Button({
                classList : 'action',
                label : 'Первый',
              }),
            ],
          }),
          new Button({
            type : 'submit',
            classList : 'accent',
            label : 'Найти билеты',
          }),
        ]),
      ]),
    ]
  }
}

class SpinButton extends Widget
{
  static class = 'SpinButton'
}
