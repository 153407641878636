import { Button, PopupMenu, MenuItem } from 'xuick'
import { SlidePopup } from './SlidePopup.js'
import './TripPlaceAddButton.css'

export class TripPlaceAddButton extends Button
{
  static class = 'TripPlaceAddButton'

  static defaultProps = {
    label : 'Добавить точку',
    icon : 'plus_outline',
    popup : {
      modal : true,
      classList : SlidePopup.class,
    },
  }

  constructor({ route, ...props }) {
    const params = new URLSearchParams({
      tripId : route.tripId,
      routeId : route.id,
    })
    super({
      menu : menuProps => new PopupMenu({
        ...menuProps,
        label : 'Добавить новое место к маршруту',
        orientation : 'vertical',
        items : [
          new MenuItem({
            icon : 'map-marker_outline',
            label : 'Поиск на карте',
            to : '/map?' + params,
          }),
        ],
      }),
      ...props,
    })
  }
}
