import lodash from 'lodash'
import { Group, Label, Control, Button, HeadBlock } from 'xuick'
import { PlaceCategoryBox } from './PlaceCategoryBox'
import { Trip } from '../common/Trip'
import './PlaceCategoryGroup.css'

export class PlaceCategoryGroup extends Group
{
  static class = 'PlaceCategoryGroup'

  #items = lodash.filter(Trip.keywords, 'filter')

  value = []

  init() {
    super.init()
    this.on('change', this.#onChange)
  }

  render() {
    const props = this.props
    const stats = props.stats || {}
    const items = props.items || this.#items
    const enabledItems = items.filter(item => stats[item.keyword])
    const value = this.value
    return [
      new HeadBlock([
        new Label(props.label),
        new Button({
          disabled : props.disabled,
          children : value.length === enabledItems.length ?
            'Снять выбор' :
            'Выбрать все',
          onclick : this.#onButtonClick,
        }),
      ]),
      new Control(items.map(item => {
        const count = stats[item.keyword] ?? 1
        return new PlaceCategoryBox({
          name : item.keyword,
          label : item.label,
          disabled : props.disabled || !count,
          checked : value.includes(item.keyword),
        })
      })),
    ]
  }

  #onChange(e) {
    if(e.target === this) {
      return
    }
    e.stopImmediatePropagation()
    const target = e.target
    this.value = target.value ?
      [...this.value, target.name] :
      lodash.without(this.value, target.name)
    this.emit('change')
  }

  #onButtonClick = () => {
    const props = this.props
    const stats = props.stats || {}
    const items = props.items || this.#items
    const enabledItems = items.filter(item => stats[item.keyword])
    this.value = this.value.length === enabledItems.length ?
      [] :
      lodash.map(items, 'keyword')
    this.emit('change')
  }
}
