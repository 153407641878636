import { RoleImg } from 'xuick'
import { Icon } from './Icon'
import './UserCardPhoto.css'

export class UserCardPhoto extends RoleImg
{
  static class = 'UserCardPhoto'

  render() {
    const photo = this.props.user.photos[0]
    if(!photo) {
      return new Icon('unsplash_metro_dim')
    }
    const url = Object.assign(new URL('/photo', location.origin), {
      search : new URLSearchParams({
        image : photo.image,
        width : 80,
        height : 80,
      }),
    })
    this.style = {
      backgroundImage : `url(${ url })`,
    }
  }
}
