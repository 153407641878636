import lodash from 'lodash'
import { DateTime } from 'luxon'
import { DialogContent, PopupMenu, MenuItem, ConfirmDialog, Heading } from 'xuick'
import { ActionsDialog } from './ActionsDialog.js'
import { SlidePopup } from './SlidePopup.js'
import { TripPlaceCommentDialog } from './TripPlaceCommentDialog.js'
import api from './api'

export class TripPlaceActionsDialog extends ActionsDialog
{
  static class = 'TripPlaceActionsDialog'

  state = {
    ...this.state,
    busy : false,
  }

  render() {
    const { props, state } = this
    const { trip, route, place } = props
    const number = trip.routes.indexOf(route) + 1
    const dt = DateTime.fromISO(route.date)
    const date = dt.toLocaleString({
      month : 'numeric',
      day : 'numeric',
    })
    return new DialogContent([
      new Heading(`${ date } День ${ number }: ${ place.name }`),
      new PopupMenu({
        orientation : 'vertical',
        children : [
          new MenuItem({
            label : place.RoutePlace.comment ?
              'Изменить комментарий к этой точке' :
              'Добавить комментарий к этой точке',
            icon : 'message_outline',
            disabled : state.busy,
            dialog : dialogProps => new TripPlaceCommentDialog({
              ...dialogProps,
              route,
              place,
              classList : 'SlidePopup',
              onload : this.#onDialogLoad,
            }),
          }),
          new MenuItem({
            label : 'Удалить эту точку из маршрута',
            icon : 'bin_outline',
            disabled : state.busy,
            dialog : dialogProps => new ConfirmDialog({
              ...dialogProps,
              classList : 'SlidePopup',
              title : `Удаление мест с примечаниями из маршрутов`,
              message : `Место, которое вы хотите удалить из маршрута, может содержать примечания. При удалении места примечание к нему будет невозможно восстановить. \n\nУдалить это место из маршрута?`,
              submitLabel : 'Удалить',
              cancelLabel : 'Отмена',
              onsubmit : this.#onDialogSubmit,
            }),
          }),
        ],
      }),
    ])
  }

  #onDialogLoad = e => {
    e.target.close()
    this.emit('load')
  }

  #onDialogSubmit = async e => {
    const { route, place } = this.props
    const places = lodash.without(route.places, place)
    const ids = lodash.map(places, 'id')
    await api.updateRoutePlaces(route.id, ids)
    e.target.close()
    this.emit('load')
  }
}
