import lodash from 'lodash'
import { HtmlA, Button } from 'xuick'
import { Icon } from './Icon.js'
import { ProfileItem } from './ProfileItem.js'
import { ProfileSiteDialog } from './ProfileSiteDialog.js'

const TRUNCATE_URL_LENGTH = 35

export class ProfileSiteItem extends ProfileItem
{
  static class = 'ProfileSiteItem'

  render() {
    const { user, editMode } = this.props
    let site
    if(user.site) {
      site = user.site.replace(/^https?:\/\//, '')
      site = lodash.truncate(site, {
        length : TRUNCATE_URL_LENGTH,
      })
    }
    return [
      new Icon('internet_outline'),
      editMode ?
        new Button(site || 'Ваш сайт или профиль в соцсетях') :
        new HtmlA({
          href : user.site,
          target : '_blank',
          children : site,
        }),
      new ProfileSiteDialog({
        user,
        modal : true,
        open : this.state.expanded,
        oncancel : () => {
          this.setState({ expanded : false })
        },
        onload : () => {
          this.setState({ expanded : false })
        },
      }),
    ]
  }
}
