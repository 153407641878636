import { HtmlDiv, HtmlP, Button } from 'xuick'
import { Icon } from './Icon.js'
import { ProfileItem } from './ProfileItem.js'
import { ProfileDetailsDialog } from './ProfileDetailsDialog.js'
import './ProfileDetailsItem.css'

export class ProfileDetailsItem extends ProfileItem
{
  static class = 'ProfileDetailsItem'

  render() {
    const { user, editMode } = this.props
    return [
      new Icon('more-info_outline'),
      new HtmlDiv([
        editMode ?
          new Button('О себе') :
          new HtmlDiv('О себе'),
        user.details && new HtmlP({
          innerText : user.details,
        }),
      ]),
      new ProfileDetailsDialog({
        user,
        modal : true,
        open : this.state.expanded,
        oncancel : () => {
          this.setState({ expanded : false })
        },
        onload : () => {
          this.setState({ expanded : false })
        },
      }),
    ]
  }
}
