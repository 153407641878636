import { DateTime } from 'luxon'
import { Tab, Time, Control } from 'xuick'
import './TripDateTab.css'

export class TripDateTab extends Tab
{
  static class = 'TripDateTab'

  date
  route

  render() {
    const dt = DateTime.fromISO(this.date)
    return this.control = new Control(
      new Time(dt.toLocaleString({
        day : 'numeric',
        month : 'long',
      })),
    )
  }
}
