import { DateTime } from 'luxon'
import { HtmlDiv, Button, Time } from 'xuick'
import { Icon } from './Icon.js'
import './TripRouteHead.css'

export class TripRouteHead extends HtmlDiv
{
  static class = 'TripRouteHead'

  date

  init() {
    super.init()
    this.on('click', this.#onClick)
  }

  render() {
    const props = this.props
    const routes = props.trip.routes
    const first = routes[0]
    const last = routes.at(-1)
    const date = props.date
    const dt = DateTime.fromISO(date)
    return [
      new Button({
        children : new Icon('left_outline_black'),
        disabled : !first || date === first.date,
        value : dt.minus({ days : 1 }).toISODate(),
      }),
      new Time(dt.toLocaleString({
        day : 'numeric',
        month : 'long',
      })),
      new Button({
        children : new Icon('right_outline_black'),
        disabled : !last || date === last.date,
        value : dt.plus({ days : 1 }).toISODate(),
      }),
    ]
  }

  #onClick(e) {
    const button = e.target.closest(Button)
    if(!button) {
      return
    }
    this.date = button.value
    this.emit('change')
  }
}
