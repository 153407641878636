import { RoleFeed } from 'xuick'
import { LoadingIndicator } from './LoadingIndicator.js'
import { TripsEmptyBlock } from './TripsEmptyBlock.js'
import { TripCard } from './TripCard.js'
import './TripsFeed.css'

export class TripsFeed extends RoleFeed
{
  static class = 'TripsFeed'

  render() {
    const { trips, filter, busy } = this.props
    if(busy) {
      return new LoadingIndicator
    }
    if(!trips.length) {
      return new TripsEmptyBlock({ filter })
    }
    return trips.map(
      trip => new TripCard({
        key : trip.id,
        trip,
      }),
    )
  }
}
