import { TabPanel, Heading, Status, Article } from 'xuick'
import './InfoPlaceAboutPanel.css'

export class InfoPlaceAboutPanel extends TabPanel
{
  static class = 'InfoPlaceAboutPanel'

  render() {
    const props = this.props
    if(props.hidden) {
      return
    }
    const place = props.place
    return place.info ?
      new Article({
        innerHTML : new Heading(place.name) + place.info,
      }) :
      new Status('Нет данных')
  }
}
