import {
  Class,
  DialogContent,
  DialogHead,
  Heading,
  Button,
  DialogBody,
  HtmlDiv,
  TextBox,
  Status,
  Form,
} from 'xuick'
import { ProfileItemDialog } from './ProfileItemDialog.js'
import { HeadBackButton } from './HeadBackButton.js'
import api from './api.js'
import './ProfileDetailsDialog.css'

const MAX_DETAILS_LENGTH = 1000

export class ProfileDetailsDialog extends ProfileItemDialog
{
  static class = 'ProfileDetailsDialog'

  state = {
    ...this.state,
    busy : false,
    details : null,
  }

  init() {
    super.init()
    this.on('cancel', this.onCancel)
    this.on('submit', this.onSubmit)
  }

  render() {
    const user = this.props.user
    const state = this.state
    const value = state.details ?? user.details ?? ''
    const number = MAX_DETAILS_LENGTH - value.length
    return new Form({
      classList : Class.generate(DialogContent),
      children : [
        new DialogHead([
          new HeadBackButton({
            disabled : state.busy,
          }),
          new Heading('О себе'),
        ]),
        new DialogBody([
          new HtmlDiv([
            new TextBox({
              value,
              enterKeyHint : 'enter',
              multiLine : true,
              disabled : state.busy,
              oninput : e => {
                this.setState({ details : e.target.value })
              },
            }),
            new Status({
              innerText : `${ value.length } / ${ MAX_DETAILS_LENGTH }`,
              classList : value.length > MAX_DETAILS_LENGTH && 'overflow',
            }),
          ]),
          new Button({
            label : 'Сохранить',
            type : 'submit',
            classList : 'accent',
            disabled : state.busy || state.details === user.details || number < 0,
          }),
        ]),
      ],
    })
  }

  onCancel() {
    this.setState({ details : null })
  }

  async onSubmit() {
    this.setState({ busy : true })
    try {
      await api.updateUser({
        details : this.state.details.trim() || null,
      })
      this.setState({
        busy : false,
        details : null,
      })
      this.emit('load')
    }
    catch(err) {
      this.setState({ busy : false })
    }
  }
}
