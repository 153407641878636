import { DateTime } from 'luxon'
import { HtmlArticle, Heading, Time } from 'xuick'
import { Icon } from './Icon'
import { DateInterval } from '../common/DateInterval.js'
import { TripPhoto } from './TripPhoto.js'
import { UserPhoto } from './UserPhoto.js'
import './TripMainCard.css'

export class TripMainCard extends HtmlArticle
{
  static class = 'TripMainCard'

  static generateTitle(trip) {
    if(trip.title) {
      return trip.title
    }
    const destination = trip.destination
    const name = destination.name_ru || destination.name_en
    const interval = DateInterval.fromDateRange(trip.interval)
    const duration = interval.toDuration('days', { locale : 'ru' })
    const days = duration.plus({ days : 1 })
    return `${ name }<br>на ${ days.toHuman() }`
  }

  render() {
    const trip = this.props.trip
    const title = TripMainCard.generateTitle(trip)
    const interval = DateInterval.fromDateRange(trip.interval)
    const time = interval.toLocaleString(DateTime.DATE_SHORT)
    return [
      new TripPhoto({
        photo : trip.routes[0]?.places[0]?.photos[0],
      }),
      new Heading({ innerHTML : title }),
      new Time([new Icon('calendar_custom_white'), time]),
      new UserPhoto({
        user : trip.creator,
      }),
    ]
  }
}
