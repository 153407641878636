import { HtmlDiv, Switch } from 'xuick'
import { Icon } from './Icon.js'
import { ProfileItem } from './ProfileItem.js'
import geolocation from './geolocation.js'
import api from './api.js'

export class ProfileLocationItem extends ProfileItem
{
  static class = 'ProfileLocationItem'

  state = {
    busy : false,
  }

  init() {
    void null
  }

  render() {
    const { props, state } = this
    const user = props.user
    return [
      new Icon('location_filled'),
      new HtmlDiv(
        state.busy ? 'Загрузка...' : user.showGeolocation ?
          user.placeOfStay?.name || 'Unknown' :
          'Показывать геолокацию',
      ),
      this._switch = new Switch({
        checked : user.showGeolocation,
        disabled : state.busy,
        hidden : !props.editMode,
        onchange : this.onChange,
      }),
    ]
  }

  onChange = async e => {
    const checked = e.target.value
    this.setState({ busy : true })
    try {
      if(!checked) {
        await api.updateUserGeolocation(null)
        return
      }
      const { coords } = await geolocation.getCurrentPosition()
      await api.updateUserGeolocation({
        lat : coords.latitude,
        lng : coords.longitude,
      })
    }
    finally {
      this.setState({ busy : false })
    }
  }
}
