import lodash from 'lodash'
import { DialogContent, DialogHead, DialogBody, Heading, Button, ListItem, List } from 'xuick'
import { Icon } from './Icon.js'
import { LanguageBox } from './adaptive/LanguageBox.js'
import { ProfileItemDialog } from './ProfileItemDialog.js'
import { HeadBackButton } from './HeadBackButton.js'
import api from './api.js'

export class ProfileLangDialog extends ProfileItemDialog
{
  static class = 'ProfileLangDialog'

  init() {
    super.init()
    this.on('change', this.#onChange)
  }

  render() {
    const state = this.state
    const user = this.props.user
    return new DialogContent([
      new DialogHead([
        new HeadBackButton({
          disabled : state.busy,
        }),
        new Heading('Языки, которыми вы владеете'),
      ]),
      new DialogBody([
        this._langBox = new LanguageBox({
          label : 'Выберите язык',
          disabled : state.busy,
          excludeIds : lodash.map(user.languages, 'id'),
        }),
        new List(user.languages.map(item => {
          return new ListItem({
            key : item.id,
            children : [
              item.name,
              new Button({
                value : item.id,
                disabled : state.busy,
                onclick : this.#onButtonClick,
                children : state.busy ?
                  new Icon('bin_outline_dim') :
                  new Icon('bin_outline'),
              }),
            ],
          })
        })),
      ]),
    ])
  }

  async #updateUserLanguages(languages) {
    this.setState({ busy : true })
    try {
      await api.updateUser({
        languages : lodash.map(languages, 'id'),
      })
      this._langBox.reset()
    }
    finally {
      this.setState({ busy : false })
    }
  }

  #onChange(e) {
    const item = e.target.item
    if(!item) {
      return
    }
    void this.#updateUserLanguages([
      ...this.props.user.languages,
      item,
    ])
  }

  #onButtonClick = e => {
    const button = e.target.closest(Button)
    const languages = this.props.user.languages.filter(
      item => item.id !== button.value,
    )
    void this.#updateUserLanguages(languages)
  }
}
