import { HtmlDiv } from 'xuick'
import { PlacePhoto } from './PlacePhoto'
import './PlacePhotoGroup.css'

export class PlacePhotoGroup extends HtmlDiv
{
  static class = 'PlacePhotoGroup'

  init() {
    super.init()
    this.on('click', this.#onClick)
  }

  render() {
    return this.props.place.photos.map(
      photo => new PlacePhoto({ photo }),
    )
  }

  async mount() {
    super.mount()
    await new Promise(setTimeout)
    this.node.scrollLeft = 0
  }

  #onClick(e) {
    const photo = e.target.closest(PlacePhoto)
    if(!photo) {
      return
    }
    const style = getComputedStyle(this.node)
    const paddingLeft = parseInt(style.paddingLeft) || 0
    this.node.scrollTo({
      left : photo.node.offsetLeft - paddingLeft,
      behavior : 'smooth',
    })
  }
}
