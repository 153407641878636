import { Main, MainBlock, Heading, Group } from 'xuick'
import { LoadingIndicator } from './LoadingIndicator.js'
import { InfoMainHead } from './InfoMainHead.js'
import { InfoObjectNav } from './InfoObjectNav.js'
import { InfoObjectLink } from './InfoObjectLink.js'
import { InfoRegionDialog } from './InfoRegionDialog.js'
import api from './api.js'

export class InfoRegionsMain extends Main
{
  static class = 'InfoRegionsMain'

  state = {
    macroregions : undefined,
  }

  render() {
    const macroregions = this.state.macroregions
    const objectId = this.props.regionId
    return [
      new InfoMainHead('Регионы'),
      macroregions ?
        new MainBlock(macroregions.map(
          macroregion => new InfoObjectNav([
            new Heading(macroregion.name),
            new Group(macroregion.regions.map(
              region => new InfoObjectLink({
                to : '/info/regions/' + region.id,
                photo : region.photos[0] || null,
                label : region.name,
              }),
            )),
          ]))) :
        new LoadingIndicator,
      new InfoRegionDialog({
        objectId,
        open : !!objectId,
        oncancel : () => history.back(),
      }),
    ]
  }

  mount() {
    super.mount()
    void this.#load()
  }

  async #load() {
    this.setState({
      macroregions : await api.getMacroregions(),
    })
  }
}
