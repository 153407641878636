import { Block } from 'xuick'
import { TripPlaceItem } from './TripPlaceItem'
import { TripPlaceButton } from './TripPlaceButton.js'
import './TripStayItem.css'

export class TripStayItem extends TripPlaceItem
{
  static class = 'TripStayItem'

  render() {
    return [
      new Block(new TripPlaceButton({
        label : 'Размещение',
      })),
    ]
  }
}
