import { HtmlDiv } from 'xuick'
import './MainHead.css'

export class MainHead extends HtmlDiv
{
  static class = 'MainHead'

  constructor(props) {
    if(typeof props === 'string') {
      super({ text : props })
    }
    else super(props)
  }
}
