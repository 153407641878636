import { HtmlDiv, Option, Note } from 'xuick'
import './PlaceOption.css'

export class PlaceOption extends Option
{
  static class = 'PlaceOption'

  render() {
    const place = this.props.item
    const note = place.id ?
      `${ place.locality.name }, ${ place.country.name }` :
      `Искать «${ place.name }»`
    return [
      new HtmlDiv(place.name),
      new Note(note),
    ]
  }
}
