import { Screen } from './Screen'
import { MobileMenu } from './MobileMenu'
import { SearchHeader } from './SearchHeader.js'
import { SearchMain } from './SearchMain.mobile'
import './SearchScreen.css'

export class SearchScreen extends Screen
{
  static class = 'SearchScreen'

  init() {
    super.init()
    this.on('submit', this.onSubmit)
  }

  render() {
    document.title = 'Поиск'
    return [
      new MobileMenu,
      new SearchHeader,
      this._main = new SearchMain({
        key : 'search',
      }),
    ]
  }

  async onSubmit() {
    document.documentElement.scrollTop = 0
    await new Promise(setTimeout)
    await this._main.load()
  }
}
