export default {
  options : {
    enableHighAccuracy : true,
    maximumAge : 60_000,
    timeout : 10_000,
  },
  getCurrentPosition(options = this.options) {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, options)
    })
  },
  watchPosition(successCallback, errorCallback, options = this.options) {
    return navigator.geolocation.watchPosition(successCallback, errorCallback, options)
  },
  clearWatch(watchId) {
    navigator.geolocation.clearWatch(watchId)
  },
  async getLocation() {
    try {
      const { coords } = await this.getCurrentPosition()
      return {
        lat : coords.latitude,
        lng : coords.longitude,
      }
    }
    catch(err) {
      console.error(err)
    }
  },
}
