import { DateTime } from 'luxon'
import { HtmlArticle, FootBlock, Heading, MainBlock, Time, Link } from 'xuick'
import { Icon } from './Icon.js'
import { Trip } from '../common/Trip.js'
import { DateInterval } from '../common/DateInterval.js'
import { TripPhoto } from './TripPhoto.js'
import { UserPhoto } from './UserPhoto.js'
import { TripMenuButton } from './TripMenuButton.js'
import { TripMenu } from './TripMenu.js'
import './TripCard.css'

export class TripCard extends HtmlArticle
{
  static class = 'TripCard'

  static defaultProps = {
    tabIndex : 0,
  }

  trip

  render() {
    const trip = this.props.trip
    const url = Object.assign(new URL(location), {
      pathname : '/trips/' + trip.id,
    })
    const title = Trip.generateTitle(trip)
    const interval = DateInterval.fromDateRange(trip.interval)
    const time = interval.toLocaleString(DateTime.DATE_SHORT)
    return new Link({
      to : url.href,
      children : [
        new TripPhoto({
          photo : trip.routes[0]?.places[0]?.photos[0],
        }),
        new FootBlock([
          new UserPhoto({
            user : trip.creator,
          }),
          new MainBlock([
            new Heading(title),
            new Time(time),
          ]),
          new TripMenuButton({
            menu : menuProps => new TripMenu({
              ...menuProps,
              trip,
            }),
            onclick : e => {
              e.preventDefault()
              e.stopImmediatePropagation()
            },
          }),
        ]),
        trip.comment && new Icon('important-note_outline_white'),
      ],
    })
  }
}
