import { HtmlDetails, Main, MainBlock, SearchBox } from 'xuick'
import { LoadingIndicator } from './LoadingIndicator.js'
import { InfoMainHead } from './InfoMainHead.js'
import { InfoNavBlock } from './InfoNavBlock.js'
import { InfoNavSummary } from './InfoNavSummary.js'
import { InfoCountriesNav } from './InfoCountriesNav.js'
import { InfoCountryDialog } from './InfoCountryDialog.js'
import api from './api'
import './InfoCountriesMain.css'

export class InfoCountriesMain extends Main
{
  static class = 'InfoCountriesMain'

  state = {
    regions : undefined,
    query : '',
  }

  render() {
    const state = this.state
    const regions = state.regions
    const objectId = this.props.countryId
    const query = state.query.trim().toUpperCase().replaceAll('Ё', 'Е')
    return [
      new InfoMainHead('Страны и территории'),
      regions ?
        new MainBlock([
          new SearchBox({
            label : 'Введите название страны',
            placeholder : 'Например, Палау',
            value : state.query,
            oninput : e => {
              this.setState({ query : e.target.value })
            },
          }),
          regions.map(region => {
            const filter = country => {
              const name = country.name.toUpperCase().replaceAll('Ё', 'Е')
              return name.includes(query)
            }
            const countries = query ?
              region.countries.filter(filter) :
              region.countries
            return new InfoNavBlock({
              hidden : !countries.length,
              children : [
                new HtmlDetails({
                  open : query ? !!countries.length : undefined,
                  children : [
                    new InfoNavSummary({
                      text : region.name,
                      // count : countries.length,
                    }),
                    new InfoCountriesNav({ countries }),
                  ],
                }),
              ],
            })
          }),
        ]) :
        new LoadingIndicator,
      new InfoCountryDialog({
        objectId,
        open : !!objectId,
        oncancel : () => history.back(),
      }),
    ]
  }

  async mount() {
    super.mount()
    this.setState({
      regions : await api.getRegions(),
    })
  }
}
