import { HtmlDiv, Button, CountryFlag, List, ListItem, Link } from 'xuick'
import { Icon } from './Icon.js'
import { ProfileItem } from './ProfileItem.js'
import { ProfileCountryDialog } from './ProfileCountryDialog.js'
import './ProfileCountryItem.css'

const rules = new Intl.PluralRules('ru')
const forms = {
  one : 'страну',
  few : 'страны',
  many : 'стран',
}

export class ProfileCountryItem extends ProfileItem
{
  static class = 'ProfileCountryItem'

  state = {
    ...this.state,
    open : false,
  }

  render() {
    const { user, editMode } = this.props
    const countries = user.countries
    let children
    if(countries.length) {
      const form = forms[rules.select(countries.length)]
      children = `Посетил(а) ${ countries.length } ${ form }`
    }
    this.classList = this.state.open && 'open'
    return [
      new Icon('earth-globe_outline'),
      new HtmlDiv([
        editMode ?
          new Button(children || 'Страны, которые вы посетили') :
          new HtmlDiv({
            onclick : this.#onButtonClick,
            children,
          }),
        !!countries.length && new List({
          hidden : !this.state.open,
          children : countries.map(
            country => new ListItem(
              new Link({
                to : editMode ?
                  null :
                  '/info/countries/' + country.id,
                children : new CountryFlag({
                  code : country.code,
                  src : country.flag,
                }),
              }),
            ),
          ),
        }),
      ]),
      new Button({
        children : new Icon('expand-arrow_custom_black'),
        onclick : this.#onButtonClick,
        hidden : !countries.length,
      }),
      new ProfileCountryDialog({
        user,
        modal : true,
        open : this.state.expanded,
        oncancel : () => {
          this.setState({ expanded : false })
        },
      }),
    ]
  }

  #onButtonClick = e => {
    e.stopPropagation()
    this.setState(state => ({
      open : !state.open,
    }))
  }
}
