import { Main } from 'xuick'
import './Err404Main.css'

export class Err404Main extends Main
{
  static class = 'Err404Main'

  render() {
    document.title = 'Страница не найдена'
    return '4 0 4'
  }
}
