import { Photo } from './Photo.js'
import { Icon } from './Icon.js'
import './TripPhoto.css'

export class TripPhoto extends Photo
{
  static class = 'TripPhoto'

  state = {
    height : undefined,
  }

  render() {
    const photo = this.props.photo
    if(!photo) {
      return new Icon('unsplash_metro_dim')
    }
    const url = Photo.getUrl(photo.image)
    const height = this.state.height
    this.style = {
      backgroundImage : `url(${ url })`,
      height : height ? Math.floor(height) + 'px' : null,
    }
    return null
  }

  /*async mount() {
    super.mount()
    await new Promise(setTimeout)
    const photo = this.props.photo
    const offsetWidth = this.node.offsetWidth
    this.setState({
      height : offsetWidth / photo.width * photo.height,
    })
  }*/
}
