import { MenuItem } from 'xuick'
import { TripActionsMenu } from './TripActionsMenu.js'
import api from './api.js'

export class TripRouteActionsMenu extends TripActionsMenu
{
  static class = 'TripRouteActionsMenu'

  static defaultProps = {
    orientation : 'horizontal',
  }

  render() {
    const { trip, route } = this.props
    const params = new URLSearchParams({
      tripId : trip.id,
      routeId : route.id,
    })
    return [
      /*new MenuItem({
        icon : 'two-tickets_outline_white',
        label : 'Купить билеты',
      }),*/
      /*new MenuItem({
        icon : 'taxi_outline_white',
        label : 'Заказать такси',
      }),*/
      /*new MenuItem({
        children : [
          new Icon('occupied-bed_outline_white'),
          new Label({
            innerText : 'Найти\nотель',
          }),
        ],
      }),*/
      api.user.role && new MenuItem({
        label : 'Добавить друга',
        icon : 'plus_outline_white',
      }),
      new MenuItem({
        label : 'Посмотреть  на карте',
        icon : 'map-marker_outline_white',
        to : '/map?' + params,
      }),
    ]
  }
}
