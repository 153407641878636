const { Item } = require('./Item')

class User extends Item
{
  static props = require('../../app/models/User.props')

  isFriendOf(user) {
    if(this.friends?.some(item => item.id === user.id)) {
      return true
    }
    return user.friends?.some(item => item.id === this.id)
  }

  isFollowingOf(user) {
    if(this.followers?.some(item => item.id === user.id)) {
      return true
    }
    return !!user.following?.some(item => item.id === this.id)
  }

  isFollowerOf(user) {
    if(this.following?.some(item => item.id === user.id)) {
      return true
    }
    return !!user.followers?.some(item => item.id === this.id)
  }

  get firstName() {
    return this.name?.split(/\s+/)[0]
  }

  get familyName() {
    return this.name?.split(/\s+/)[1] ?? null
  }
}

module.exports = { User }
