import { Main } from 'xuick'
import { Screen } from './Screen'
import { MobileMenu } from './MobileMenu'
import { MobileHeader } from './MobileHeader'
import { LoadingIndicator } from './LoadingIndicator'
import './TestScreen.css'

export class TestScreen extends Screen
{
  static class = 'TestScreen'

  state = {
    examples : undefined,
  }

  async init() {
    super.init()
    const module = await import('./TestScreen-examples')
    this.setState({
      examples : module.default,
    })
  }

  render() {
    const state = this.state
    if(!state.examples) {
      return [
        new MobileMenu,
        new MobileHeader({
          titleText : 'Тестовая страница',
        }),
        new LoadingIndicator,
      ]
    }
    const url = new URL(location)
    const name = url.searchParams.get('name')
    const modules = name ?
      [state.examples[name]].filter(Boolean) :
      Object.values(state.examples)
    return [
      new MobileMenu,
      new MobileHeader({
        titleText : 'Тестовая страница',
      }),
      new Main({
        key : 'test',
        children : modules.map(module => module.default()),
      }),
    ]
  }
}
