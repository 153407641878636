import { HtmlDiv, Link, FootBlock, LeftBlock } from 'xuick'
import { Card } from './Card.js'
import { PlacePhoto } from './PlacePhoto.js'
import { PlaceRating } from './PlaceRating.js'
import { InfoPlaceDialog } from './InfoPlaceDialog.js'
import './PlaceCard.css'

export class PlaceCard extends Card
{
  static class = 'PlaceCard'

  render() {
    const props = this.props
    const place = props.place
    const category = place.categories?.[0]
    return new Link({
      to : props.to,
      dialog : props.to ?
        undefined :
        dialogProps => {
          return new InfoPlaceDialog({
            ...dialogProps,
            objectId : place.id,
          })
        },
      children : [
        new PlacePhoto({ place }),
        new FootBlock([
          new LeftBlock([
            new PlaceName(place.name),
            new PlaceTypeName(category?.name || ' '),
          ]),
          new PlaceRating({ place }),
        ]),
      ],
    })
  }
}

export class PlaceName extends HtmlDiv
{
  static class = 'PlaceName'
}

export class PlaceTypeName extends HtmlDiv
{
  static class = 'PlaceTypeName'
}
