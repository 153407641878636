import { HtmlSummary, HtmlSpan } from 'xuick'
import { Icon } from './Icon'
import './InfoNavSummary.css'

export class InfoNavSummary extends HtmlSummary
{
  static class = 'InfoNavSummary'

  constructor(props) {
    if(typeof props === 'string') {
      super({ text : props })
    }
    else super(props)
  }

  render() {
    const props = this.props
    return [
      new HtmlSpan(props.text),
      props.count !== undefined && new HtmlSpan(props.count),
      new Icon('expand-arrow_custom_black'),
    ]
  }
}
