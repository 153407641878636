import { MenuItem, Label } from 'xuick'
import { Icon } from './Icon'

export class NavMenuItem extends MenuItem
{
  static class = 'NavMenuItem'

  static tagName = 'a'

  render() {
    const { icons, label } = this.props
    const icon = this.vnode.href && icons[1] || icons[0]
    return [
      new Icon(icon),
      label && new Label(label),
    ]
  }
}
