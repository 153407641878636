import { Group } from 'xuick'
import { MapApplication } from './MapApplication.js'
import { TripRoutePanel } from './TripRoutePanel.js'
import './TripRoutesGroup.css'

export class TripRoutesGroup extends Group
{
  static class = 'TripRoutesGroup'

  #x = 0

  render() {
    const props = this.props
    const trip = props.trip
    return trip.routes.map((route, i) => {
      const color = MapApplication.getRouteColorByIndex(i)
      return new TripRoutePanel({
        key : route.id,
        trip,
        route,
        expanded : route.date === props.date,
        travelMode : trip.travelMode || 'driving',
        style : `--route-color: ${ color };`,
        number : i + 1,
      })
    })
  }

  async mount() {
    super.mount()
    await new Promise(setTimeout)
    this.style.transition = 'none'
    this.updatePosition()
  }

  async update(prevProps, prevState) {
    super.update(prevProps, prevState)
    if(this.props.date !== prevProps.date) {
      await new Promise(setTimeout)
      this.style.transition = null
      this.updatePosition()
    }
  }

  updatePosition() {
    const date = this.props.date
    const panel = this.find(TripRoutePanel, panel => {
      return panel.route.date === date
    })
    this.x = this.#paddingLeft - panel.node.offsetLeft
  }

  get x() {
    return this.#x
  }

  set x(offset) {
    this.#x = offset
    this.style.transform = `translateX(${ offset }px)`
  }

  get #paddingLeft() {
    const style = getComputedStyle(this.node)
    return parseInt(style.paddingLeft) || 0
  }

  get step() {
    const panels = this.findAll(TripRoutePanel)
    return panels[1] ?
      panels[1].node.offsetLeft - panels[0].node.offsetLeft :
      panels[0].node.offsetWidth
  }
}
