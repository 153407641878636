import { Feed, Status } from 'xuick'
import { LoadingIndicator } from './LoadingIndicator.js'
import { LocalityCard } from './LocalityCard.js'
import geolocation from './geolocation.js'
import api from './api.js'
import './InfoLocalitiesFeed.css'

export class InfoLocalitiesFeed extends Feed
{
  static class = 'InfoLocalitiesFeed'

  static defaultProps = {
    limit : 10,
  }

  render() {
    const items = this.state.items
    if(!items) {
      return new LoadingIndicator
    }
    if(!items.length) {
      return new Status('Не найдено')
    }
    return items.map(
      locality => new LocalityCard({
        key : locality.id,
        to : '/info/localities/' + locality.id,
        locality,
      }),
    )
  }

  async loadItems(offset) {
    return api.findLocalities({
      query : this.props.query,
      location : await geolocation.getLocation(),
      publicOnly : true,
      limit : this.props.limit,
      offset,
    })
  }
}
