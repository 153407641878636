import { HtmlDiv, Paragraph, Div } from 'xuick'
import { Icon } from './Icon.js'
import './TripsEmptyBlock.css'

const icons = {
  plus : new Icon('plus_outline_white'),
  search : new Icon('search_outline'),
  tune : new Icon('tune_outline'),
}
const content = {
  actual : () => [
    new Div('Ой!'),
    new Paragraph({
      classList : 'bold',
      innerHTML : 'Пока что у вас нет ни одной поездки...',
    }),
    new Paragraph('В этом разделе находятся текущие и будущие поездки.'),
    new Paragraph({
      innerHTML : `Вы можете добавить новую поездку, нажав ${ icons.plus } или ввести в поиске ${ icons.search } интересующее вас место. Система предложит варианты готовых поездок, которые были спланированы программой или другими пользователями.`,
    }),
    new Paragraph({
      innerHTML : `Для более точной настройки поездки используйте фильтры ${ icons.tune }`,
    }),
  ],
  archive : () => [
    new Div('Ой!'),
    new Paragraph({
      classList : 'bold',
      innerHTML : 'Пока что ваш архив пуст...',
    }),
    new Paragraph('Поездки появляются в архиве, когда их даты уже прошли. Если даты изменить на текущие, поездка из архива переместится в Актуальные. '),
    new Paragraph({
      innerHTML : `Чтобы добавить в архив готовые поездки для просмотра или редактирования, воспользуйтесь поиском ${ icons.tune }`,
    }),
  ],
}

export class TripsEmptyBlock extends HtmlDiv
{
  static class = 'TripsEmptyBlock'

  render() {
    return content[this.props.filter]()
  }
}
