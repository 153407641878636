import lodash from 'lodash'
import { Button } from 'xuick'
import api from './api.js'
import './PlaceRouteButton.css'

export class PlaceRouteButton extends Button
{
  static class = 'PlaceRouteButton'

  static defaultProps = {
    icon : 'route_outline_white',
    classList : 'accent',
  }

  init() {
    super.init()
    this.on('click', this.#onClick)
  }

  async #onClick() {
    const props = this.props
    const route = props.route
    if(!route || !props.placeId) {
      return
    }
    const place = lodash.find(props.places, ['id', props.placeId])
    if(!place) {
      return
    }
    const places = [...route.places, place]
    const ids = lodash.map(places, 'id')
    await api.updateRoutePlaces(route.id, ids)
  }
}
