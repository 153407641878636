import lodash from 'lodash'
import { Main, Heading } from 'xuick'
import { SearchFeed } from './adaptive/SearchFeed'
import { MobileUserCard } from './MobileUserCard'
import { LoadingIndicator } from './LoadingIndicator'
import api from './api.js'
import './SearchMain.mobile.css'

/**
 * @abstract
 */
export class SearchMain extends Main
{
  static class = 'SearchMain'

  state = {
    items : undefined,
    stop : false,
    busy : false,
  }

  limit = 50

  /**
   * @return {Promise<*[]>}
   * @abstract
   */
  method = api.findUsers

  init() {
    window.addEventListener('scroll', this.onScroll)
  }

  render() {
    const items = this.state.items
    if(!items) {
      return new LoadingIndicator
    }
    if(!items.length) {
      return new Heading('Не найдено')
    }
    return [
      new Heading('Результат поиска'),
      new SearchFeed({
        busy : this.state.busy,
        children : items.map(this.renderItem, this),
      }),
    ]
  }

  /**
   * @abstract
   */
  renderItem(user) {
    return new MobileUserCard({
      key : user.id,
      user,
    })
  }

  destroy() {
    window.removeEventListener('scroll', this.onScroll)
  }

  mount() {
    void this.load()
  }

  async load(itemsA = []) {
    if(this.state.busy) {
      return
    }
    this.setState({ busy : true })
    try {
      const url = new URL(location)
      const query = url.searchParams.get('query')
      const itemsB = await this.method({
        query : query?.trim() || undefined,
        limit : this.limit,
        offset : itemsA.length,
      })
      const items = lodash.uniqBy([...itemsA, ...itemsB], 'id')
      this.setState({
        items,
        stop : items.length === itemsA.length,
        busy : false,
      })
    }
    catch(error) {
      this.setState({ busy : false })
      throw error
    }
  }

  onScroll = () => {
    if(this.state.stop) {
      return
    }
    const rect = this.node.getBoundingClientRect()
    if(rect.bottom < window.innerHeight * 2) {
      void this.load(this.state.items)
    }
  }
}
