import lodash from 'lodash'
import { ListItem, Button, Block } from 'xuick'
import { Icon } from './Icon'
import { TripPlaceButton } from './TripPlaceButton'
import { TripPlaceInfoDialog } from './TripPlaceInfoDialog.js'
import { TripPlaceActionsDialog } from './TripPlaceActionsDialog.js'
import './TripPlaceItem.css'

export class TripPlaceItem extends ListItem
{
  static class = 'TripPlaceItem'

  placeId

  render() {
    const { trip, routeId, placeId } = this.props
    const route = lodash.find(trip.routes, ['id', routeId])
    const place = placeId && lodash.find(route.places, ['id', placeId])
    return [
      new Block([
        new TripPlaceButton({
          label : place.name,
          place,
          dialog : dialogProps => new TripPlaceInfoDialog({
            ...dialogProps,
            trip,
            routeId,
            placeId,
          }),
        }),
        place?.RoutePlace.comment && new Icon('message_filled'),
      ]),
      place && new Button({
        type : 'menu',
        children : new Icon('menu-vertical_outline_black'),
        dialog : dialogProps => new TripPlaceActionsDialog({
          ...dialogProps,
          trip,
          route,
          place,
          onload : e => e.target.close(),
        }),
      }),
    ]
  }
}
