import { Dialog } from 'xuick'
import { SlidePopup } from './SlidePopup.js'
import './ActionsDialog.css'

export class ActionsDialog extends Dialog
{
  static class = ['ActionsDialog', SlidePopup.class].join(' ')

  static defaultProps = {
    modal : true,
    direction : 'bottom',
  }
}
