import { App, AlertDialog } from 'xuick'
import { AppError } from './adaptive/AppError.js'
import { SlidePopup } from './SlidePopup.js'
import { AuthScreen } from './AuthScreen.js'
import { TestScreen } from './TestScreen.js'
import { InfoScreen } from './InfoScreen.js'
import { TicketsScreen } from './TicketsScreen.js'
import { MapScreen } from './MapScreen.js'
import { TripsScreen } from './TripsScreen.js'
// import { ServicesScreen } from './ServicesScreen'
import { ProfileScreen } from './ProfileScreen.js'
import { SearchScreen } from './SearchScreen.js'
import { Err404Screen } from './Err404Screen.js'
import api from './api.js'
import './MobileApp.css'

export class MobileApp extends App
{
  static class = 'MobileApp'

  state = {
    ...this.state,
    error : null,
  }

  init() {
    super.init()
    window.addEventListener('resize', this.#updateHeight)
    window.addEventListener('unhandledrejection', this.onUnhandledRejection)
    document.addEventListener('contextmenu', this.#onContextMenu)
  }

  destroy() {
    super.destroy()
    window.removeEventListener('resize', this.#updateHeight)
    window.removeEventListener('unhandledrejection', this.onUnhandledRejection)
    document.removeEventListener('contextmenu', this.#onContextMenu)
  }

  render() {
    const state = this.state
    return [
      new AlertDialog({
        error : state.error,
        open : !!state.error,
        classList : SlidePopup.class,
        oncancel : () => {
          this.setState({ error : null })
        },
      }),
      App.router([
        !api.user.id ? {
          path : /.*/,
          render : AuthScreen,
        } : [
          {
            path : '/',
            render : () => {
              void this.navigate('/trips', { replace : true })
            },
          },
          {
            path : /^\/users\/(\d+)$/,
            render : userId => {
              return new ProfileScreen({ userId : +userId })
            },
          },
          {
            path : '/tickets',
            render : TicketsScreen,
          },
          {
            path : '/search',
            render : SearchScreen,
          },
          {
            path : /^\/info/,
            render : InfoScreen,
          },
          {
            path : /^\/map(?:\/place\/([\w\-]+))?$/,
            render : placeId => {
              const params = new URLSearchParams(location.search)
              return new MapScreen({
                placeId : +placeId || undefined,
                routeId : +params.get('routeId') || undefined,
                tripId : +params.get('tripId') || undefined,
              })
            },
          },
          {
            path : /^\/trips(?:\/(\d+))?$/,
            render : tripId => {
              return new TripsScreen({
                url : state.url,
                tripId : +tripId || undefined,
              })
            },
          },
          /*{
            path : '/services',
            render : ServicesScreen,
          },*/
          {
            path : '/test',
            render : TestScreen,
          },
          {
            path : /.*/,
            render : Err404Screen,
          },
        ],
      ]),
    ]
  }

  mount() {
    super.mount()
    this.#updateHeight()
  }

  update(prevProps, prevState) {
    if(this.state.url.href === prevState.url.href) {
      return
    }
    this.emit('app-navigate', { bubbles : true })
  }

  #updateHeight = () => {
    document.documentElement.style.setProperty('--doc-height', `${ innerHeight }px`)
  }

  onUnhandledRejection = e => {
    if(e.reason instanceof AppError) {
      e.preventDefault()
      this.setState({ error : e.reason })
    }
    else throw e.reason
  }

  #onContextMenu = e => e.preventDefault()
}
