import { HtmlDiv, ConfirmDialog } from 'xuick'
import { Icon } from './Icon.js'
import { ProfilePhoto } from './ProfilePhoto.js'
import { ProfilePhotoDialog } from './ProfilePhotoDialog.js'
import { ProfilePhotoUploadButton } from './ProfilePhotoUploadButton.js'
import { ProfilePhotoDeleteButton } from './ProfilePhotoDeleteButton.js'
import { SlidePopup } from './SlidePopup.js'
import api from './api.js'
import './ProfilePhotoBlock.css'

export class ProfilePhotoBlock extends HtmlDiv
{
  static class = 'ProfilePhotoBlock'

  state = {
    expanded : false,
    confirm : false,
    busy : false,
  }

  render() {
    const { props, state } = this
    if(!props.editMode) {
      return new ProfilePhoto({ user : props.user })
    }
    return [
      this._button = new ProfilePhotoUploadButton({
        key : 'upload',
        user : props.user,
        expanded : state.expanded,
        disabled : state.busy,
        onclick : () => {
          this.setState({ expanded : true })
        },
      }),
      new ProfilePhotoDialog({
        key : 'dialog',
        modal : true,
        anchor : this._button,
        open : state.expanded,
        oncancel : this.#onCancel,
        onload : () => {
          this.setState({ expanded : false })
        },
        user : props.user,
      }),
      !!props.user.photos.length && new ProfilePhotoDeleteButton({
        key : 'delete',
        children : new Icon('close_outline_black'),
        disabled : state.busy,
        onclick : () => {
          this.setState({ confirm : true })
        },
      }),
      new ConfirmDialog({
        key : 'confirm',
        title : 'Удалить фото?',
        message : 'Восстановить файлы будет невозможно',
        submitLabel : 'Удалить',
        cancelLabel : 'Отмена',
        open : state.confirm,
        disabled : state.busy,
        classList : SlidePopup.class,
        oncancel : () => {
          this.setState({ confirm : false })
        },
        onsubmit : this.#onConfirmSubmit,
      }),
    ]
  }

  #onCancel = e => {
    const target = e.target
    const cancelEvent = target.cancelEvent
    if(!cancelEvent) {
      return
    }
    if(cancelEvent.type === 'click' && !target.node.contains(cancelEvent.target)) {
      return
    }
    this.setState({ expanded : false })
  }

  #onConfirmSubmit = async () => {
    this.setState({ busy : true })
    await api.updateUserPhoto(null)
    this.setState({
      confirm : false,
      busy : false,
    })
  }
}
