import { HtmlA, Button } from 'xuick'
import { Icon } from './Icon.js'
import { ProfileItem } from './ProfileItem.js'
import { ProfileEmailDialog } from './ProfileEmailDialog.js'

export class ProfileEmailItem extends ProfileItem
{
  static class = 'ProfileEmailItem'

  render() {
    const { user, editMode } = this.props
    return [
      new Icon('envelope_outline'),
      editMode ?
        new Button(user.email || 'Ваша почта') :
        new HtmlA({
          href : 'mailto:' + user.email,
          children : user.email,
        }),
      new ProfileEmailDialog({
        user,
        modal : true,
        open : this.state.expanded,
        oncancel : () => {
          this.setState({ expanded : false })
        },
        onload : () => {
          this.setState({ expanded : false })
        },
      }),
    ]
  }
}
