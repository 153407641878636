import { InfoObjectDialog } from './InfoObjectDialog.js'
import { InfoPlaceDetailsPanel } from './InfoPlaceDetailsPanel.js'
import { InfoPlaceAboutPanel } from './InfoPlaceAboutPanel.js'
import api from './api.js'
import './InfoPlaceDialog.css'

export class InfoPlaceDialog extends InfoObjectDialog
{
  static class = 'InfoPlaceDialog'

  static defaultProps = {
    objectPropName : 'place',
  }

  static sections = [
    {
      value : 'details',
      title : 'Справка',
      panel : InfoPlaceDetailsPanel,
    },
    {
      value : 'about',
      title : 'Описание',
      panel : InfoPlaceAboutPanel,
    },
  ]

  async _load() {
    const placeId = this.props.objectId
    if(!placeId) {
      return
    }
    if(this.state.place) {
      this.setState({ place : undefined })
    }
    this.setState({
      place : await api.getPlace(placeId),
    })
  }
}
