import lodash from 'lodash'
import { DialogContent, DialogHead, DialogBody, Heading, Button, ListItem, List } from 'xuick'
import { Icon } from './Icon.js'
import { ProfileItemDialog } from './ProfileItemDialog.js'
import { HeadBackButton } from './HeadBackButton.js'
import { TagBox } from './TagBox.js'
import api from './api.js'

export class ProfileTagDialog extends ProfileItemDialog
{
  static class = 'ProfileTagDialog'

  init() {
    super.init()
    this.on('change', this.#onChange)
  }

  render() {
    const user = this.props.user
    const state = this.state
    return new DialogContent([
      new DialogHead([
        new HeadBackButton({
          disabled : state.busy,
        }),
        new Heading('Выбрать теги'),
      ]),
      new DialogBody([
        this._tagBox = new TagBox({
          disabled : state.busy,
          excludeIds : lodash.map(user.tags, 'id'),
        }),
        new List(user.tags.map(item => {
          return new ListItem({
            key : item.id,
            children : [
              item.name,
              new Button({
                value : item.id,
                disabled : state.busy,
                onclick : this.#onButtonClick,
                children : state.busy ?
                  new Icon('bin_outline_dim') :
                  new Icon('bin_outline'),
              }),
            ],
          })
        })),
      ]),
    ])
  }

  async #updateUserTags(tags) {
    this.setState({ busy : true })
    try {
      await api.updateUser({
        tags : lodash.map(tags, 'id'),
      })
      this._tagBox.reset()
    }
    finally {
      this.setState({ busy : false })
    }
  }

  #onChange(e) {
    const item = e.target.item
    if(!item) {
      return
    }
    void this.#updateUserTags([
      ...this.props.user.tags,
      item,
    ])
  }

  #onButtonClick = e => {
    const button = e.target.closest(Button)
    const tags = this.props.user.tags.filter(
      item => item.id !== button.value,
    )
    void this.#updateUserTags(tags)
  }
}
