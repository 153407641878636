import { DateTime, Interval } from 'luxon'
import { ComboBox, Label, Control } from 'xuick'
import { TripDateDialog } from './TripDateDialog'
import './TripDateBox.css'

export class TripDateBox extends ComboBox
{
  static class = 'TripDateBox'

  static defaultProps = {
    hasPopup : 'dialog',
    format : DateTime.DATE_FULL,
  }

  render() {
    const props = this.props
    const interval = props.value && Interval.fromISO(props.value)
    const text = interval?.toLocaleString(props.format)
    return [
      props.label && new Label({
        key : 'label',
        children : props.label,
      }),
      new Control({
        key : 'control',
        children : text || props.text || ' ',
      }),
      this._dialog = new TripDateDialog({
        key : 'dialog',
        anchor : this,
        open : this.state.expanded,
        interval : props.value,
        duration : props.duration,
        onsubmit : this.#onDialogSubmit,
      }),
    ]
  }

  reset() {
    this.value = undefined
    this._dialog.reset()
  }

  #onDialogSubmit = e => {
    this.value = e.target.value
    this.state.expanded = false
    this.emit('change')
  }
}
