import { TabPanel, Heading, Group, Link, Status } from 'xuick'
import { LoadingIndicator } from './LoadingIndicator'
import { UserPhoto } from './UserPhoto'
import api from './api'
import './InfoLocalityPeoplePanel.css'

export class InfoLocalityPeoplePanel extends TabPanel
{
  static class = 'InfoLocalityPeoplePanel'

  state = {
    users : undefined,
  }

  init() {
    super.init()
    if(!this.props.hidden) {
      void this.#load()
    }
  }

  render() {
    if(this.props.hidden) {
      return
    }
    const users = this.state.users
    return [
      new Heading(`Сейчас в городе`),
      users ?
        users.length ?
          new Group(users.map(
            user => new Link({
              to : '/users/' + user.id,
              children : new UserPhoto({ user }),
            }),
          )) :
          new Status('Никого нет') :
        new LoadingIndicator,
    ]
  }

  update(prevProps, prevState) {
    super.update(prevProps, prevState)
    if(this.props.hidden || !prevProps.hidden) {
      return
    }
    if(!this.state.users) {
      void this.#load()
    }
  }

  async #load(items = []) {
    const users = await api.getLocalityVisitors(this.props.locality.id, {
      limit : 20,
      offset : items.length,
    })
    this.setState({
      users : [...items, ...users],
    })
  }
}
