import {
  Class,
  Form,
  DialogContent,
  DialogHead,
  DialogBody,
  Heading,
  Button,
  TextBox,
  Block,
} from 'xuick'
import { ProfileItemDialog } from './ProfileItemDialog.js'
import { HeadBackButton } from './HeadBackButton.js'
import api from './api.js'

export class ProfileEmailDialog extends ProfileItemDialog
{
  static class = 'ProfileEmailDialog'

  state = {
    ...this.state,
    busy : false,
    email : null,
    code : null,
    step : 0,
  }

  init() {
    super.init()
    this.on('cancel', this.onCancel)
    this.on('submit', this.onSubmit)
  }

  render() {
    const user = this.props.user
    const state = this.state
    return new Form({
      classList : Class.generate(DialogContent),
      children : [
        new DialogHead([
          new HeadBackButton({
            disabled : state.busy,
          }),
          new Heading('Ваша почта'),
        ]),
        new DialogBody([
          new Block([
            this._emailBox = new TextBox({
              label : 'Адрес электронной почты',
              value : state.email ?? user.email,
              inputMode : 'email',
              enterKeyHint : 'done',
              disabled : state.busy,
              readOnly : !!state.step,
              oninput : e => {
                this.setState({ email : e.target.value })
              },
            }),
            this._codeBox = new TextBox({
              label : 'Код из письма',
              value : this.state.code ?? '',
              inputMode : 'numeric',
              enterKeyHint : 'done',
              disabled : state.busy,
              hidden : !state.step,
              oninput : e => {
                this.setState({ code : e.target.value })
              },
            }),
          ]),
          new Button({
            label : 'Сохранить',
            type : 'submit',
            classList : 'accent',
            disabled : state.busy || !state.email || state.email === user.email,
          }),
        ]),
      ],
    })
  }

  onCancel() {
    this.setState({
      email : null,
      code : null,
      step : 0,
    })
  }

  async onSubmit() {
    const step = this.state.step
    const email = this.state.email.trim()
    if(!email || email === this.props.user.email) {
      return
    }
    this.setState({ busy : true })
    try {
      if(step === 0) {
        await api.updateUserEmail({ email })
        this.setState({
          busy : false,
          step : 1,
        })
        this._codeBox.focus()
        return
      }
      if(step === 1) {
        await api.updateUserEmail({
          email,
          code : this.state.code,
        })
        this.setState({
          busy : false,
          email : null,
          code : null,
          step : 0,
        })
        this.emit('load')
      }
    }
    catch(err) {
      this.setState({ busy : false })
      if(step) {
        this._codeBox.focus()
      }
      else this._emailBox.focus()
      throw err
    }
  }
}
