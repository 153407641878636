import './mobile.css'
import { Settings } from 'luxon'
import { ElemType } from 'xuick'
import { MobileApp } from './MobileApp'
import params from './params.js'

Settings.defaultLocale = params.lang
ElemType.setDebugMode(params.env.NODE_ENV === 'development')

const render = app => {
  MobileApp.destroy(app)
  return MobileApp.render({}, document.body)
}

let app = render()

if(module.hot) {
  module.hot.accept(['./MobileApp'], () => app = render(app))
}
