import { Radio, Class, Button, ButtonControl } from 'xuick'

export class TripsFilterRadio extends Radio
{
  static className = Class.generate(Button)

  render() {
    return new ButtonControl(this.props.text)
  }
}
