import { CancelButton } from 'xuick'
import { Icon } from './Icon'

export class HeadCancelButton extends CancelButton
{
  static class = 'HeadCancelButton'

  static defaultProps = {
    tabIndex : -1,
  }

  render() {
    return new Icon('close_filled')
  }
}
