import { Option, Span, CountryFlag } from 'xuick'
import './CountryOption.css'

export class CountryOption extends Option
{
  static class = 'CountryOption'

  render() {
    const item = this.props.item
    const code = item.country?.code || item.code
    const src = item.country?.flag || item.flag
    return [
      new CountryFlag({ code, src }),
      new Span(item.name),
    ]
  }
}
