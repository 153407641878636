module.exports = {
  'accounting' : 'accounting_outline',
  'adult store' : '18-plus_outline',
  'air sports' : 'parachute_outline',
  'airport' : 'airport_outline',
  'amusement park' : 'theme-park_outline',
  'antique store' : 'kettle_outline',
  'apartment' : 'apartment_outline',
  'aquarium' : 'rectangular-aquarium_outline',
  'art gallery' : 'image-gallery_outline',
  'atm' : 'insert-credit-card_outline',
  'bakery' : 'bakery_outline',
  'bank' : 'bank-building_outline',
  'bar' : 'cocktail_outline',
  'beach' : 'beach-umbrella_outline',
  'beauty salon' : 'makeup-brush_outline',
  'bed and breakfast' : 'restaurant-building_outline',
  'bicycle rental' : 'bicycle_outline',
  'bicycle store' : 'bicycle_outline',
  'bike path' : 'cycling_outline',
  'book store' : 'book_outline',
  'bowling alley' : 'bowling_outline',
  'building' : 'pagoda_outline',
  'bus station' : 'shuttle-bus_outline',
  'cafe' : 'coffee-cup_outline',
  'camping' : 'camping-tent_outline',
  'car dealer' : 'car-dealership-building_outline',
  'car rental' : 'car-rental_outline',
  'car service' : 'car-service_outline',
  'car wash' : 'automatic-car-wash_outline',
  'carnival' : 'comedy_outline',
  'casino' : 'casino_outline',
  'cave' : 'cave_outline',
  'cemetery' : 'cemetery_outline',
  'church' : 'church_outline',
  'circus' : 'elephant-circus_outline',
  'city hall' : 'city-hall_outline',
  'clinic' : 'hospital_outline',
  'clothing store' : 'clothes_outline',
  'college and university' : 'university_outline',
  'concert hall' : 'concert_outline',
  'convenience store' : 'grocery-store_outline',
  'courthouse' : 'courthouse_outline',
  'dance club' : 'party_outline',
  'dentist' : 'tooth_outline',
  'department store' : 'department-shop_outline',
  'dining room' : 'buffet-breakfast_outline',
  'drugstore' : 'pharmacy-shop_outline',
  'electrician' : 'electrical_outline',
  'electronics store' : 'device-shop_outline',
  'embassy' : 'embassy_outline',
  'entertainment' : '3d-glasses_regular',
  'entertainment show' : 'public-speaking_outline',
  'equestrian sports' : 'equestrian_outline',
  'establishment' : 'small-business_outline',
  'events' : 'music-conductor_regular',
  'exhibition' : 'exhibition_outline',
  'fast food' : 'salami-pizza_outline',
  'festival' : 'confetti_outline',
  'finance' : 'investment_outline',
  'fire station' : 'fire-station_outline',
  'fishing store' : 'sports-fishing_outline',
  'florist' : 'tulip_outline',
  'food and drink' : 'food_regular',
  'fruit and vegetable store' : 'fruit-bag_outline',
  'funeral home' : 'coffin-face_outline',
  'furniture store' : 'furniture-store_outline',
  'gaming cafe' : 'game-controller_outline',
  'garden' : 'hand-planting_outline',
  'gas station' : 'gas-station_outline',
  'general contractor' : 'engineer_outline',
  'guest house' : 'house_outline',
  'gym and studio' : 'deadlift_outline',
  'hair care' : 'barbershop_outline',
  'hardware store' : 'shopping-bag_regular',
  'health and beauty' : 'doctors-bag_regular',
  'health food store' : 'organic-food_outline',
  'hindu temple' : 'hindu-swastik_outline',
  'historical place' : 'archeology_outline',
  'home goods store' : 'shopping-bag_regular',
  'hospital' : 'doctors-bag_regular',
  'hostel' : 'hostel_outline',
  'hotel' : 'city-buildings_outline',
  'insurance agency' : 'insurance-agent_outline',
  'jewelry store' : 'jewelry_outline',
  'lake' : 'lake_outline',
  'language school' : 'language_outline',
  'laundry' : 'clothes-in-laundry_outline',
  'lawyer' : 'law_outline',
  'library' : 'library-building_outline',
  'light rail station' : 'city-railway-station_outline',
  'liquor store' : 'liquor-shelf_outline',
  'local government office' : 'department_outline',
  'locksmith' : 'wrench_outline',
  'lodging' : 'occupied-bed_outline',
  'marketplace' : 'fast-moving-consumer-goods_outline',
  'massage' : 'massage_outline',
  'meal delivery' : 'deliver-food_outline',
  'meal takeaway' : 'take-away-food_outline',
  'monument' : 'lion-statue_outline',
  'mosque' : 'mosque_outline',
  'mountain' : 'mountain_outline',
  'mountain sports' : 'climber_outline',
  'movie rental' : 'movie_outline',
  'movie theater' : 'film-reel_outline',
  'moving company' : 'heavy_outline',
  'museum' : 'museum_outline',
  'natural feature' : 'earth-element_regular',
  'nature preserve' : 'forest_outline',
  'neighborhood' : null,
  'night club' : 'swing_outline',
  'observation deck' : 'point-of-interest_outline',
  'packaging store' : 'cardboard-box_outline',
  'painter' : 'paint-palette-with-brush_outline',
  'park' : 'national-park_outline',
  'parking' : 'parking_outline',
  'pet store' : 'dog-paw_outline',
  'pharmacy' : 'pharmacy-shop_outline',
  'physiotherapist' : 'physical-therapy_outline',
  'place of worship' : 'pray_outline',
  'places to visit' : 'vintage-camera_regular',
  'plumber' : 'water-pipe_outline',
  'point of interest' : 'collect_outline',
  'police station' : 'police-station_outline',
  'political' : 'user-groups_outline',
  'post office' : 'post-office_outline',
  'preschool' : 'preschool_outline',
  'primary school' : 'school_outline',
  'railway station' : 'railway-station_outline',
  'real estate agency' : 'real-estate-agent_outline',
  'religious place' : 'pray_outline',
  'restaurant' : 'restaurant_outline',
  'river' : 'sailboat_outline',
  'roofing contractor' : 'roofing_outline',
  'running track' : 'trekking_outline',
  'rv park' : 'camper_outline',
  'school' : 'school_outline',
  'secondary school' : 'school_outline',
  'services' : 'insert-card_outline',
  'shoe store' : 'footwear_outline',
  'shopping' : 'shopping-bag_regular',
  'shopping mall' : 'shopping-mall_outline',
  'spa' : 'spa_outline',
  'sport' : 'soccer_regular',
  'sports event' : 'basketball-field_outline',
  'stadium' : 'stadium_outline',
  'state institution' : 'user-groups_outline',
  'storage' : 'storage_outline',
  'store' : 'grocery-store_outline',
  'street food' : 'food-truck_outline',
  'strip club' : 'panties_outline',
  'subway station' : 'underground_outline',
  'supermarket' : 'shopping-bag_regular',
  'synagogue' : 'synagogue_outline',
  'taxi stand' : 'cab-stand_outline',
  'tea room' : 'tea-pair_outline',
  'theatre' : 'theatre-mask_outline',
  'tourist information' : 'geography-document_outline',
  'town square' : 'rounded-square_outline',
  'toy game store' : 'steam-engine_outline',
  'transit station' : 'public-transportation_outline',
  'transportation' : 'suv_outline',
  'travel agency' : 'combi-ticket_outline',
  'vegan vegetarian cafe' : 'natural-food_regular',
  'veterinary care' : 'veterinarian-male_outline',
  'villas and houses' : 'country-house_outline',
  'volcano' : 'volcano_outline',
  'water park' : 'water-park_outline',
  'water sport' : 'swimming_outline',
  'waterfall' : 'waterfall_outline',
  'yoga studio' : 'yoga_outline',
  'zoo' : 'alligator_outline',
}
