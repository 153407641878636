import { TabPanel, Article } from 'xuick'
import './InfoObjectAboutPanel.css'

export class InfoObjectAboutPanel extends TabPanel
{
  static class = 'InfoObjectAboutPanel'

  render() {
    const props = this.props
    if(props.hidden) {
      return
    }
    return new Article({
      innerHTML : props.object.info || 'Нет данных',
    })
  }
}
