import { Screen } from './Screen'
import { Err404Main } from './Err404Main'
import { MobileMenu } from './MobileMenu'
import { MobileHeader } from './MobileHeader'
import './Err404Screen.css'

export class Err404Screen extends Screen
{
  static class = 'Err404Screen'

  render() {
    return [
      new MobileMenu,
      new MobileHeader({
        titleText : 'Страница не найдена',
      }),
      new Err404Main({ key : '404' }),
    ]
  }
}
