import { RoleImg } from 'xuick'
import { Icon } from './Icon.js'
import params from './params.js'
import './ProfilePhoto.css'

export class ProfilePhoto extends RoleImg
{
  static class = 'ProfilePhoto'

  render() {
    const photo = this.props.user.photos?.[0]
    if(!photo) {
      return new Icon('unsplash_metro_dim')
    }
    const url = `${ params.env.S3_BUCKET_URL }/${ photo.image }.webp`
    this.style = {
      backgroundImage : `url(${ url })`,
    }
    return null
  }
}
