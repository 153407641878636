import AppInterface from './AppInterface'
import params from './params'

const api = new AppInterface({
  logging : params.env.NODE_ENV === 'development',
  methods : params.methods,
})

api.user = params.user

if(api.user) {
  api.connect()
}

api.on('info', info => {
  const version = localStorage.getItem('version')
  if(version === info.version) {
    return
  }
  localStorage.setItem('version', info.version)
  if(version) {
    location.reload()
  }
})

api.on('reload', () => location.reload())

if(params.env.NODE_ENV === 'development') {
  let now
  api.on('info', info => {
    if(now) {
      location.reload()
    }
    else now = info.now
  })
  api.on('error', console.error)
  api.on('server_error', console.error)
}

if(module.hot) {
  module.hot.dispose(() => api.off())
}

export default api
