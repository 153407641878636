import { HtmlDiv } from 'xuick'
import { UserStatusDialog } from './UserStatusDialog.js'
import api from './api.js'
import './UserStatus.css'

export class UserStatus extends HtmlDiv
{
  static class = 'UserStatus'

  state = {
    expanded : false,
  }

  init() {
    super.init()
    this.on('click', this.#onClick)
  }

  render() {
    const user = this.props.user
    return [
      new HtmlDiv(user.statusText || 'Введите статус'),
      new UserStatusDialog({
        user,
        open : this.state.expanded,
        oncancel : () => {
          this.setState({ expanded : false })
        },
        onload : () => {
          this.setState({ expanded : false })
        },
      }),
    ]
  }

  #onClick() {
    if(this.props.user.id === api.user.id) {
      this.setState({ expanded : true })
    }
  }
}
