import { HtmlDiv, HtmlArticle } from 'xuick'
import { MapTripBlock } from './MapTripBlock.js'
import { MapPlaceCard } from './MapPlaceCard.js'
import { MapRouteCard } from './MapRouteCard.js'
import { MapTripCard } from './MapTripCard.js'
import './MapCardBlock.css'

export class MapCardBlock extends HtmlDiv
{
  static class = 'MapCardBlock'

  init() {
    this.on('click', this.#onClick)
  }

  render() {
    const props = this.props
    const { place, route, trip } = props
    const children = []
    if(trip) {
      children.push(new MapTripBlock({ trip, route }))
    }
    if(place) {
      return [
        ...children,
        new MapPlaceCard({
          key : place.id,
          route,
          places : props.places,
          placeId : place.id,
          position : props.position,
        }),
      ]
    }
    if(trip && route) {
      return [
        ...children,
        new MapRouteCard({ trip, route }),
      ]
    }
    if(trip) {
      return [
        ...children,
        new MapTripCard({ trip }),
      ]
    }
    this.hidden = true
  }

  #onClick(e) {
    e.stopPropagation()
  }

  get cardHeight() {
    const article = this.find(HtmlArticle)
    return article?.node.offsetHeight || 0
  }
}
