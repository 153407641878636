import { HtmlHeader, Button, Heading } from 'xuick'
import { Icon } from './Icon'
import { HeaderLogoLink } from './HeaderLogoLink.js'
import { SettingsDialog } from './SettingsDialog'
import './MobileHeader.css'

export class MobileHeader extends HtmlHeader
{
  static class = 'MobileHeader'

  render() {
    const props = this.props
    return [
      new HeaderLogoLink,
      new Heading(props.titleText),
      new Button({
        children : new Icon('settings_outline'),
        dialog : SettingsDialog,
      }),
    ]
  }
}
