import { HtmlDiv } from 'xuick'
import './MapLocationMarker.css'

export class MapLocationMarker extends HtmlDiv
{
  static class = 'MapLocationMarker'

  #view

  mount() {
    const { AdvancedMarkerView } = google.maps.marker
    const props = this.props
    this.#view = new AdvancedMarkerView({
      map : props.map,
      position : props.position,
      content : this.node,
    })
  }

  remove() {
    this.#view.map = null
  }

  setPosition(position) {
    this.#view.position = position
  }
}
