import { TabList } from 'xuick'
import { MapApplication } from './MapApplication.js'
import { TripDateTab } from './TripDateTab'
import './TripDateList.css'

export class TripDateList extends TabList
{
  static class = 'TripDateList'

  date

  #x = 0

  init() {
    super.init()
    this.on('click', this.#onClick)
  }

  render() {
    const props = this.props
    const routes = props.trip.routes
    return routes.map((route, i) => {
      const color = MapApplication.getRouteColorByIndex(i)
      return new TripDateTab({
        route,
        key : route.id,
        date : route.date,
        number : i + 1,
        total : routes.length,
        selected : route.date === props.date,
        style : `--route-color: ${ color };`,
      })
    })
  }

  async mount() {
    super.mount()
    await new Promise(setTimeout)
    this.style.transition = 'none'
    this.updatePosition()
  }

  update(prevProps, prevState) {
    super.update(prevProps, prevState)
    if(this.props.date !== prevProps.date) {
      this.style.transition = null
      this.updatePosition()
    }
  }

  updatePosition() {
    const date = this.props.date
    const tab = this.find(TripDateTab, tab => {
      return tab.date === date
    })
    this.x = this.#paddingLeft - tab.node.offsetLeft
  }

  #onClick(e) {
    const item = e.target.closest(TripDateTab)
    if(!item || item.date === this.date) {
      return
    }
    this.date = item.date
    this.emit('change')
  }

  get x() {
    return this.#x
  }

  set x(offset) {
    this.#x = offset
    this.style.transform = `translateX(${ offset }px)`
  }

  get #paddingLeft() {
    const style = getComputedStyle(this.node)
    return parseInt(style.paddingLeft) || 0
  }

  get step() {
    const tabs = this.findAll(TripDateTab)
    return tabs[1] ?
      tabs[1].node.offsetLeft - tabs[0].node.offsetLeft :
      tabs[0].node.offsetWidth
  }
}
