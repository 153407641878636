import { RoleListItem, Button } from 'xuick'
import './TripServicesItem.css'

export class TripServicesItem extends RoleListItem
{
  static class = 'TripServicesItem'

  render() {
    const props = this.props
    return [
      props.flight && new Button({
        label : 'Смотреть билет',
      }),
      props.taxi && new Button({
        label : 'Заказать такси',
      }),
    ]
  }
}
