import {
  Class,
  RoleForm,
  DialogContent,
  DialogHead,
  DialogBody,
  Heading,
  Button,
  Img,
  Block,
} from 'xuick'
import { LoadingIndicator } from './LoadingIndicator.js'
import { ProfileItemDialog } from './ProfileItemDialog.js'
import { HeadBackButton } from './HeadBackButton.js'
import { ProfilePhotoSelectDialog } from './ProfilePhotoSelectDialog.js'
import api from './api.js'
import './ProfilePhotoDialog.css'

export class ProfilePhotoDialog extends ProfileItemDialog
{
  static class = 'ProfilePhotoDialog'

  static defaultProps = {
    direction : 'right',
  }

  state = {
    ...this.state,
    dialog : false,
    busy : false,
    file : null,
  }

  init() {
    super.init()
    this.on('cancel', this.#onCancel)
    this.on('submit', this.#onSubmit)
  }

  render() {
    const state = this.state
    this.busy = state.busy
    return new RoleForm({
      classList : Class.generate(DialogContent),
      children : [
        new DialogHead([
          new HeadBackButton({
            disabled : state.busy,
          }),
          new Heading('Добавить фото'),
        ]),
        new DialogBody([
          new Block(
            state.busy ?
              new LoadingIndicator :
              state.file && new Img({
                src : URL.createObjectURL(state.file),
              }),
          ),
          new Button({
            label : 'Загрузить',
            classList : 'accent',
            type : 'submit',
            hidden : !state.file,
            disabled : state.busy,
          }),
        ]),
        new ProfilePhotoSelectDialog({
          open : state.dialog,
          oncancel : e => {
            this.setState({ dialog : false })
            this.cancel(e.nativeEvent)
          },
          onchange : e => {
            this.setState({
              file : e.target.file,
              dialog : false,
            })
          },
        }),
      ],
    })
  }

  update(prevProps, prevState) {
    super.update(prevProps, prevState)
    if(!prevProps.open && this.props.open) {
      this.setState({ dialog : true })
    }
  }

  #onCancel(e) {
    if(this.state.busy === true) {
      e.stopImmediatePropagation()
      return
    }
    this.setState({ file : null })
  }

  async #onSubmit() {
    const file = this.state.file
    if(!file) {
      return
    }
    this.setState({ busy : true })
    try {
      await api.updateUserPhoto(await file.arrayBuffer())
      this.setState({
        busy : false,
        file : null,
      })
      this.emit('load')
    }
    catch(err) {
      this.setState({ busy : false })
      throw err
    }
  }
}
