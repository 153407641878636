import wait from 'wait'
import lodash from 'lodash'
import { Id, Form, MainBlock, FootBlock, Button, ValidationAlert } from 'xuick'
import { Trip } from '../common/Trip.js'
import { NomadLogo } from './NomadLogo.js'
import { LocalityBox } from './adaptive/LocalityBox.js'
import { TripDateBox } from './TripDateBox.js'
import { PlaceCategoryDialog } from './PlaceCategoryDialog.js'
import geolocation from './geolocation.js'
import api from './api.js'
import './TripForm.css'

const keywords = lodash.map(Trip.keywords, 'keyword')

export class TripForm extends Form
{
  static class = 'TripForm'

  state = {
    destinationId : undefined,
    interval : undefined,
    invalid : null,
    message : null,
    expanded : false,
    location : undefined,
    stats : undefined,
  }

  trip

  #alertId

  init() {
    super.init()
    this.on('submit', this.#onSubmit)
    void this.#getLocation()
  }

  async #getLocation() {
    const { coords } = await geolocation.getCurrentPosition()
    this.setState({
      location : {
        lat : coords.latitude,
        lng : coords.longitude,
      },
    })
  }

  render() {
    const { props, state } = this
    const destinationId = state.destinationId || props.destinationId
    this.#alertId ??= Id.generate()
    return [
      new NomadLogo({ classList : 'white' }),
      new MainBlock([
        this._destinationBox = new LocalityBox({
          label : 'Куда?',
          placeholder : 'Город',
          invalid : state.invalid === this._destinationBox,
          errorMessage : this.#alertId,
          required : true,
          value : destinationId,
          location : this.state.location,
          publicOnly : true,
          onchange : this.#onDestinationBoxChange,
          onclick : () => {
            this.setState({ invalid : null })
          },
        }),
        this._dateBox = new TripDateBox({
          label : 'Когда?',
          text : 'Выберите даты',
          invalid : state.invalid === this._dateBox,
          errorMessage : this.#alertId,
          required : true,
          type : 'range',
          popup : {
            modal : true,
          },
          value : state.interval,
          onchange : e => {
            this.setState({
              interval : e.target.value,
              invalid : null,
            })
          },
          onclick : () => {
            this.setState({ invalid : null })
          },
        }),
      ]),
      new FootBlock([
        new Button({
          label : 'Пропустить',
          type : 'cancel',
          classList : 'dim',
          onclick : () => {
            this.emit('cancel')
          },
        }),
        this._submitButton = new Button({
          label : 'Далее',
          type : 'submit',
          classList : 'accent',
        }),
      ]),
      new PlaceCategoryDialog({
        open : state.expanded,
        anchor : this._submitButton,
        destinationId,
        interval : state.interval,
        maxPlaces : props.maxPlaces,
        maxDistance : props.maxDistance,
        travelMode : props.travelMode,
        stats : state.stats,
        oncancel : this.#onDialogCancel,
        onload : this.#onDialogLoad,
        onclick : this.#onDialogClick,
      }),
      new ValidationAlert({
        id : this.#alertId,
        open : !!state.invalid,
        anchor : state.invalid,
        text : state.message,
        oncancel : () => {
          this.setState({ invalid : false })
        },
      }),
    ]
  }

  #onDestinationBoxChange = async e => {
    const localityId = e.target.value
    this.setState({
      destinationId : localityId,
      invalid : null,
      stats : undefined,
    })
    if(!localityId) {
      return
    }
    this.setState({
      stats : await api.getCategoryStats({
        localityId,
        keywords,
      }),
    })
  }

  #onDialogCancel = e => {
    if(!e.target.busy) {
      this.setState({ expanded : false })
    }
  }

  async #onSubmit(e) {
    const { props, state } = this
    const destinationId = state.destinationId || props.destinationId
    if(!destinationId) {
      e.stopPropagation()
      this.setState({
        invalid : this._destinationBox,
        message : 'Необходимо указать город',
      })
      return
    }
    if(!state.interval) {
      e.stopPropagation()
      this.setState({
        invalid : this._dateBox,
        message : 'Необходимо указать даты поездки',
      })
      return
    }
    this.setState({ expanded : true })
  }

  #onDialogLoad = async e => {
    this.trip = e.target.trip
    this.emit('load')
    await wait(500)
    this._destinationBox.reset()
    this._dateBox.reset()
    this.setState({
      destinationId : undefined,
      interval : undefined,
      expanded : false,
    })
  }

  #onDialogClick = e => {
    const button = e.target?.closest(Button)
    if(button?.type === 'settings') {
      this.emit('settings-click', { bubbles : true })
    }
  }
}
