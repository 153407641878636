import { PlaceActionButton } from './PlaceActionButton'
import geolocation from './geolocation'

const GOOGLE_DIR_API_VERSION = 1
const GOOGLE_DIR_URL = 'https://www.google.com/maps/dir/'

export class PlaceDirectionsButton extends PlaceActionButton
{
  static defaultProps = {
    icon : 'gps_outline_white',
  }

  state = {
    ...this.state,
    position : undefined,
  }

  async init() {
    super.init()
    if(this.props.position) {
      return
    }
    try {
      this.setState({
        position : await geolocation.getCurrentPosition(),
      })
    }
    catch {
      this.setState({ position : null })
    }
  }

  assign() {
    super.assign()
    const props = this.props
    const position = props.position || this.state.position
    const coords = position?.coords
    const [lng, lat] = props.place.geometry.coordinates
    if(!coords) {
      this.href = `geo:${ [lat, lng] }`
      return
    }
    const url = new URL(GOOGLE_DIR_URL)
    const search = new URLSearchParams({
      api : GOOGLE_DIR_API_VERSION,
      origin : [coords.latitude, coords.longitude],
      destination : [lat, lng],
    })
    this.href = Object.assign(url, { search })
    this.target = '_blank'
  }
}
