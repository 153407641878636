const lodash = require('lodash')
const props = require('dbadmin/lib/DataModel.props')

const origin = typeof process === 'undefined' ?
  location.origin :
  process.env.ORIGIN

module.exports = {
  //////////////// main ////////////////
  id : {
    ...props.id,
  },
  photo : {
    name : 'photo',
    title : 'Фото',
    type : 'photo',
    internal : true,
    get() {
      const photo = this.getDataValue('photo')
      if(!photo) {
        return null
      }
      const { m, s } = photo
      return {
        m : m.startsWith('/') ? origin + m : m,
        s : s.startsWith('/') ? origin + s : s,
      }
    },
  },
  photos : {
    name : 'photos',
    title : 'Фото',
    type : 'reference',
    model : 'Photo',
    multiple : true,
    targetColumn : 'user',
    order : [['number', 'ASC']],
    include : true,
  },
  name : {
    name : 'name',
    title : 'Имя',
    type : 'string',
    required : true,
    search : true,
    include : true,
    public : true,
    validate : {
      len : [2, 200],
    },
  },
  role : {
    name : 'role',
    title : 'Роль',
    type : 'enum',
    filter : true,
    hidden : true,
    enum : [
      {
        value : 'admin',
        title : 'Администратор',
        abbr : 'Админ',
      },
      {
        value : 'master',
        title : 'Разработчик',
        abbr : 'Мастер',
      },
    ],
  },
  //////////////// contacts ////////////////
  email : {
    name : 'email',
    title : 'Электронная почта',
    abbr : 'Email',
    type : 'email',
    search : true,
    unique : 'User_email',
    validate : {
      isEmail : true,
    },
  },
  phoneNumber : {
    name : 'phoneNumber',
    title : 'Телефон',
    abbr : 'Тел.',
    type : 'tel',
    search : true,
    hidden : true,
    unique : 'User_phoneNumber',
    internal : true,
    validate : {
      isPhoneNumber : true,
    },
  },
  googleId : {
    name : 'googleId',
    title : 'Google ID',
    abbr : 'GGL',
    type : 'string',
    unique : 'User_googleId',
    search : true,
    hidden : true,
    readOnly : true,
    validate : {
      is : /^\d+$/.source,
    },
  },
  facebookId : {
    name : 'facebookId',
    title : 'Facebook ID',
    abbr : 'FB',
    type : 'string',
    unique : 'User_facebookId',
    search : true,
    hidden : true,
    readOnly : true,
    validate : {
      is : /^\d+$/.source,
    },
  },
  vkId : {
    name : 'vkId',
    title : 'VK ID',
    abbr : 'VK',
    type : 'string',
    unique : 'User_vkId',
    search : true,
    hidden : true,
    readOnly : true,
    validate : {
      is : /^\d+$/.source,
    },
  },
  site : {
    name : 'site',
    title : 'Сайт',
    type : 'url',
    search : true,
    hidden : true,
    validate : {
      isUrl : true,
    },
  },
  //////////////// settings ////////////////
  online : {
    name : 'online',
    title : 'Онлайн',
    type : 'boolean',
    required : true,
    defaultValue : false,
    readOnly : true,
    filter : true,
  },
  showGeolocation : {
    name : 'showGeolocation',
    title : 'Показывать геопозицию',
    type : 'boolean',
    required : true,
    defaultValue : false,
    filter : true,
    hidden : true,
  },
  showStatusTo : {
    name : 'showStatusTo',
    title : 'Показывать статус',
    abbr : 'Статус',
    type : 'enum',
    required : true,
    defaultValue : 'everyone',
    filter : true,
    hidden : true,
    enum : [
      {
        value : 'user',
        title : 'Только я',
      },
      {
        value : 'contacts',
        title : 'Контакты',
      },
      {
        value : 'everyone',
        title : 'Все',
      },
    ],
  },
  showProfileTo : {
    name : 'showProfileTo',
    title : 'Показывать сведения',
    abbr : 'Сведения',
    type : 'enum',
    required : true,
    defaultValue : 'everyone',
    filter : true,
    hidden : true,
    enum : [
      {
        value : 'user',
        title : 'Только я',
      },
      {
        value : 'contacts',
        title : 'Контакты',
      },
      {
        value : 'everyone',
        title : 'Все',
      },
    ],
  },
  allowSearchName : {
    name : 'allowSearchName',
    title : 'Имя доступно в поиске',
    abbr : 'Имя в поиске',
    type : 'boolean',
    required : true,
    defaultValue : true,
    filter : true,
    hidden : true,
  },
  allowSearchProfile : {
    name : 'allowSearchProfile',
    title : 'Сведения доступны в поиске',
    abbr : 'Сведения в поиске',
    type : 'boolean',
    required : true,
    defaultValue : true,
    filter : true,
    hidden : true,
  },
  allowSearchTags : {
    name : 'allowSearchTags',
    title : 'Теги доступны в поиске',
    abbr : 'Теги в поиске',
    type : 'boolean',
    required : true,
    defaultValue : true,
    filter : true,
    hidden : true,
  },
  suspended : {
    name : 'suspended',
    title : 'Заблокирован',
    abbr : 'Бан',
    type : 'boolean',
    required : true,
    defaultValue : false,
    filter : true,
    hidden : true,
  },
  //////////////// references ////////////////
  languages : {
    name : 'languages',
    title : 'Языки',
    type : 'reference',
    model : 'Language',
    multiple : true,
    through : 'UserLanguage',
    targetColumn : 'users',
    filter : true,
    hidden : true,
  },
  countries : {
    name : 'countries',
    title : 'Страны',
    type : 'reference',
    model : 'Place',
    multiple : true,
    targetColumn : 'visitors',
    through : 'UserCountry',
    filter : true,
    hidden : true,
  },
  /*placeOfBirth : {
    name : 'placeOfBirth',
    title : 'Родной город',
    type : 'reference',
    model : 'Place',
    targetColumn : 'children',
    filter : true,
    hidden : true,
  },*/
  placeOfStay : {
    name : 'placeOfStay',
    title : 'Текущий город',
    abbr : 'Город',
    type : 'reference',
    model : 'Place',
    targetColumn : 'people',
    filter : true,
    hidden : true,
    refTypes : ['locality'],
  },
  trips : {
    name : 'trips',
    title : 'Поездки',
    type : 'reference',
    model : 'Trip',
    targetColumn : 'creator',
    multiple : true,
    hidden : true,
  },
  tags : {
    name : 'tags',
    title : 'Теги',
    type : 'reference',
    model : 'Tag',
    through : 'UserTag',
    multiple : true,
    targetColumn : 'users',
    hidden : true,
  },
  following : {
    name : 'following',
    title : 'Подписки',
    type : 'reference',
    model : 'User',
    through : 'Relationship',
    targetColumn : 'followers',
    multiple : true,
    count : true,
    filter : true,
    hidden : true,
  },
  followers : {
    name : 'followers',
    title : 'Подписчики',
    type : 'reference',
    model : 'User',
    through : 'Relationship',
    targetColumn : 'following',
    multiple : true,
    count : true,
    filter : true,
    hidden : true,
  },
  friends : {
    name : 'friends',
    title : 'Друзья',
    type : 'virtual',
    virtual : true,
    internal : true,
    get() {
      const followers = this.followers || []
      const following = this.following || []
      return lodash.intersectionBy(followers, following, 'id')
    },
  },
  /*communities : {
    name : 'communities',
    title : 'Сообщества',
    type : 'reference',
    model : 'Community',
    targetColumn : 'members',
    through : 'Membership',
    multiple : true,
    count : true,
    filter : true,
    hidden : true,
  },
  albums : {
    name : 'albums',
    title : 'Альбомы',
    type : 'reference',
    model : 'Album',
    targetColumn : 'owner',
    multiple : true,
    hidden : true,
  },
  posts : {
    name : 'posts',
    title : 'Посты',
    type : 'reference',
    model : 'Post',
    targetColumn : 'author',
    multiple : true,
    count : true,
    hidden : true,
  },
  feed : {
    name : 'feed',
    title : 'Лента',
    type : 'reference',
    model : 'Post',
    targetColumn : 'subject',
    multiple : true,
    count : true,
    hidden : true,
  },
  comments : {
    name : 'comments',
    title : 'Комментарии',
    type : 'reference',
    model : 'Comment',
    targetColumn : 'author',
    multiple : true,
    count : true,
    hidden : true,
  },*/
  //////////////// profile ////////////////
  statusText : {
    name : 'statusText',
    title : 'Статус',
    type : 'string',
    hidden : true,
  },
  birthdate : {
    name : 'birthdate',
    title : 'День рождения',
    abbr : 'ДР',
    type : 'date',
    internal : true,
  },
  gender : {
    name : 'gender',
    title : 'Пол',
    type : 'enum',
    internal : true,
    enum : [
      {
        value : 'male',
        title : 'Мужской',
        abbr : 'М',
      },
      {
        value : 'female',
        title : 'Женский',
        abbr : 'Ж',
      },
      {
        value : 'other',
        title : 'Другой',
        abbr : 'Др.',
      },
    ],
  },
  details : {
    name : 'details',
    title : 'Подробности',
    type : 'text',
    search : true,
    hidden : true,
    validate : {
      len : [1, 1000],
    },
  },
  //////////////// timestamps ////////////////
  lastSeenAt : {
    name : 'lastSeenAt',
    title : 'Был в сети',
    type : 'dateTime',
    readOnly : true,
    filter : true,
    hidden : true,
  },
  createdAt : {
    ...props.createdAt,
  },
  updatedAt : {
    ...props.updatedAt,
  },
  deletedAt : {
    ...props.deletedAt,
  },
}
