const TravelMode = {
  bicycling : {
    name : 'Велосипед',
    icon : 'bicycle_outline',
  },
  driving : {
    name : 'Автомобиль',
    icon : 'porsche-911-new_outline',
  },
  transit : {
    name : 'Общественный транспорт',
    icon : 'bus_outline',
  },
  two_wheeler : {
    name : 'Мотоцикл / скутер',
    icon : 'scooter_outline',
  },
  walking : {
    name : 'Пешком',
    icon : 'walking_outline',
  },
}

module.exports = TravelMode
