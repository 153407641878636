import { Search, Button, Heading } from 'xuick'
import { MobileHeader } from './MobileHeader.js'
import { PlaceBox } from './PlaceBox.js'
import { Icon } from './Icon.js'
import { HeaderLogoLink } from './HeaderLogoLink.js'
import './MapHeader.css'

export class MapHeader extends MobileHeader
{
  static class = 'MapHeader'

  query = ''

  itemId

  item = null

  init() {
    super.init()
    this.on('submit', this.#onSubmit)
  }

  render() {
    const props = this.props
    const category = props.category
    this.dataset = {
      categoryId : category?.id || null,
    }
    if(category) {
      return [
        new Button({
          type : 'back',
          onclick : this.#onResetClick,
          children : new Icon('left_outline'),
        }),
        new Heading(category.title),
        new Button({
          type : 'search',
          children : new Icon('search_outline'),
          onclick : async () => {
            this.emit('reset')
            await new Promise(setTimeout)
            this._placeBox.focus()
          },
        }),
      ]
    }
    return [
      new HeaderLogoLink,
      new Search([
        this._placeBox = new PlaceBox({
          placeholder : 'Адрес или место',
          location : props.location,
          radius : props.radius,
          query : props.query,
          value : props.itemId,
          oninput : this.#onPlaceBoxInput,
          onchange : this.#onPlaceBoxChange,
        }),
        new Button({
          type : 'submit',
          hidden : props.itemId !== undefined,
          disabled : !props.query.trim(),
          children : new Icon('search_outline'),
        }),
      ]),
    ]
  }

  update(prevProps, prevState) {
    super.update(prevProps, prevState)
    if(!this.props.itemId) {
      this.item = null
    }
    if(this.props.category && !prevProps.category) {
      this._placeBox.reset()
    }
  }

  #onSubmit(e) {
    if(e.target === this) {
      return
    }
    e.stopPropagation()
    const placeBox = this._placeBox
    const [item] = placeBox.items
    if(!item) {
      return
    }
    this.query = placeBox.query
    this.itemId = item.id
    this.item = item
    this.emit('submit')
  }

  #onResetClick() {
    this.emit('reset')
  }

  #onPlaceBoxInput = e => {
    e.stopPropagation()
    this.query = e.target.query
    this.emit('input')
  }

  #onPlaceBoxChange = e => {
    e.stopPropagation()
    const target = e.target
    this.query = target.query
    this.itemId = target.value
    this.item = target.item
    this.emit('submit')
  }
}
