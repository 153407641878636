import { SuggestBox } from 'xuick'
import api from './api'

export class LanguageBox extends SuggestBox
{
  static class = 'LanguageBox'

  async loadItems(query, limit, offset) {
    return api.findLanguages({
      query,
      scope : ['name_en', 'name_ru'],
      filters : {
        widespread : true,
      },
      order : [['name_ru', 'ASC']],
      limit,
      offset,
    })
  }

  async loadItem(id) {
    const [result] = await api.findLanguages({
      filters : { id },
      limit : 1,
    })
    return result
  }
}
