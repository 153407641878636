import { Button, Icon, Heading } from 'xuick'
import { MainHead } from './MainHead.js'

export class InfoMainHead extends MainHead
{
  static class = 'InfoMainHead'

  render() {
    return [
      new Button({
        children : new Icon('left_outline'),
        onclick : () => history.back(),
      }),
      new Heading(this.props.text),
    ]
  }
}
